import { useEffect, useRef, useState, useCallback } from 'react';
import { io } from 'socket.io-client';
import axiosInstance from '../../helpers/axiosconfig';

const DEBUG = process.env.NODE_ENV !== 'production';

const log = (...args) => {
  if (DEBUG) {
    console.log('[WebSocket]:', ...args);
  }
};

export const useWebSocket = (userId) => {
  const socketRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('initializing');

  const initializeSocket = useCallback(() => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }

      if (socketRef.current) {
        socketRef.current.disconnect();
      }

      // socketRef.current = io('http://localhost:3000', {
      socketRef.current = io('https://test.speakable.online', {
        query: { userId },
        withCredentials: true,
        transports: ['websocket', 'polling']
      });

      socketRef.current.on('connect', () => {
        log('Connected to chat server');
        setIsConnected(true);
        setError(null);
        setConnectionStatus('connected');
      });

      socketRef.current.on('connect_error', (err) => {
        log('Connection error:', err);
        setIsConnected(false);
        setError(err);
        setConnectionStatus('error');
      });

      socketRef.current.on('disconnect', () => {
        log('Disconnected from server');
        setIsConnected(false);
        setConnectionStatus('disconnected');
      });

      socketRef.current.on('error', (error) => {
        log('Socket error:', error);
        setError(error);
      });

    } catch (err) {
      log('Socket initialization error:', err);
      setError(err);
      setConnectionStatus('error');
    }
  }, [userId]);

  const fetchChatHistory = useCallback(async (recipientId) => {
    try {
      // Fetch messages where current user is either sender or recipient
      const [sentMessages, receivedMessages] = await Promise.all([
        axiosInstance.get(`/history/${userId}/${recipientId}`),
        axiosInstance.get(`/history/${recipientId}/${userId}`)
      ]);

      // Combine and sort messages from both endpoints
      const allMessages = [
        ...(sentMessages.data.success ? sentMessages.data.messages[recipientId] || [] : []),
        ...(receivedMessages.data.success ? receivedMessages.data.messages[userId] || [] : [])
      ].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      return {
        [recipientId]: allMessages
      };
    } catch (err) {
      console.error('Error fetching chat history:', err);
      throw err;
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [userId, initializeSocket]);

  const sendMessage = useCallback((event, data) => {
    if (!socketRef.current?.connected) {
      return false;
    }

    try {
      socketRef.current.emit(event, data);
      return true;
    } catch (err) {
      console.error('Error sending message:', err);
      return false;
    }
  }, []);

  const subscribe = useCallback((event, callback) => {
    if (!socketRef.current) return () => {};

    socketRef.current.on(event, callback);
    return () => socketRef.current?.off(event, callback);
  }, []);

  return { 
    socket: socketRef.current,
    isConnected,
    error,
    isInitializing: connectionStatus === 'initializing',
    connectionStatus,
    sendMessage,
    subscribe,
    fetchChatHistory
  };
};

export default useWebSocket;