// Main imports remain the same, just add useTranslation
import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Signup_Student } from "../store/actions/studentsActions";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";  // Add this import
import { 
  FaRegEye, 
  FaRegEyeSlash, 
  FaEnvelope, 
  FaUser,
  FaKey,
  FaRocket,
  FaUserPlus 
} from "react-icons/fa";
import { toast } from "react-toastify";

const SignupFormPopup = ({ handleClose }) => {
  const { t } = useTranslation("global"); // Add translation hook
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const recaptchref = useRef(null);

  // All state variables remain the same
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [savedOtp, setSavedOtp] = useState("");
  const [acceptRules, setAcceptRules] = useState(false);
  const [acceptPolicies, setAcceptPolicies] = useState(false);
  const [verified, setVerified] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [animationPaused, setAnimationPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = t("Signupform.firstNameRequired");
      isValid = false;
    }

    if (!lastName.trim()) {
      errors.lastName = t("Signupform.lastNameRequired");
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = t("Signupform.emailRequired");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = t("Signupform.emailInvalid");
      isValid = false;
    }

    if (!password.trim()) {
      errors.password = t("Signupform.passwordRequired");
      isValid = false;
    } else if (password.length < 6) {
      errors.password = t("Signupform.passwordLength");
      isValid = false;
    }

    if (!verifiedOtp) {
      errors.otp = t("Signupform.otpRequired");
      isValid = false;
    }

    if (!acceptRules || !acceptPolicies) {
      errors.terms = t("Signupform.rulesRequired");
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSendOtp = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://test.speakable.online/api/sendOtpEmail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email })
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log("OTP received:", data.otp); // Add this line to see the OTP
        setIsOtpSent(true);
        setSavedOtp(data.otp.toString());
        toast.success(t("Signupform.otpSentSuccess"));
      } else {
        toast.error(t("Signupform.otpSendFailed"));
      }
    } catch (error) {
      toast.error(t("Signupform.otpSendError"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = () => {
    if (otp === savedOtp) {
      setVerifiedOtp(true);
      toast.success(t("Signupform.otpVerified"));
    } else {
      toast.error(t("Signupform.invalidOtp"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        // Get the recaptcha token
        const recaptchaToken = recaptchref.current.getValue();
        
        // Check if recaptcha token exists
        if (!recaptchaToken) {
          toast.error(t("Signupform.recaptchaRequired"));
          setIsLoading(false);
          return;
        }
  
        // Check if OTP is verified
        if (!verifiedOtp) {
          toast.error(t("Signupform.otpVerificationRequired"));
          setIsLoading(false);
          return;
        }
  
        // Dispatch signup action with all required fields
        await dispatch(Signup_Student({
          firstName,
          lastName,
          Username: `${firstName} ${lastName}`,
          Email: email,
          Password: password,
          recaptcha: recaptchaToken,
          Phone_Number: '', // Add a default empty string if phone is not required
        })).unwrap();
        
        // Handle successful signup
        setSignupSuccess(true);
        toast.success(t("Signupform.signupSuccess"));
  
        // Reset form
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setOtp('');
        setIsOtpSent(false);
        setVerifiedOtp(false);
        setSavedOtp('');
        setAcceptRules(false);
        setAcceptPolicies(false);
        setVerified(false);
        
        // Reset recaptcha
        recaptchref.current.reset();
  
        // Close form and navigate after delay
        setTimeout(() => {
          handleClose();
          navigate("/dashboard");
        }, 1500);
  
      } catch (error) {
        // Add animation class for error feedback
        formRef.current.classList.add('shake');
        setTimeout(() => {
          formRef.current.classList.remove('shake');
        }, 500);
  
        // Show error message
        toast.error(
          error.response?.data?.message || 
          error.message || 
          t("Signupform.signupFailed")
        );
  
        // Reset recaptcha on error
        recaptchref.current.reset();
        setVerified(false);
  
      } finally {
        setIsLoading(false);
      }
    } else {
      // If validation fails, show first error
      const firstError = Object.values(errors)[0];
      if (firstError) {
        toast.error(firstError);
      }
    }
  };

  // Other handlers remain the same
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const handleInputFocus = () => setAnimationPaused(true);
  const handleInputBlur = () => setAnimationPaused(false);
  
  // In the return statement, update the text content with translations
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-2 sm:p-4">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={handleClose} />
      
      <div className={`relative w-full max-w-md transform transition-all duration-500 ${signupSuccess ? 'scale-0 rotate-180' : 'scale-100'}`}>
        <div 
          ref={formRef}
          className={`bg-white rounded-xl sm:rounded-2xl shadow-2xl overflow-hidden transform transition-transform duration-300 ${animationPaused ? 'scale-100' : 'hover:scale-[1.02]'}`}
        >
          {/* Keep all the styling divs */}
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 opacity-20 animate-gradient" />
          
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="bubble-animation" />
            <div className="bubble-animation delay-2" />
            <div className="bubble-animation delay-4" />
          </div>

          <div className="relative p-4 sm:p-8">
            <div className="flex justify-center mb-4 sm:mb-6">
              <div className="w-16 h-16 sm:w-20 sm:h-20 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full p-1 animate-spin-slow">
                <div className="bg-white rounded-full w-full h-full flex items-center justify-center">
                  <FaUserPlus className="text-2xl sm:text-3xl text-purple-500 animate-bounce" />
                </div>
              </div>
            </div>

            <h3 className="text-xl sm:text-2xl font-bold text-center mb-6 sm:mb-8 text-gray-800">
              {t("Signupform.heading")}
            </h3>

            <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
              {/* Name fields */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                <div className="relative group">
                  <input
                    id="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="peer w-full px-3 sm:px-4 py-2 sm:py-3 text-sm sm:text-base border-2 border-gray-200 rounded-lg sm:rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                    placeholder=" "
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    required
                  />
                  <label 
                    htmlFor="firstName"
                    className="absolute left-2 -top-2.5 bg-white px-2 text-xs sm:text-sm text-gray-600 transition-all cursor-text
                             peer-placeholder-shown:top-2 sm:peer-placeholder-shown:top-3 
                             peer-placeholder-shown:left-3 sm:peer-placeholder-shown:left-4 
                             peer-placeholder-shown:text-sm sm:peer-placeholder-shown:text-base 
                             peer-focus:-top-2.5 peer-focus:left-2 
                             peer-focus:text-xs sm:peer-focus:text-sm 
                             peer-focus:text-purple-500"
                  >
                    {t("Signupform.firstName")}
                  </label>
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-500 transform origin-left transition-transform duration-300 scale-x-0 group-focus-within:scale-x-100" />
                </div>

                <div className="relative group">
                  <input
                    id="lastName"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="peer w-full px-3 sm:px-4 py-2 sm:py-3 text-sm sm:text-base border-2 border-gray-200 rounded-lg sm:rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                    placeholder=" "
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    required
                  />
                  <label 
                    htmlFor="lastName"
                    className="absolute left-2 -top-2.5 bg-white px-2 text-xs sm:text-sm text-gray-600 transition-all cursor-text
                             peer-placeholder-shown:top-2 sm:peer-placeholder-shown:top-3 
                             peer-placeholder-shown:left-3 sm:peer-placeholder-shown:left-4 
                             peer-placeholder-shown:text-sm sm:peer-placeholder-shown:text-base 
                             peer-focus:-top-2.5 peer-focus:left-2 
                             peer-focus:text-xs sm:peer-focus:text-sm 
                             peer-focus:text-purple-500"
                  >
                    {t("Signupform.lastName")}
                  </label>
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-500 transform origin-left transition-transform duration-300 scale-x-0 group-focus-within:scale-x-100" />
                </div>
              </div>

              {/* Updated Email field */}
              <div className="relative group">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="peer w-full px-3 sm:px-4 py-2 sm:py-3 text-sm sm:text-base border-2 border-gray-200 rounded-lg sm:rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                  placeholder=" "
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  required
                />
                <label 
                  htmlFor="email"
                  className="absolute left-2 -top-2.5 bg-white px-2 text-xs sm:text-sm text-gray-600 transition-all cursor-text
                           peer-placeholder-shown:top-2 sm:peer-placeholder-shown:top-3 
                           peer-placeholder-shown:left-3 sm:peer-placeholder-shown:left-4 
                           peer-placeholder-shown:text-sm sm:peer-placeholder-shown:text-base 
                           peer-focus:-top-2.5 peer-focus:left-2 
                           peer-focus:text-xs sm:peer-focus:text-sm 
                           peer-focus:text-purple-500"
                >
                  {t("Signupform.Email")}
                </label>
                <FaEnvelope className="absolute right-3 sm:right-4 top-3 sm:top-4 text-gray-400 group-focus-within:text-purple-500 transition-colors" />
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-500 transform origin-left transition-transform duration-300 scale-x-0 group-focus-within:scale-x-100" />
              </div>

              {/* Updated OTP field */}
              {isOtpSent && !verifiedOtp && (
                <div className="relative group">
                  <input
                    id="otp"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="peer w-full px-3 sm:px-4 py-2 sm:py-3 text-sm sm:text-base border-2 border-gray-200 rounded-lg sm:rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                    placeholder=" "
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    required
                  />
                  <label 
                    htmlFor="otp"
                    className="absolute left-2 -top-2.5 bg-white px-2 text-xs sm:text-sm text-gray-600 transition-all cursor-text
                             peer-placeholder-shown:top-2 sm:peer-placeholder-shown:top-3 
                             peer-placeholder-shown:left-3 sm:peer-placeholder-shown:left-4 
                             peer-placeholder-shown:text-sm sm:peer-placeholder-shown:text-base 
                             peer-focus:-top-2.5 peer-focus:left-2 
                             peer-focus:text-xs sm:peer-focus:text-sm 
                             peer-focus:text-purple-500"
                  >
                    {t("Signupform.enterOtp")}
                  </label>
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-500 transform origin-left transition-transform duration-300 scale-x-0 group-focus-within:scale-x-100" />
                </div>
              )}

              {/* Updated Password field */}
              <div className="relative group">
                <input
                  id="password"
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="peer w-full px-3 sm:px-4 py-2 sm:py-3 text-sm sm:text-base border-2 border-gray-200 rounded-lg sm:rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                  placeholder=" "
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  required
                />
                <label 
                  htmlFor="password"
                  className="absolute left-2 -top-2.5 bg-white px-2 text-xs sm:text-sm text-gray-600 transition-all cursor-text
                           peer-placeholder-shown:top-2 sm:peer-placeholder-shown:top-3 
                           peer-placeholder-shown:left-3 sm:peer-placeholder-shown:left-4 
                           peer-placeholder-shown:text-sm sm:peer-placeholder-shown:text-base 
                           peer-focus:-top-2.5 peer-focus:left-2 
                           peer-focus:text-xs sm:peer-focus:text-sm 
                           peer-focus:text-purple-500"
                >
                  {t("Signupform.Password")}
                </label>
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 sm:right-4 top-3 sm:top-4 text-gray-400 hover:text-purple-500 transition-colors"
                >
                  {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                </button>
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-500 transform origin-left transition-transform duration-300 scale-x-0 group-focus-within:scale-x-100" />
              </div>

              {/* OTP Button */}
              {/* Continuing from the OTP Button */}
              <button
                type="button"
                onClick={!isOtpSent ? handleSendOtp : handleVerifyOtp}
                disabled={verifiedOtp || isLoading}
                className="w-full py-2 sm:py-3 text-sm sm:text-base bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white rounded-lg sm:rounded-xl font-medium transition-all disabled:opacity-50 group overflow-hidden"
              >
                <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
                <div className="relative flex items-center justify-center">
                  {isLoading ? (
                    <div className="flex items-center">
                      <div className="w-4 h-4 sm:w-5 sm:h-5 border-t-2 border-white rounded-full animate-spin mr-2" />
                      {t("common.processing")}
                    </div>
                  ) : (
                    <span>
                      {!isOtpSent ? t("Signupform.sendOtp") : verifiedOtp ? t("Signupform.verified") : t("Signupform.verifyOtp")}
                    </span>
                  )}
                </div>
              </button>

              {/* Checkboxes */}
              <div className="space-y-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={acceptRules}
                    onChange={(e) => setAcceptRules(e.target.checked)}
                    className="w-4 h-4 text-purple-500 rounded focus:ring-purple-500"
                  />
                  <span className="text-xs sm:text-sm text-gray-600">{t("Signupform.acceptRules")}</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={acceptPolicies}
                    onChange={(e) => setAcceptPolicies(e.target.checked)}
                    className="w-4 h-4 text-purple-500 rounded focus:ring-purple-500"
                  />
                  <span className="text-xs sm:text-sm text-gray-600">{t("Signupform.acceptPolicies")}</span>
                </label>
              </div>

              {/* ReCAPTCHA */}
              <div className="flex justify-center transform scale-90 sm:scale-100 origin-top">
                <ReCAPTCHA
                  sitekey="6LdyugQqAAAAAJZTuDB8Wg9Z6kDOGv77l0VDmmvg"
                  onChange={(value) => setVerified(!!value)}
                  ref={recaptchref}
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={!verified || !verifiedOtp || isLoading || !acceptRules || !acceptPolicies}
                className="relative w-full py-2 sm:py-3 text-sm sm:text-base bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white rounded-lg sm:rounded-xl font-medium transition-all disabled:opacity-50 group overflow-hidden"
              >
                <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
                <div className="relative flex items-center justify-center">
                  {isLoading ? (
                    <div className="flex items-center">
                      <div className="w-4 h-4 sm:w-5 sm:h-5 border-t-2 border-white rounded-full animate-spin mr-2" />
                      {t("Signupform.signingIn")}
                    </div>
                  ) : (
                    <>
                      <span>{t("Signupform.SignUp")}</span>
                      <FaRocket className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                    </>
                  )}
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }

        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 5s ease infinite;
        }

        .animate-spin-slow {
          animation: spin 8s linear infinite;
        }

        @keyframes float {
          0%, 100% { transform: translateY(0) rotate(0deg); }
          50% { transform: translateY(-20px) rotate(180deg); }
        }

        .bubble-animation {
          position: absolute;
          width: 50px;
          height: 50px;
          background: linear-gradient(to right, #60a5fa, #c084fc);
          border-radius: 50%;
          opacity: 0.1;
          animation: float 10s ease-in-out infinite;
        }

        .delay-2 {
          animation-delay: -2s;
          left: 70%;
          width: 30px;
          height: 30px;
        }

        .delay-4 {
          animation-delay: -4s;
          left: 30%;
          width: 40px;
          height: 40px;
        }

        .shake {
          animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
        }

        @keyframes shake {
          10%, 90% { transform: translate3d(-1px, 0, 0); }
          20%, 80% { transform: translate3d(2px, 0, 0); }
          30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
          40%, 60% { transform: translate3d(4px, 0, 0); }
        }
      `}</style>
    </div>
  );
};

export default SignupFormPopup;