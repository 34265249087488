import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Signin_user, initializeWebSocket } from "../store/actions/studentsActions";
import { Link, useNavigate } from "react-router-dom";
import FillEmailPopup from "./FillEmailPopup";
import ReCAPTCHA from "react-google-recaptcha";
import { 
  FaRegEye, 
  FaRegEyeSlash, 
  FaEnvelope, 
  FaLock,
  FaKey,
  FaRocket 
} from "react-icons/fa";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";

const SigninFormPopup = ({ handleClose, returnUrl = "/dashboard" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  // State variables remain the same
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [errors, setErrors] = useState({});
  const [FillEmail, setFillEmail] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [animationPaused, setAnimationPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  
  const recaptchref = useRef(false);
  const formRef = useRef(null);

  const auth = useSelector((state) => state.auth) || {};
  const { loading = false, error = null, user = null } = auth;

  useEffect(() => {
    if (user) {
      setLoginSuccess(true);
      setTimeout(() => {
        toast.success(t("Signinform.successMessage"));
        handleClose();
        navigate(returnUrl);
      }, 1500);
    }
  }, [user, navigate, handleClose, returnUrl]);

  useEffect(() => {
    if (error) {
      toast.error(error.message || t("Signinform.errorMessage", { error: t("Signinform.defaultError") }));
    }
  }, [error]);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!Email.trim()) {
      errors.email = t("Signinform.emailRequired");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      errors.email = t("Signinform.emailInvalid");
      isValid = false;
    }

    if (!Password.trim()) {
      errors.password = t("Signinform.passwordRequired");
      isValid = false;
    } else if (Password.length < 6) {
      errors.password = t("Signinform.passwordLength");
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const loginResult = await dispatch(Signin_user({ Email, Password })).unwrap();
        if (loginResult?.data) {
          const wsResponse = await dispatch(initializeWebSocket()).unwrap();
          console.log('WebSocket initialization response:', wsResponse);
        }
      } catch (err) {
        formRef.current.classList.add('shake');
        setTimeout(() => {
          formRef.current.classList.remove('shake');
        }, 500);
        toast.error(err.message || t("Signinform.errorMessage", { error: t("Signinform.defaultError") }));
      } finally {
        setIsLoading(false);
      }
    } else {
      formRef.current.classList.add('shake');
      setTimeout(() => {
        formRef.current.classList.remove('shake');
      }, 500);
    }
  };

  const closeFillEmailFormPopup = () => setFillEmail(false);
  const openFillEmailFormPopup = () => setFillEmail(true);
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const onChange = (value) => {
    setRecaptcha(value);
    setVerified(true);
  };
  const handleInputFocus = () => setAnimationPaused(true);
  const handleInputBlur = () => setAnimationPaused(false);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={handleClose} />
      
      <div className={`relative w-full max-w-md transform transition-all duration-500 ${loginSuccess ? 'scale-0 rotate-180' : 'scale-100'}`}>
        <div 
          ref={formRef}
          className={`bg-white rounded-2xl shadow-2xl overflow-hidden transform transition-transform duration-300 ${animationPaused ? 'scale-100' : 'hover:scale-[1.02]'}`}
        >
          {/* Keep existing gradient background */}
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 opacity-20" />

          <div className="relative p-8">
            {/* Logo section remains unchanged */}
            <div className="flex justify-center mb-6">
              <div className="w-20 h-20 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full p-1">
                <div className="bg-white rounded-full w-full h-full flex items-center justify-center">
                  <FaKey className="text-3xl text-purple-500" />
                </div>
              </div>
            </div>

            <h3 className="text-2xl font-bold text-center mb-8 text-gray-800">
              {t("Signinform.heading")}
            </h3>

            <form onSubmit={SubmitHandler} className="space-y-6">
              {/* Email Input */}
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="peer w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="email"
                  className="absolute left-2 -top-2.5 bg-white px-2 text-sm text-gray-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-sm peer-focus:text-purple-500"
                >
                  {t("Signinform.Email")}
                </label>
                <FaEnvelope className="absolute right-4 top-4 text-gray-400 peer-focus:text-purple-500 transition-colors" />
                {errors.email && (
                  <span className="text-red-500 text-sm mt-1">{errors.email}</span>
                )}
              </div>

              {/* Password Input */}
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="peer w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:border-purple-500 focus:outline-none transition-all bg-transparent"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="password"
                  className="absolute left-2 -top-2.5 bg-white px-2 text-sm text-gray-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:left-2 peer-focus:text-sm peer-focus:text-purple-500"
                >
                  {t("Signinform.Password")}
                </label>
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-4 top-4 text-gray-400 hover:text-purple-500 transition-colors"
                >
                  {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                </button>
                {errors.password && (
                  <span className="text-red-500 text-sm mt-1">{errors.password}</span>
                )}
              </div>

              {/* Forgot Password Link */}
              <div className="text-right">
                <button
                  type="button"
                  onClick={openFillEmailFormPopup}
                  className="text-sm text-purple-500 hover:text-purple-700 transition-colors"
                >
                  {t("Signinform.Forgot")}
                </button>
              </div>

              {/* ReCAPTCHA */}
              <div className="flex justify-center transform hover:scale-[1.01] transition-transform">
                <ReCAPTCHA
                  sitekey="6LdyugQqAAAAAJZTuDB8Wg9Z6kDOGv77l0VDmmvg"
                  onChange={onChange}
                  ref={recaptchref}
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={!verified || isLoading || loading}
                className="relative w-full py-3 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white rounded-xl font-medium transition-all disabled:opacity-50 group overflow-hidden"
              >
                <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
                <div className="relative flex items-center justify-center">
                  {isLoading ? (
                    <div className="flex items-center">
                      <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin mr-2" />
                      {t("Signinform.signingIn")}
                    </div>
                  ) : (
                    <>
                      <span>{t("Signinform.Submit")}</span>
                      <FaRocket className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                    </>
                  )}
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        .shake {
          animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
        }

        @keyframes shake {
          10%, 90% { transform: translate3d(-1px, 0, 0); }
          20%, 80% { transform: translate3d(2px, 0, 0); }
          30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
          40%, 60% { transform: translate3d(4px, 0, 0); }
        }
      `}</style>

      {FillEmail && <FillEmailPopup handleClose={closeFillEmailFormPopup} />}
    </div>
  );
};

export default SigninFormPopup;