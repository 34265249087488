import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useTranslation } from "react-i18next";
import { async_removeuser } from "../store/actions/studentsActions";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { useWebSocket } from './hooks/useWebSocket';
import ChatPopup from './admin-dashboard-components/ChatPopup';

const StudentDashboard = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isPinned, setPinned] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");
  const student = useSelector((state) => state.students.user);
  const teachers = useSelector((state) => state.teachers.Teacherslist);
  const Bookings = useSelector((state) => state.bookings.StudentID_Booking);
  

  const chatUsers = useMemo(() => {
    const teacherIdsFromBookings = new Set();
    
    Bookings?.forEach(booking => {
      booking.Teacher_ID?.forEach(teacher => {
        if (teacher._id) {
          teacherIdsFromBookings.add(teacher._id);
        }
      });
    });

    return [
      ...teachers.filter(teacher => teacherIdsFromBookings.has(teacher._id))
        .map(teacher => ({
          id: teacher._id,
          name: teacher.Username,
          email: teacher.Email,
          type: 'teacher',
          avatar: teacher.Profile_Image?.[0] ? 
            `https://ik.imagekit.io/8s3jwexmv/${teacher.Profile_Image[0]}` : 
            null
        })),
      {
        id: '667fd808df2795ef057c93b5',
        name: 'admin',
        email: 'admin@speakable.com',
        type: 'admin',
        avatar: null
      }
    ];
  }, [teachers, Bookings]);

  const themeColors = {
    dashboard: { bg: 'bg-blue-100', text: 'text-blue-700', hover: 'hover:bg-blue-50' },
    profile: { bg: 'bg-purple-100', text: 'text-purple-700', hover: 'hover:bg-purple-50' },
    bookings: { bg: 'bg-emerald-100', text: 'text-emerald-700', hover: 'hover:bg-emerald-50' },
    payments: { bg: 'bg-amber-100', text: 'text-amber-700', hover: 'hover:bg-amber-50' },
    enquiries: { bg: 'bg-rose-100', text: 'text-rose-700', hover: 'hover:bg-rose-50' }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const languages = [
    { value: 'en', label: t('languages.English'), flag: '🇬🇧' },
    { value: 'pl', label: t('languages.Polish'), flag: '🇵🇱' },
  ];

  const handleLogout = async () => {
    try {
      await dispatch(async_removeuser());
      dispatch({ type: 'LOGOUT_USER' });
      localStorage.removeItem('token');
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      dispatch({ type: 'LOGOUT_USER' });
      localStorage.removeItem('token');
      sessionStorage.clear();
      navigate('/');
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const newLang = selectedOption.value;
    i18n.changeLanguage(newLang);
    localStorage.setItem('i18nextLng', newLang);
    setShowMobileMenu(false);
  };

  const getCurrentLanguage = () => {
    const currentLang = i18n.language || localStorage.getItem('i18nextLng') || 'en';
    return languages.find(lang => lang.value === currentLang) || languages[0];
  };

  const getTheme = (path) => {
    if (path.includes("dash")) return themeColors.dashboard;
    if (path.includes("profile")) return themeColors.profile;
    if (path.includes("Bookings")) return themeColors.bookings;
    if (path.includes("Payments") || path.includes("Wallet")) return themeColors.payments;
    return themeColors.enquiries;
  };

  const handleSidebarMouseEnter = () => {
    if (!isPinned) setCollapsed(false);
  };

  const handleSidebarMouseLeave = () => {
    if (!isPinned) setCollapsed(true);
  };

  const togglePin = () => {
    setPinned(!isPinned);
    if (!isPinned) setCollapsed(false);
  };

  const selectStyles = {
    control: (base) => ({
      ...base,
      background: 'transparent',
      border: '1px solid rgb(229 231 235)',
      borderRadius: '1rem',
      padding: '2px',
      boxShadow: 'none',
      minHeight: '2rem',
      '&:hover': {
        borderColor: getTheme(location.pathname).text
      }
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? getTheme(location.pathname).bg : 'white',
      color: isSelected ? getTheme(location.pathname).text : 'gray',
      fontSize: '0.875rem',
      '&:hover': {
        backgroundColor: getTheme(location.pathname).hover
      }
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '0.875rem'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '0.875rem'
    })
  };

  const menuItems = [
    { label: t('StudentDash.panelheading1'), icon: "bar-chart-fill", path: "/Student-dashboard/dash" },
    { label: t('StudentDash.panelheading2'), icon: "person-fill", path: "/Student-dashboard/profile" },
    { label: t('StudentDash.panelheading4'), icon: "calendar-check", path: "/Student-dashboard/Bookings" },
    { label: t('StudentDash.panelheading5'), icon: "credit-card", path: "/Student-dashboard/Payments" },
    { label: t('StudentDash.panelheading8'), icon: "wallet2", path: "/Student-dashboard/Wallet" },
    { label: t('StudentDash.panelheading6'), icon: "chat-dots", path: "/Student-dashboard/Enquirys" },
    { label: t('StudentDash.panelheading7'), icon: "box-arrow-right", path: null, onClick: handleLogout }
  ];

  const mobileMenuItems = [
    { label: t('StudentDash.panelheading1'), icon: "bar-chart-fill", path: "/Student-dashboard/dash" },
    { label: t('StudentDash.panelheading2'), icon: "person-fill", path: "/Student-dashboard/profile" },
    { label: t('StudentDash.panelheading4'), icon: "calendar-check", path: "/Student-dashboard/Bookings" },
    { label: t('StudentDash.panelheading5'), icon: "credit-card", path: "/Student-dashboard/Payments" },
    { label: t('StudentDash.panelheading8'), icon: "wallet2", path: "/Student-dashboard/Wallet" },
    { label: t('common.more'), icon: "three-dots", onClick: () => setShowMobileMenu(!showMobileMenu) }
  ];

  const currentLanguage = getCurrentLanguage();

  return (
    <div className="flex min-h-screen bg-gray-50/95 relative">
      {/* Desktop Sidebar */}
      <div className="hidden md:block relative">
        <div 
          className="transition-all duration-500 ease-in-out transform sticky top-0"
          onMouseEnter={handleSidebarMouseEnter}
          onMouseLeave={handleSidebarMouseLeave}
        >
          <Sidebar
            collapsed={collapsed}
            width="min(300px, 30vw)"
            collapsedWidth="min(80px, 10vw)"
            className="h-screen"
            backgroundColor="transparent"
            rootStyles={{
              border: 'none',
              minHeight: '100vh',
              position: 'relative'
            }}
          >
            <div className="h-full bg-white/90 backdrop-blur-sm shadow-xl rounded-r-3xl relative overflow-y-auto border-r border-gray-100">
              <div className={`absolute top-0 left-0 w-full h-40 ${getTheme(location.pathname).bg} transition-colors duration-300`} />
              
              <div className="relative pt-4 md:pt-8 pb-3 md:pb-6 px-3 md:px-6">
                <div className="flex items-center justify-between">
                  <h3 className={`text-xl md:text-2xl font-bold ${getTheme(location.pathname).text} transition-all duration-300 flex items-center gap-2 md:gap-3 ${collapsed ? 'justify-center' : ''}`}>
                    <span className="text-2xl md:text-3xl">🎓</span>
                    <span className={`transition-all duration-300 ${collapsed ? 'opacity-0 scale-0' : 'opacity-100 scale-100'}`}>
                      {t('StudentDash.heading')}
                    </span>
                  </h3>
                  {!collapsed && (
                    <button
                      onClick={togglePin}
                      className={`p-2 rounded-lg transition-colors duration-200 ${
                        isPinned 
                          ? `${getTheme(location.pathname).bg} ${getTheme(location.pathname).text}`
                          : 'text-gray-400 hover:bg-gray-100'
                      }`}
                    >
                      <i className={`bi bi-pin${isPinned ? '-fill' : ''} text-lg`} />
                    </button>
                  )}
                </div>

                {!collapsed && (
                  <div className="mt-2 md:mt-4">
                    <Select
                      options={languages}
                      defaultValue={currentLanguage}
                      onChange={handleLanguageChange}
                      styles={selectStyles}
                      formatOptionLabel={({ label, flag }) => (
                        <div className="flex items-center gap-2">
                          <span>{flag}</span>
                          <span>{label}</span>
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>

              <Menu
                menuItemStyles={{
                  button: ({ active }) => {
                    const theme = getTheme(location.pathname);
                    return {
                      backgroundColor: active ? theme.bg : 'transparent',
                      backdropFilter: 'blur(8px)',
                      borderRadius: '1rem',
                      margin: '0.25rem 0.75rem',
                      padding: '0.75rem',
                      color: active ? theme.text : 'rgb(107 114 128)',
                      fontWeight: '500',
                      fontSize: '0.875rem',
                      transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                      transform: hoveredItem === active ? 'scale(1.05)' : 'scale(1)',
                      '&:hover': {
                        backgroundColor: theme.hover,
                        color: theme.text,
                        transform: 'translateX(0.5rem)',
                        boxShadow: '0 8px 16px -4px rgb(0 0 0 / 0.1)',
                      },
                    };
                  },
                  icon: {
                    fontSize: '1.125rem',
                    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  }
                }}
              >
                {!collapsed && (
                  <div className="px-6 py-4 text-xs font-bold text-gray-400 uppercase tracking-widest">
                    {t('StudentDash.panel')}
                  </div>
                )}
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={item.label}
                    icon={<i className={`bi bi-${item.icon} text-xl`} />}
                    active={location.pathname === item.path}
                    onClick={() => item.onClick ? item.onClick() : item.path && navigate(item.path)}
                    onMouseEnter={() => setHoveredItem(item.label)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Sidebar>
        </div>
      </div>

      {/* Main Content */}
      <main className="flex-1 min-h-screen overflow-y-auto relative">
        <div className="p-4 md:p-8 pb-24 md:pb-8">
          <Outlet />
        </div>
      </main>

      {/* ChatPopup */}
      <div className="fixed bottom-20 md:bottom-6 right-4 md:right-6 z-50 max-w-[calc(100vw-2rem)]">
        <ChatPopup 
          currentUser={{
            id: student._id,
            name: `${student.firstName} ${student.lastName}`,
            type: 'student'
          }}
          availableUsers={chatUsers}
        />
      </div>

      {/* Mobile Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-40">
        {showMobileMenu && (
          <div className="absolute bottom-full w-full mb-2 px-4 max-h-[80vh] overflow-y-auto">
            <div className="bg-white rounded-lg shadow-lg border border-gray-100">
              {/* Language Selection */}
              <div className="px-4 py-3 border-b border-gray-100">
                <div className="text-sm font-medium text-gray-500 mb-2">{t('common.Language')}</div>
                <div className="space-y-2">
                  {languages.map((lang) => (
                    <button
                      key={lang.value}
                      onClick={() => handleLanguageChange(lang)}
                      className={`w-full px-3 py-2 rounded-lg flex items-center gap-3 transition-colors duration-200 ${
                        lang.value === currentLanguage.value
                          ? `${getTheme(location.pathname).bg} ${getTheme(location.pathname).text}`
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      <span className="text-lg">{lang.flag}</span>
                      <span>{lang.label}</span>
                      {lang.value === currentLanguage.value && (
                        <i className="bi bi-check2 ml-auto" />
                      )}
                    </button>
                  ))}
                </div>
              </div>

              {/* Logout Option */}
              <button
                onClick={handleLogout}
                className="w-full px-4 py-3 flex items-center gap-3 text-red-600 hover:bg-red-50 transition-colors duration-200"
              >
                <i className="bi bi-box-arrow-right text-lg" />
                <span>{t('StudentDash.panelheading7')}</span>
              </button>

              {/* Cancel Button */}
              <button
                onClick={() => setShowMobileMenu(false)}
                className="w-full px-4 py-3 flex items-center gap-3 text-gray-600 hover:bg-gray-50 border-t border-gray-100 transition-colors duration-200"
              >
                <i className="bi bi-x-lg text-lg" />
                <span>{t('common.cancel')}</span>
              </button>
            </div>
          </div>
        )}

        {/* Mobile Navigation Bar */}
        <div className="bg-white border-t border-gray-100 shadow-lg">
          <div className="flex justify-around items-center h-16">
            {mobileMenuItems.map((item) => {
              const isActive = item.path ? location.pathname === item.path : false;
              const theme = getTheme(location.pathname);
              
              return (
                <button
                  key={item.label}
                  onClick={item.onClick || (() => navigate(item.path))}
                  className={`flex flex-col items-center justify-center w-full h-full px-2 ${
                    isActive ? `${theme.text} ${theme.bg}` : 'text-gray-500'
                  } transition-colors duration-200`}
                >
                  <i className={`bi bi-${item.icon} text-base md:text-lg`} />
                  <span className="text-xs mt-1">{item.label}</span>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;