import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChatBotButton from './landingcomponents/ChatBotButton';
import Home from './landingcomponents/LandingNewPage';

const Layout = () => {
  const isAuthenticated = useSelector((state) => state.students.isAuthenticated);
  const userType = useSelector((state) => state.students.userType);
  const navigate = useNavigate();
  const [chatPopUp, setChatPopUp] = useState(false);

  useEffect(() => {
    if (isAuthenticated > 0) {
      switch (userType) {
        case 'student':
          navigate('/Student-dashboard/dash');
          break;
        case 'teacher':
          navigate('/Teacher-dashboard/dash');
          break;
        case 'admin':
          navigate('/Admin-Dashboard/Dashboard');
          break;
        case 'accountant':
          navigate('/Accontant-Dashboard/dash');
          break;
        default:
          navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [isAuthenticated, userType, navigate]);

  const onLetsChatClick = () => {
    setChatPopUp(!chatPopUp);
  };

  return (
    <>
      <div>
        <Home />
        <ChatBotButton
          onLetsChatClick={onLetsChatClick}
          ChatPopUp={chatPopUp}
        />
        <ToastContainer
          style={{
            zIndex: 9999,
            position: "fixed",
            top: 0,
            right: 0,
          }}
        />
      </div>
    </>
  );
};

export default Layout;