import React, { useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const PurchaseSteps = ({ 
  isOpen, 
  onClose, 
  pack, 
  student, 
  convertPrice,
  onConfirm 
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const totalSteps = 2;

  const hasAddress = student?.Address && Object.keys(student.Address).length > 0;

  const handleNext = () => {
    if (currentStep === totalSteps - 1) {
      onConfirm({ 
        billingAddress: selectedBillingAddress,
        lectureDetails: selectedLecture || {
          count: pack?.Number_of_Lectures,
          finalPrice: pack?.Package_Amount
        }
      });
      return;
    }
    setCurrentStep(prev => Math.min(prev + 1, totalSteps - 1));
  };

  const handlePrev = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const handleChangeAddress = () => {
    navigate('/Student-dashboard/profile');
    onClose();
  };

  const formatLectureOptions = (lectures) => {
    return lectures?.map(lecture => {
      const lectureData = lecture._doc || lecture;
      return {
        ...lectureData,
        pricePerLecture: lectureData.finalPrice / lectureData.count
      };
    }) || [];
  };

  const renderAddressStep = () => {
    if (!hasAddress) {
      return (
        <div className="text-center p-6">
          <p className="text-lg text-gray-600 mb-4">{t("PurchaseSteps.NoBillingAddress")}</p>
          <button 
            className="py-2 px-4 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors"
            onClick={handleChangeAddress}
          >
            {t("PurchaseSteps.AddNewAddress")}
          </button>
        </div>
      );
    }

    const address = student.Address;

    return (
      <div className="space-y-4">
        <div
          onClick={() => setSelectedBillingAddress(address)}
          className={`relative p-4 rounded-lg border-2 cursor-pointer transition-all duration-200
            ${selectedBillingAddress === address 
              ? 'border-violet-500 bg-violet-50 shadow-md' 
              : 'border-gray-200 hover:border-violet-300'}`}
        >
          <div className="flex items-start gap-4">
            <div className="flex items-center h-5 mt-1">
              <input
                type="radio"
                checked={selectedBillingAddress === address}
                onChange={() => setSelectedBillingAddress(address)}
                className="h-4 w-4 text-violet-600 border-gray-300 focus:ring-violet-500"
              />
            </div>
            <div>
              <p className="font-medium text-gray-900">{student.firstName} {student.lastName}</p>
              <p className="text-gray-600">{address.street}</p>
              <p className="text-gray-600">
                {address.city}, {address.postalCode}
              </p>
              <p className="text-gray-600">{address.country}</p>
            </div>
          </div>
        </div>
        
        <div className="flex justify-center">
          <button
            onClick={handleChangeAddress}
            className="text-violet-600 hover:text-violet-700 underline text-sm font-medium"
          >
            {t("PurchaseSteps.ChangeBillingAddress")}
          </button>
        </div>
      </div>
    );
  };

  const renderStep = () => {
    switch(currentStep) {
      case 0:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-violet-900 mb-4">
              {t("PurchaseSteps.SelectLectures")}
            </h2>
            <div className="space-y-4">
              {formatLectureOptions(pack?.lectures).map((lecture, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedLecture(lecture)}
                  className={`relative p-6 rounded-lg border-2 cursor-pointer transition-all duration-200
                    ${selectedLecture?._id === lecture._id 
                      ? 'border-violet-500 bg-violet-50 shadow-md' 
                      : 'border-gray-200 hover:border-violet-300'}`}
                >
                  <div className="flex items-start gap-4">
                    <div className="flex items-center h-5 mt-1">
                      <input
                        type="radio"
                        checked={selectedLecture?._id === lecture._id}
                        onChange={() => setSelectedLecture(lecture)}
                        className="h-4 w-4 text-violet-600 border-gray-300 focus:ring-violet-500"
                      />
                    </div>
                    <div className="flex-grow">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-lg font-semibold text-gray-900">
                            {t("PurchaseSteps.LectureCount", { count: lecture.count })}
                          </h3>
                          <p className="text-sm text-gray-500">
                            {t("PurchaseSteps.PricePerLecture")}: {convertPrice(lecture.pricePerLecture)}
                          </p>
                        </div>
                        <div className="text-right">
                          <div className="text-lg font-bold text-violet-600">
                            {convertPrice(lecture.finalPrice)}
                          </div>
                          {lecture.discount > 0 && (
                            <>
                              <div className="text-sm line-through text-gray-400">
                                {convertPrice(lecture.basePrice)}
                              </div>
                              <div className="mt-1">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  {t("PurchaseSteps.SavePercent", { percent: lecture.discount })}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {lecture.discount > 0 && (
                        <div className="mt-2 text-sm text-green-600">
                          {t("PurchaseSteps.YouSave", { amount: convertPrice(lecture.basePrice - lecture.finalPrice) })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={handleNext}
              disabled={!selectedLecture}
              className={`w-full py-3 px-4 rounded-lg text-white transition-colors
                ${selectedLecture ? 'bg-violet-600 hover:bg-violet-700' : 'bg-gray-300 cursor-not-allowed'}`}
            >
              {t("PurchaseSteps.ContinueToBilling")}
            </button>
          </div>
        );

      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-violet-900 mb-4">
              {t("PurchaseSteps.BillingAddress")}
            </h2>
            {renderAddressStep()}
            <div className="flex space-x-4">
              <button 
                onClick={handlePrev}
                className="flex-1 py-3 px-4 rounded-lg border-2 border-violet-200 text-violet-700 hover:bg-violet-50 transition-colors"
              >
                {t("PurchaseSteps.Back")}
              </button>
              <button 
                onClick={handleNext}
                disabled={!selectedBillingAddress}
                className={`flex-1 py-3 px-4 rounded-lg text-white transition-colors
                  ${selectedBillingAddress ? 'bg-violet-600 hover:bg-violet-700' : 'bg-gray-300 cursor-not-allowed'}`}
              >
                {t("PurchaseSteps.ConfirmPurchase")}
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem'
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '42rem',
      width: '100%',
      padding: '2rem',
      border: 'none',
      borderRadius: '1rem',
      background: '#fff',
      maxHeight: '90vh',
      overflow: 'auto'
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
      ariaHideApp={false}
    >
      <div className="relative">
        <button 
          onClick={onClose}
          className="absolute top-0 right-0 text-gray-400 hover:text-gray-600 text-xl"
          aria-label={t("PurchaseSteps.Close")}
        >
          ×
        </button>
        
        <div className="flex justify-center mb-8">
          <div className="flex items-center space-x-2">
            {[...Array(totalSteps)].map((_, index) => (
              <React.Fragment key={index}>
                <div className={`
                  w-8 h-8 rounded-full flex items-center justify-center transition-colors
                  ${currentStep === index 
                    ? 'bg-violet-600 text-white' 
                    : currentStep > index 
                      ? 'bg-violet-200 text-violet-700' 
                      : 'bg-violet-100 text-violet-400'}`}
                >
                  {index + 1}
                </div>
                {index < totalSteps - 1 && (
                  <div className={`w-16 h-1 transition-colors ${currentStep > index ? 'bg-violet-200' : 'bg-violet-100'}`} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        
        {renderStep()}
      </div>
    </Modal>
  );
};

export default PurchaseSteps;