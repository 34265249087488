// src/components/ChatButton.js

import React, { useState } from 'react';
import ChatComponent from './ChatComponent';

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      <button onClick={toggleChat} className="chat-button">
        {/* {isChatOpen ? 'Close Chat' : 'Open Chat'} */}
      </button>
      <ChatComponent isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
};

export default ChatButton;