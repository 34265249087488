import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import './SmallCalendar.css';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const SmallCalendar = ({ 
  onChange, 
  availableDates,
  selectedTimezone,
  hourFormat,
  teacherTimezone,
  onTimezoneChange,
  selectedDate,
  selectedTimeSlots
}) => {
  // Initialize with current date if no date is selected
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDateState, setSelectedDateState] = useState(null);
  const [localSelectedTimeSlots, setLocalSelectedTimeSlots] = useState(selectedTimeSlots || []);
  const [noSlotsMessage, setNoSlotsMessage] = useState('');

  // Initialize selectedDateState with a default value
  useEffect(() => {
    setSelectedDateState(selectedDate || new Date());
  }, [selectedDate]);

  useEffect(() => {
    setLocalSelectedTimeSlots(selectedTimeSlots || []);
  }, [selectedTimeSlots]);

  useEffect(() => {
    if (selectedDateState) {
      const dateString = moment(selectedDateState).format('YYYY-MM-DD');
      updateTimeSlotsForDate(dateString);
    }
  }, [selectedTimezone, hourFormat]);

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const getPreviousMonth = (date) => new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const getNextMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 1);

  const convertTime = (dateStr, timeStr, toTimezone) => {
    if (!dateStr || !timeStr || !toTimezone) return '';
    const utcDateTime = moment.utc(`${dateStr} ${timeStr}`);
    const convertedTime = utcDateTime.tz(toTimezone);
    return convertedTime.format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm');
  };

  const updateTimeSlotsForDate = (dateString) => {
    if (!dateString) return;
    
    const slots = availableDates?.[dateString]?.slots || [];
    
    if (slots.length === 0) {
      setNoSlotsMessage('No time slots available for this date');
      setLocalSelectedTimeSlots([]);
    } else {
      setNoSlotsMessage('');
      
      const convertedSlots = slots.map(slot => ({
        start: convertTime(dateString, slot.start, selectedTimezone),
        end: convertTime(dateString, slot.end, selectedTimezone)
      }));
      
      convertedSlots.sort((a, b) => {
        const timeA = moment(a.start, hourFormat === '12' ? 'hh:mm A' : 'HH:mm');
        const timeB = moment(b.start, hourFormat === '12' ? 'hh:mm A' : 'HH:mm');
        return timeA - timeB;
      });

      setLocalSelectedTimeSlots(convertedSlots);
      
      if (onTimezoneChange) {
        onTimezoneChange(dateString, selectedTimezone);
      }
    }
  };

  const handlePrevMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
  };

  const handleDateClick = (day) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDateState(newDate);
    if (onChange) {
      onChange(newDate);
    }

    const dateString = moment(newDate).format('YYYY-MM-DD');
    updateTimeSlotsForDate(dateString);
  };

  const isDateAvailable = (year, month, day) => {
    const dateString = moment(new Date(year, month, day)).format('YYYY-MM-DD');
    return availableDates && 
           availableDates[dateString] && 
           Array.isArray(availableDates[dateString].slots) && 
           availableDates[dateString].slots.length > 0;
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    const firstDayOfMonth = getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth());
    const days = [];

    // Empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // Days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected = selectedDateState && 
                        day === selectedDateState.getDate() &&
                        currentDate.getMonth() === selectedDateState.getMonth() &&
                        currentDate.getFullYear() === selectedDateState.getFullYear();
      const today = new Date();
      const isToday = day === today.getDate() &&
                      currentDate.getMonth() === today.getMonth() &&
                      currentDate.getFullYear() === today.getFullYear();
      const isAvailable = isDateAvailable(currentDate.getFullYear(), currentDate.getMonth(), day);

      days.push(
        <div
          key={day}
          onClick={() => handleDateClick(day)}
          className={`calendar-day ${isSelected ? 'selected' : ''} ${isToday ? 'today' : ''} ${isAvailable ? 'available' : ''}`}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  // Ensure we have a valid date before rendering
  if (!selectedDateState) {
    return null;
  }

  return (
    <div className="custom-calendar">
      <div className="calendar-header">
        <div className="current-date">
          <div className="day">{daysOfWeek[selectedDateState.getDay()]}</div>
          <div className="date">{selectedDateState.getDate()}</div>
        </div>
        <div className="month-year">
          <div>{months[selectedDateState.getMonth()]}</div>
          <div>{selectedDateState.getFullYear()}</div>
        </div>
      </div>
      <div className="calendar-body">
        <div className="month-navigation">
          <button onClick={handlePrevMonth} className="nav-button">&#8249;</button>
          <div className="current-month">{months[currentDate.getMonth()]} {currentDate.getFullYear()}</div>
          <button onClick={handleNextMonth} className="nav-button">&#8250;</button>
        </div>
        <div className="weekdays">
          {daysOfWeek.map(day => (
            <div key={day} className="weekday">{day}</div>
          ))}
        </div>
        <div className="days">
          {renderCalendarDays()}
        </div>
      </div>
      
      <div className="calendar-time-slots">
        {localSelectedTimeSlots.length > 0 ? (
          <>
            <h4>Available Time Slots ({selectedTimezone})</h4>
            <div className="time-slots-list">
              {localSelectedTimeSlots.map((slot, index) => (
                <div key={index} className="time-slot-item">
                  {slot.start} - {slot.end}
                </div>
              ))}
            </div>
          </>
        ) : (
          noSlotsMessage && (
            <div className="no-slots-message">
              {noSlotsMessage}
            </div>
          )
        )}
      </div>

      <div className="calendar-footer">
        <span>{months[getPreviousMonth(currentDate).getMonth()]}</span>
        <span>{months[currentDate.getMonth()]}</span>
        <span>{months[getNextMonth(currentDate).getMonth()]}</span>
      </div>
    </div>
  );
};

export default SmallCalendar;