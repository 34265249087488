import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useWebSocket } from '../hooks/useWebSocket';
import { useTranslation } from 'react-i18next';
import EmojiPicker from '../utils/EmojiPicker';
import { 
  Close as CloseIcon,
  Send as SendIcon,
  Chat as ChatIcon,
  Search as SearchIcon,
  MinimizeRounded as MinimizeIcon,
  EmojiEmotions as EmojiIcon,
  AttachFile as AttachFileIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { Tooltip, useMediaQuery } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';


const ChatPopup = ({ currentUser, availableUsers }) => {
    
    const isMobile = useMediaQuery('(max-width: 768px)');
    const { socket, isConnected, error, isInitializing, fetchChatHistory } = useWebSocket(currentUser.id);
    const { t } = useTranslation("global");
    const [isOpen, setIsOpen] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [unreadCounts, setUnreadCounts] = useState({});
    const [isTyping, setIsTyping] = useState({});
    const [notification, setNotification] = useState(null);
    const messagesEndRef = useRef(null);
    const typingTimeoutRef = useRef({});
    const [userFilter, setUserFilter] = useState('all');
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [lastMessageTimestamps, setLastMessageTimestamps] = useState({});

    const scrollToBottom = useCallback(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const canChatWith = useCallback((targetUser) => {
        if (!currentUser || !targetUser) return false;

        switch (currentUser.type) {
            case 'admin':
                return true;
            case 'student':
                return ['teacher', 'admin'].includes(targetUser.type);
            case 'teacher':
                return ['student', 'admin'].includes(targetUser.type);
            default:
                return false;
        }
    }, [currentUser]);

    const getAvailableTabs = useCallback(() => {
        switch (currentUser.type) {
            case 'admin':
                return [
                    { value: 'all', label: t('chat.all') },
                    { value: 'student', label: t('chat.students') },
                    { value: 'teacher', label: t('chat.teachers') }
                ];
            case 'student':
                return [
                    { value: 'all', label: t('chat.all') },
                    { value: 'teacher', label: t('chat.teachers') }
                ];
            case 'teacher':
                return [
                    { value: 'all', label: t('chat.all') },
                    { value: 'student', label: t('chat.students') }
                ];
            default:
                return [{ value: 'all', label: t('chat.all') }];
        }
    }, [currentUser.type, t]);

    useEffect(() => {
        let filtered = users.filter(user => canChatWith(user));
        
        if (userFilter !== 'all') {
            filtered = filtered.filter(user => user.type === userFilter);
        }

        if (searchQuery) {
            filtered = filtered.filter(user => 
                user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.email?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        filtered.sort((a, b) => {
            const timestampA = lastMessageTimestamps[a.id] || '0';
            const timestampB = lastMessageTimestamps[b.id] || '0';
            return new Date(timestampB) - new Date(timestampA);
        });

        setDisplayedUsers(filtered);
    }, [users, userFilter, searchQuery, canChatWith, lastMessageTimestamps]);

    useEffect(() => {
        setUsers(availableUsers.map(user => ({
            ...user,
            online: false
        })));
    }, [availableUsers]);

    useEffect(() => {
        if (isConnected && socket && currentUser?.id) {
            socket.on('message', (data) => {
                if (!data?.from) return;

                setMessages(prev => ({
                    ...prev,
                    [data.from]: [...(prev[data.from] || []), data]
                }));

                setLastMessageTimestamps(prev => ({
                    ...prev,
                    [data.from]: new Date().toISOString()
                }));

                if (activeChat?.id !== data.from) {
                    setUnreadCounts(prev => ({
                        ...prev,
                        [data.from]: (prev[data.from] || 0) + 1
                    }));

                    toast.info(`New message from ${data.fromUser?.username || 'Unknown User'}`, {
                        position: "bottom-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: () => (
                            <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-sm overflow-hidden">
                                {data.fromUser?.profileImage ? (
                                    <img 
                                        src={data.fromUser.profileImage} 
                                        alt={data.fromUser.username}
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    data.fromUser?.username?.[0]
                                )}
                            </div>
                        )
                    });
                }
                scrollToBottom();
            });

            socket.on('userTyping', ({ from, isTyping: typing }) => {
                if (!from) return;
                setIsTyping(prev => ({ ...prev, [from]: typing }));
            });

            socket.on('userOnline', (data) => {
                if (!data?.userId) return;
                setUsers(prev => prev.map(user => 
                    user.id === data.userId ? { ...user, online: true } : user
                ));
            });

            socket.on('userOffline', (data) => {
                if (!data?.userId) return;
                setUsers(prev => prev.map(user => 
                    user.id === data.userId ? { ...user, online: false } : user
                ));
            });

            socket.emit('getOnlineUsers');

            return () => {
                socket.off('message');
                socket.off('userTyping');
                socket.off('userOnline');
                socket.off('userOffline');
            };
        }
    }, [socket, isConnected, activeChat, currentUser?.id, scrollToBottom]);

    const handleSendMessage = useCallback(() => {
        if (!newMessage?.trim() || !activeChat?.id || !socket || !currentUser?.id) return;
    
        const timestamp = new Date().toISOString();
        const messageData = {
            to: activeChat.id,
            content: newMessage.trim(),
            timestamp
        };
    
        socket.emit('message', messageData);
    
        // Create a properly structured message object
        const newMessageObj = {
            content: newMessage.trim(),
            timestamp,
            from: {  // Add the complete sender information
                id: currentUser.id,
                username: currentUser.name,
                profileImage: currentUser.avatar
            }
        };
    
        setMessages(prev => ({
            ...prev,
            [activeChat.id]: [...(prev[activeChat.id] || []), newMessageObj]
        }));
    
        setLastMessageTimestamps(prev => ({
            ...prev,
            [activeChat.id]: timestamp
        }));
    
        setNewMessage('');
        scrollToBottom();
    }, [newMessage, activeChat?.id, socket, currentUser, scrollToBottom]);

    const handleTyping = useCallback(() => {
        if (!activeChat?.id || !socket) return;

        socket.emit('typing', {
            to: activeChat.id,
            isTyping: true
        });

        if (typingTimeoutRef.current[activeChat.id]) {
            clearTimeout(typingTimeoutRef.current[activeChat.id]);
        }

        typingTimeoutRef.current[activeChat.id] = setTimeout(() => {
            socket.emit('typing', {
                to: activeChat.id,
                isTyping: false
            });
        }, 2000);
    }, [activeChat?.id, socket]);

    const handleChatSelect = useCallback(async (user) => {
        if (!user?.id) return;
        
        setActiveChat(user);
        setUnreadCounts(prev => ({ ...prev, [user.id]: 0 }));
        
        try {
            // Fetch chat history when selecting a user
            const chatHistory = await fetchChatHistory(user.id);
            
            // Update messages state with the fetched history
            if (chatHistory[user.id]) {
                setMessages(prev => ({
                    ...prev,
                    [user.id]: chatHistory[user.id]
                }));

                // Update last message timestamp if there are messages
                if (chatHistory[user.id].length > 0) {
                    const lastMessage = chatHistory[user.id][chatHistory[user.id].length - 1];
                    setLastMessageTimestamps(prev => ({
                        ...prev,
                        [user.id]: lastMessage.timestamp
                    }));
                }
            }
        } catch (error) {
            console.error('Error loading chat history:', error);
            // You might want to show a toast notification here
            toast.error(t('chat.errorLoadingHistory'));
        }
    }, [fetchChatHistory, t]);

    const formatTime = useCallback((timestamp) => {
        if (!timestamp) return '';
        try {
            return new Date(timestamp).toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
            });
        } catch (error) {
            return '';
        }
    }, []);

    const formatMessageDate = useCallback((date) => {
        const today = new Date();
        const messageDate = new Date(date);
        
        if (messageDate.toDateString() === today.toDateString()) {
            return t('chat.today');
        }
        
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        if (messageDate.toDateString() === yesterday.toDateString()) {
            return t('chat.yesterday');
        }
        
        return messageDate.toLocaleDateString();
    }, [t]);

    const groupMessagesByDate = useCallback((messages) => {
        return messages.reduce((groups, message) => {
            const date = new Date(message.timestamp).toDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(message);
            return groups;
        }, {});
    }, []);

    const shouldShowTimestamp = useCallback((message, previousMessage) => {
        if (!previousMessage) return true;
        
        const currentTime = new Date(message.timestamp);
        const previousTime = new Date(previousMessage.timestamp);
        const timeDiff = currentTime - previousTime;
        
        return timeDiff > 5 * 60 * 1000; // Show timestamp if messages are more than 5 minutes apart
    }, []);

    const getMessageStatus = useCallback((message) => {
        return message.status || 'sent';
    }, []);

    const renderMessages = useCallback(() => {
        if (!activeChat?.id || !messages[activeChat.id]) return null;
        
        const groupedMessages = groupMessagesByDate(messages[activeChat.id]);
        
        return Object.entries(groupedMessages).map(([date, dateMessages]) => (
            <div key={date} className="space-y-4">
                <div className="text-center">
                    <span className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-600">
                        {formatMessageDate(date)}
                    </span>
                </div>

                {dateMessages.map((message, index) => {
                    const showTimestamp = shouldShowTimestamp(message, dateMessages[index - 1]);
                    const isCurrentUser = message.from.id === currentUser.id;

                    return (
                        <div
                            key={message._id || index}
                            className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
                        >
                            {!isCurrentUser && (
                                <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mr-2 overflow-hidden">
                                    {message.from.profileImage ? (
                                        <img 
                                            src={message.from.profileImage}
                                            alt={message.from.username}
                                            className="w-full h-full object-cover"
                                        />
                                    ) : (
                                        message.from.username?.[0]
                                    )}
                                </div>
                            )}

                            <div className={`max-w-[70%] rounded-2xl px-4 py-2 ${
                                isCurrentUser 
                                    ? 'bg-gradient-to-r from-emerald-500 to-teal-500 text-white'
                                    : 'bg-gray-100 text-gray-900'
                            }`}>
                                <p className="text-sm">{message.content}</p>
                                {showTimestamp && (
                                    <div className={`text-xs mt-1 ${
                                        isCurrentUser ? 'text-white/80' : 'text-gray-500'
                                    }`}>
                                        {formatTime(message.timestamp)}
                                        {isCurrentUser && message.readAt && (
                                            <span className="ml-1">
                                                ✓✓
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        ));
    }, [activeChat?.id, messages, currentUser.id, formatMessageDate, formatTime, shouldShowTimestamp, groupMessagesByDate]);

    return (
        <>
            {/* Chat Toggle Button - Only show when chat is not open */}
            {!isOpen && (
                <button 
                    onClick={() => setIsOpen(true)}
                    className="fixed bottom-24 md:bottom-6 right-6 w-14 h-14 md:w-16 md:h-16 flex items-center justify-center rounded-full bg-gradient-to-r from-emerald-500 to-teal-500 text-white shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200 z-50"
                >
                    <div className="relative">
                        <ChatIcon className="w-7 h-7 md:w-8 md:h-8" />
                        {Object.values(unreadCounts).reduce((a, b) => a + b, 0) > 0 && (
                            <span className="absolute -top-2 -right-2 w-5 h-5 md:w-6 md:h-6 flex items-center justify-center bg-red-500 rounded-full text-xs font-bold">
                                {Object.values(unreadCounts).reduce((a, b) => a + b, 0)}
                            </span>
                        )}
                    </div>
                </button>
            )}


            {/* Toast Container */}
            <ToastContainer position="bottom-right" />

            {/* Chat Window */}
            {isOpen && (
                <div className={`fixed bg-white flex flex-col overflow-hidden z-40 border border-gray-100 transition-all duration-300 ${
                    isMobile
                        ? 'inset-0 rounded-none'  // Full screen on mobile
                        : 'bottom-24 md:bottom-6 right-6 w-[380px] h-[500px] md:h-[580px] rounded-2xl shadow-2xl'
                }`}>
                    {/* Gradient Header */}
                    <div className="bg-gradient-to-r from-emerald-500 to-teal-500 p-4 flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            {activeChat && (
                                <button 
                                    onClick={() => setActiveChat(null)}
                                    aria-label={t('chat.back')}
                                    className="p-1 hover:bg-white/20 rounded-full transition-colors"
                                >
                                    <ArrowBackIcon className="w-5 h-5 text-white" />
                                </button>
                            )}
                            <h2 className="text-white font-semibold">
                            {activeChat ? activeChat.name : t('chat.title')}
                            </h2>
                        </div>
                        <div className="flex gap-2">
                            {!isMobile && (
                                <button 
                                    onClick={() => setMinimized(!minimized)}
                                    aria-label={t('chat.minimize')}
                                    className="p-1 hover:bg-white/20 rounded-full transition-colors"
                                >
                                    <MinimizeIcon className="w-5 h-5 text-white" />
                                </button>
                            )}
                            <button 
                                onClick={() => setIsOpen(false)}
                                aria-label={t('chat.buttons.close')}
                                className="p-1 hover:bg-white/20 rounded-full transition-colors"
                            >
                                <CloseIcon className="w-5 h-5 text-white" />
                            </button>
                        </div>
                    </div>

                    {(!minimized || isMobile) && (
                        <>
                            {/* Connection Status */}
                            {!isConnected && (
                                <div className="bg-amber-50 border-b border-amber-100 px-4 py-2 text-amber-700 text-sm">
                                    {isInitializing ? t('chat.connecting') : t('chat.disconnected')}
                                </div>
                            )}

                            {/* Main Content */}
                            <div className="flex-1 flex flex-col overflow-hidden">
                                {!activeChat ? (
                                    // Users List View
                                    <>
                                        {/* Modern Tabs */}
                                        <div className="flex border-b border-gray-100">
                                            {getAvailableTabs().map((tab) => (
                                                <button
                                                    key={tab.value}
                                                    onClick={() => setUserFilter(tab.value)}
                                                    className={`flex-1 py-3 text-sm font-medium transition-colors
                                                        ${userFilter === tab.value 
                                                            ? 'text-emerald-600 border-b-2 border-emerald-500' 
                                                            : 'text-gray-500 hover:text-gray-700'}`}
                                                >
                                                    {tab.label}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Search Bar */}
                                        <div className="p-3 sticky top-0 bg-white z-10">
                                            <div className="relative">
                                                <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                                                <input
                                                    type="text"
                                                    placeholder={t('chat.placeholder')}
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    className="w-full pl-10 pr-4 py-2 bg-gray-50 rounded-full border border-gray-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                                                />
                                            </div>
                                        </div>

                                        {/* Users List */}
                                        <div className="flex-1 overflow-y-auto">
                                            {displayedUsers.map((user) => (
                                                <button
                                                    key={user.id}
                                                    onClick={() => handleChatSelect(user)}
                                                    className="w-full p-3 flex items-center gap-3 hover:bg-gray-50 transition-colors"
                                                >
                                                    <div className="relative">
                                                        <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center text-lg font-medium overflow-hidden">
                                                            {user.avatar ? (
                                                                <img src={user.avatar} alt={user.name} className="w-full h-full object-cover" />
                                                            ) : (
                                                                user.name?.[0] || '?'
                                                            )}
                                                        </div>
                                                        <span className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-white
                                                            ${user.online ? 'bg-emerald-500' : 'bg-gray-300'}`}
                                                        />
                                                    </div>
                                                    <div className="flex-1 text-left">
                                                        <div className="font-medium text-gray-900">{user.name}</div>
                                                        <div className="text-sm text-gray-500 flex items-center gap-2">
                                                            <span className="truncate">{user.email}</span>
                                                            <span className="text-xs px-2 py-0.5 rounded-full bg-gray-100 whitespace-nowrap">
                                                                {user.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {unreadCounts[user.id] > 0 && (
                                                        <span className="w-6 h-6 rounded-full bg-red-500 text-white text-xs font-bold flex items-center justify-center">
                                                            {unreadCounts[user.id]}
                                                        </span>
                                                    )}
                                                </button>
                                            ))}
                                            {displayedUsers.length === 0 && (
                                                <div className="p-8 text-center text-gray-500">
                                                    {t('chat.noUsers')}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    // Chat View
                                    <>
                                        {/* Messages Area */}
                                        <div className="flex-1 overflow-y-auto p-4 space-y-4">
                                            {renderMessages()}
                                            <div ref={messagesEndRef} />
                                            {isTyping[activeChat?.id] && (
                                                <div className="flex items-center gap-2 ml-2">
                                                    <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                                                        {activeChat?.name[0]}
                                                    </div>
                                                    <div className="text-sm text-gray-500 italic">{t('chat.typing')}</div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Message Input */}
<div className="p-2 sm:p-4 border-t border-gray-100 bg-white">
    <div className="relative flex items-center gap-1 sm:gap-2">
        <div className="flex gap-0.5 sm:gap-1">
            <EmojiPicker
                onEmojiSelect={(emoji) => {
                    setNewMessage(prev => prev + emoji);
                }}
            />
            <Tooltip title="File attachment feature coming soon!" arrow placement="top">
                <button className="p-1.5 sm:p-2 text-gray-400 hover:text-gray-600 disabled:opacity-50" disabled>
                    <AttachFileIcon className="w-4 h-4 sm:w-5 sm:h-5" />
                </button>
            </Tooltip>
        </div>
        <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => {
                setNewMessage(e.target.value);
                handleTyping();
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                }
            }}
            className="flex-1 py-1.5 sm:py-2 px-3 sm:px-4 bg-gray-50 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
        />
        <button
            onClick={handleSendMessage}
            disabled={!newMessage.trim()}
            className="p-1.5 sm:p-2 text-white bg-emerald-500 rounded-full hover:bg-emerald-600 disabled:opacity-50 disabled:hover:bg-emerald-500 transition-colors"
        >
            <SendIcon className="w-4 h-4 sm:w-5 sm:h-5" />
        </button>
    </div>
</div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

ChatPopup.propTypes = {
    currentUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['admin', 'student', 'teacher']).isRequired,
    }).isRequired,
    availableUsers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string,
            type: PropTypes.oneOf(['student', 'teacher', 'admin']).isRequired,
            avatar: PropTypes.string,
        })
    ).isRequired,
};

ChatPopup.defaultProps = {
    availableUsers: []
};

export default ChatPopup;
