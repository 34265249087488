import React, { useState, useLayoutEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { animateScroll as scroll } from "react-scroll";
import Speakableimg from "../../Speakableimg.jpg";
import SignupFormPopup from "../SignupFormPopup";
import SigninFormPopup from "../SigninFormPopup";
import { useTranslation } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-scroll';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

const Navbar = () => {
  const { t, i18n } = useTranslation("global");
  const [signupFormPopup, setSignupFormPopup] = useState(false);
  const [signinFormPopup, setSigninFormPopup] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLang = localStorage.getItem('userLanguage');
    if (savedLang) {
      return savedLang === 'en' ? 'English' : 'Polish';
    }
    return i18n.language === 'en' ? 'English' : 'Polish';
  });
  const location = useLocation();

  // Function to detect user's country and set language
  const detectAndSetLanguage = async () => {
    try {
      // Only proceed if there's no language saved in localStorage
      if (!localStorage.getItem('userLanguage')) {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        
        // Set language based on country code
        const userLanguage = data.country_code === 'PL' ? 'pl' : 'en';
        
        // Save to localStorage and cookies
        localStorage.setItem('userLanguage', userLanguage);
        setCookie('language', userLanguage, 30);
        
        // Update i18n and selected language
        i18n.changeLanguage(userLanguage);
        setSelectedLanguage(userLanguage === 'en' ? 'English' : 'Polish');
      }
    } catch (error) {
      console.error('Error detecting user location:', error);
      // Fallback to English if detection fails
      const fallbackLanguage = 'en';
      localStorage.setItem('userLanguage', fallbackLanguage);
      setCookie('language', fallbackLanguage, 30);
      i18n.changeLanguage(fallbackLanguage);
      setSelectedLanguage('English');
    }
  };

  useLayoutEffect(() => {
    detectAndSetLanguage();
  }, []);

  const scrollWithOffset = (el) => {
    const headerOffset = document.querySelector(".Navbar_main").offsetHeight || 70;
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -headerOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const scrollTo = useCallback((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const headerOffset = document.querySelector(".Navbar_main").offsetHeight || 70;
      const yCoordinate = element.offsetTop - headerOffset;
      scroll.scrollTo(yCoordinate, {
        duration: 800,
        smooth: "easeInOutQuart",
      });
    } else {
      setTimeout(() => scrollTo(elementId), 300);
    }
  }, []);

  useLayoutEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      setTimeout(() => scrollTo(id), 500);
    }
  }, [location, scrollTo]);

  const navigateHandler = () => {
    navigate("/");
  };

  const openSignupFormPopup = () => setSignupFormPopup(true);
  const closeSignupFormPopup = () => setSignupFormPopup(false);
  const openSigninFormPopup = () => setSigninFormPopup(true);
  const closeSigninFormPopup = () => setSigninFormPopup(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const handleLinkClick = (elementId) => {
    setMenuOpen(false);
    if (location.pathname !== "/") {
      navigate(`/#${elementId}`);
    } else {
      navigate(`/#${elementId}`, { replace: true });
      scrollTo(elementId);
    }
  };

  const handleChangeLanguage = (lang) => {
    setSelectedLanguage(lang === 'en' ? 'English' : 'Polish');
    i18n.changeLanguage(lang);
    setCookie('language', lang, 30);
    localStorage.setItem('userLanguage', lang);
  };

  const navLinks = [
    { to: "Home", label: t("header.Home") },
    { to: "Courses", label: t("header.Courses") },
    { to: "About", label: t("header.About") },
    { to: "OurTeachers", label: t("header.OurTeachers") },
    { to: "Reviews", label: t("header.Reviews") },
    { to: "Contact", label: t("header.Contact") },
  ];

  const renderNavLinks = (additionalClass = "") => (
    <>
      {navLinks.map((link) => (
        <HashLink
          smooth
          key={link.to}
          to={`/#${link.to}`}
          scroll={scrollWithOffset}
          style={{ cursor: "pointer" }}
          className={`d_center text-decoration-none Navlink ${additionalClass}`}
          onClick={() => handleLinkClick(link.to)}
        >
          {link.label}
        </HashLink>
      ))}
    </>
  );

  return (
    <>
      <div className="Navbar_main">
        <div className="Navbar_nav_firstdiv">
          <img
            style={{ cursor: "pointer" }}
            onClick={navigateHandler}
            src={Speakableimg}
            alt="Speakable Logo"
          />
        </div>
        <div className="Navbar_nav_div">
          {renderNavLinks()}
        </div>
        <div className="Navbar_nav_lastdiv">
          <button
            className="btn btn-outline-success nav-btn-sign mobile-visible"
            onClick={openSignupFormPopup}
          >
            {t("header.Signup")}
          </button>
          <button
            className="btn btn-outline-success nav-btn-sign mobile-visible"
            onClick={openSigninFormPopup}
          >
            {t("header.Signin")}
          </button>
          <div className="d-flex align-items-center">
            <div className="dropdown mobile-visible">
              <button
                className="dropdown-toggle mobile-visible"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <i className="bi bi-globe-central-south-asia mx-2"></i>
                {selectedLanguage}
              </button>

              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleChangeLanguage("en")}
                  >
                    <img
                      style={{
                        border: "0.01px solid rgb(8 8 8 / 46%)",
                        marginRight: "15px",
                      }}
                      width={25}
                      height={15}
                      src={require("../../assets/English.webp")}
                      alt="English"
                    />
                    English
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleChangeLanguage("pl")}
                  >
                    <img
                      style={{
                        border: "0.01px solid rgb(8 8 8 / 46%)",
                        marginRight: "15px",
                      }}
                      width={25}
                      height={15}
                      src={require("../../assets/Polish.png")}
                      alt="Polish"
                    />
                    Polish
                  </a>
                </li>
              </ul>
            </div>
            <button
              className="btn btn-outline-success mx-2 menu-button"
              onClick={toggleMenu}
            >
              <i className="bi bi-list"></i>
            </button>
          </div>
        </div>
        {signupFormPopup && <SignupFormPopup handleClose={closeSignupFormPopup} />}
        {signinFormPopup && <SigninFormPopup handleClose={closeSigninFormPopup} />}
      </div>

      {menuOpen && (
        <div className="responsive-menu">
          {renderNavLinks("mt-2")}
          <button className="btn btn-outline-danger mt-2" onClick={closeMenu}>
            {t("header.Close")}
          </button>
        </div>
      )}
    </>
  );
};

export default Navbar;