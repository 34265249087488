import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetch5Teachers } from '../../store/actions/teachersActions';
import { fetch5courses } from '../../store/actions/coursesActions';
import Speakableimg from "../../Speakableimg.jpg";
import SignupFormPopup from "../SignupFormPopup";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SigninFormPopup from "../SigninFormPopup";
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';


  
  // Language selector component
  
  const Home = () => {
const [showBooking, setShowBooking] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [showTeacherModal, setShowTeacherModal] = useState(false);
    const [showSelectTeacherMessage, setShowSelectTeacherMessage] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [language, setLanguage] = useState(i18next.language);
    const { t } = useTranslation("global");

    // Function to detect user's country and set language
    useEffect(() => {
        const detectCountryAndSetLanguage = async () => {
            try {
                // Use a free IP geolocation API
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                
                // If user is from Poland, set language to Polish, otherwise English
                const userLanguage = data.country_code === 'PL' ? 'pl' : 'en';
                i18next.changeLanguage(userLanguage);
                setLanguage(userLanguage);
            } catch (error) {
                console.error('Error detecting country:', error);
                // Fallback to English in case of error
                i18next.changeLanguage('en');
                setLanguage('en');
            }
        };

        detectCountryAndSetLanguage();
    }, []);

    const changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        setLanguage(lng);
    };

    // Language selector component defined within Home
    const LanguageSelector = () => (
        <div className="fixed bottom-20 right-4 z-50">
            <select 
                value={language} 
                onChange={(e) => changeLanguage(e.target.value)}
                className="bg-white border border-gray-300 rounded-lg px-4 py-2 shadow-lg hover:border-blue-500 transition-all duration-300"
            >
                <option value="en">English</option>
                <option value="pl">Polski</option>
            </select>
        </div>
    );

    const handleFreeLessonClick = () => {
        setShowSelectTeacherMessage(true);
        const teachersSection = document.getElementById('teachers');
        if (teachersSection) {
            teachersSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleChooseTeacher = () => {
        setShowTeacherModal(false);
        setShowBooking(true);
    };

    // Navigation Component
    const Navigation = ({ menuOpen, setMenuOpen }) => {
        const { t } = useTranslation("global");
        const [signupFormPopup, setSignupFormPopup] = useState(false);
        const [signinFormPopup, setSigninFormPopup] = useState(false);
      
        const handleMenuClick = () => setMenuOpen(false);
        const openSignupFormPopup = () => setSignupFormPopup(true);
        const closeSignupFormPopup = () => setSignupFormPopup(false);
        const openSigninFormPopup = () => setSigninFormPopup(true);
        const closeSigninFormPopup = () => setSigninFormPopup(false);
      
        return (
          <nav className="bg-white shadow-md sticky top-0 z-50 animate-fade-in">
            <div className="container mx-auto px-4 py-2">
              <div className="flex items-center justify-between">
                {/* Logo */}
                <div className="flex items-center">
                  <img 
                    src={Speakableimg} 
                    alt={t("navigation.brand")} 
                    className="h-8 w-8 mr-2 transition-transform hover:scale-105"
                  />
                  <a href="#" className="text-blue-700 font-bold text-xl">
                    {t("navigation.brand")}
                  </a>
                </div>
      
                {/* Desktop Navigation */}
                <div className="hidden lg:flex space-x-4 animate-fade-in-down">
                  {['home', 'individual', 'business', 'courses', 'methodology', 
                    'pricing', 'teachers', 'faq', 'contact'].map(key => (
                    <a 
                      key={key}
                      href={`#${key}`}
                      onClick={handleMenuClick}
                      className="text-gray-700 hover:text-blue-700 transition duration-300 hover:scale-105"
                    >
                      {t(`navigation.${key}`)}
                    </a>
                  ))}
                </div>
      
                {/* Auth Buttons */}
                <div className="flex items-center space-x-2">
                  <button 
                    onClick={openSigninFormPopup}
                    className="bg-blue-700 text-white py-1 px-3 text-sm rounded hover:bg-blue-800 transition-all duration-300 hover:scale-105 animate-fade-in"
                  >
                    {t("navigation.signIn")}
                  </button>
                  <button 
                    onClick={openSignupFormPopup}
                    className="border border-blue-700 text-blue-700 py-1 px-3 text-sm rounded hover:bg-blue-50 transition-all duration-300 hover:scale-105 animate-fade-in"
                  >
                    {t("navigation.signUp")}
                  </button>
      
                  {/* Mobile Menu Button */}
                  <div className="lg:hidden ml-2">
                    <button
                      onClick={() => setMenuOpen(!menuOpen)}
                      className="text-gray-700 hover:text-blue-700 focus:outline-none"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        {menuOpen ? (
                          <path d="M6 18L18 6M6 6l12 12" />
                        ) : (
                          <path d="M4 6h16M4 12h16M4 18h16" />
                        )}
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
      
              {/* Mobile Menu */}
              {menuOpen && (
                <div className="lg:hidden mt-4 animate-fade-in-down">
                  <div className="flex flex-col space-y-2">
                    {['home', 'individual', 'business', 'courses', 'methodology', 
                      'pricing', 'teachers', 'faq', 'contact'].map(key => (
                      <a
                        key={key}
                        href={`#${key}`}
                        onClick={handleMenuClick}
                        className="text-gray-700 hover:text-blue-700 transition duration-300 py-2 px-4 rounded hover:bg-gray-100"
                      >
                        {t(`navigation.${key}`)}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
      
            {/* Popups */}
            {signupFormPopup && <SignupFormPopup handleClose={closeSignupFormPopup} />}
            {signinFormPopup && <SigninFormPopup handleClose={closeSigninFormPopup} />}
          </nav>
        );
      };

    // Home Section Component
    const HomeSection = ({ handleFreeLessonClick }) => {
        const { t } = useTranslation("global");
        
        return (
          <section id="home" className="bg-gradient-to-r from-purple-500 to-blue-700 text-white text-center py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
              <h1 className="text-3xl md:text-6xl font-bold animate-fade-in-up">
                {t("navigation.heroTitle")}
              </h1>
              <p className="mt-4 text-lg md:text-xl animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                {t("navigation.heroSubtitle")}
              </p>
              <button 
                onClick={handleFreeLessonClick} 
                className="mt-6 inline-block bg-orange-500 text-white font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-orange-600 transition-all duration-300 hover:scale-110"
              >
                {t("navigation.freeTrial")}
              </button>
            </div>
            <LanguageSelector />

          </section>
        );
      };

    // Promotion Section Component
    const PromotionSection = () => (
        <section className="bg-white text-gray-800 py-10 px-4">
            <div className="container mx-auto text-center">
                <div className="flex flex-col md:flex-row justify-center items-center">
                    <span className="text-4xl md:text-5xl mr-0 md:mr-4">🎁💸</span>
                    <h2 className="text-2xl md:text-3xl font-bold">
                    {t("navigation.promotionTitle")}
                    </h2>
                </div>
            </div>
        </section>
    );

    // Platform Section Component
    const PlatformSection = () => (
        <section id="platform" className="bg-gray-100 text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.platformTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="p-6 text-center animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '200ms' }}>
                        <span className="text-6xl animate-float">💻</span>
                        <h3 className="text-2xl font-bold mt-4">{t("navigation.reviewTitle")}</h3>
                        <p className="mt-2">{t("navigation.reviewDescription")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '400ms' }}>
                        <span className="text-6xl animate-float">🎮</span>
                        <h3 className="text-2xl font-bold mt-4">{t("navigation.gamifiedTitle")}</h3>
                        <p className="mt-2">{t("navigation.gamifiedDescription")}</p>
                    </div>
                    <div className="p-6 text-center animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '600ms' }}>
                        <span className="text-6xl animate-float">📱</span>
                        <h3 className="text-2xl font-bold mt-4">{t("navigation.accessTitle")}</h3>
                        <p className="mt-2">{t("navigation.accessDescription")}</p>
                    </div>
                </div>
            </div>
        </section>
    );

    // Inspiration Section Component
    const InspirationSection = () => (
        <section className="bg-white text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 animate-fade-in-up">
                {t("navigation.inspirationTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-2 md:grid-cols-4 gap-8">
                    <div className="p-6 animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '200ms' }}>
                        <h3 className="text-4xl font-bold text-blue-700 animate-pulse-slow">9+</h3>
                        <p className="mt-2">{t("navigation.experienceLabel")}</p>
                    </div>
                    <div className="p-6 animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '400ms' }}>
                        <h3 className="text-4xl font-bold text-blue-700 animate-pulse-slow">70+</h3>
                        <p className="mt-2">{t("navigation.coursesLabel")}</p>
                    </div>
                    <div className="p-6 animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '600ms' }}>
                        <h3 className="text-4xl font-bold text-blue-700 animate-pulse-slow">3200+</h3>
                        <p className="mt-2">{t("navigation.teachersLabel")}</p>
                    </div>
                    <div className="p-6 animate-fade-in-up hover:scale-105 transition-all duration-300" style={{ animationDelay: '800ms' }}>
                        <h3 className="text-4xl font-bold text-blue-700 animate-pulse-slow">75,000+</h3>
                        <p className="mt-2">{t("navigation.studentsLabel")}</p>
                    </div>
                </div>
            </div>
        </section>
    );

    // Methodology Section Component
    const MethodologySection = () => (
        <section id="methodology" className="bg-gray-100 text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.methodologyTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="p-6 flex animate-fade-in-left hover:scale-105 transition-all duration-300" style={{ animationDelay: '200ms' }}>
                        <span className="text-4xl mr-4">🤓</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.methodTitle")}</h3>
                            <p className="mt-2">
                            {t("navigation.methodDescription")}
                            </p>
                        </div>
                    </div>
                    <div className="p-6 flex animate-fade-in-right hover:scale-105 transition-all duration-300" style={{ animationDelay: '400ms' }}>
                        <span className="text-4xl mr-4">💁‍♀️</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.approachTitle")}</h3>
                            <p className="mt-2">{t("navigation.approachDescription")}</p>
                        </div>
                    </div>
                    <div className="p-6 flex animate-fade-in-left hover:scale-105 transition-all duration-300" style={{ animationDelay: '600ms' }}>
                        <span className="text-4xl mr-4">💻</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.appTitle")}</h3>
                            <p className="mt-2">
                            {t("navigation.appDescription")}
                            </p>
                        </div>
                    </div>
                    <div className="p-6 flex animate-fade-in-right hover:scale-105 transition-all duration-300" style={{ animationDelay: '800ms' }}>
                        <span className="text-4xl mr-4">🌎</span>
                        <div>
                            <h3 className="text-2xl font-bold">{t("navigation.globalTeachersTitle")}</h3>
                            <p className="mt-2">
                            {t("navigation.globalTeachersDescription")}.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    // Pricing Section Component
    const PricingSection = () => {
        const dispatch = useDispatch();
        const courses = useSelector((state) => state.courses.courseslist);

        useEffect(() => {
            dispatch(fetch5courses());
        }, [dispatch]);

        const tiers = ["Basic", "Standard", "Pro", "Premium"];
        const lessons = ["10", "20", "50", "75"];

        return (
            <section id="pricing" className="bg-white text-gray-800 py-20 px-4 animate-fade-in">
                <div className="container mx-auto">
                    <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">{t("navigation.pricingTitle")}</h2>
                    <p className="mt-4 text-lg md:text-xl text-center animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                    {t("navigation.pricingSubtitle")}!
                    </p>
                    <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {courses?.map((course, index) => (
                            <div 
                                key={index} 
                                className="bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 animate-fade-in-up hover:scale-105"
                                style={{ animationDelay: `${(index + 2) * 200}ms` }}
                            >
                                <h3 className="text-2xl font-bold">{tiers[index]}</h3>
                                <p className="mt-2 font-bold">{course.Course_Name}</p>
                                <p className="mt-2">{lessons[index]} {t("navigation.lessonsCount")}</p>
                                <p className="mt-2 text-xl font-bold animate-pulse-slow">PLN {course.Purchase_Price} / {t("navigation.lessonsCount")}</p>
                                <a 
                                    href="#contact" 
                                    className="mt-6 inline-block bg-blue-700 text-white font-semibold py-2 px-6 rounded-full hover:bg-blue-800 transition-all duration-300 hover:scale-110"
                                >
                                    {t("navigation.enrollNow")}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    };

    

    const TeachersSection = ({ setSelectedTeacher, setShowTeacherModal }) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const teachersData = useSelector((state) => state.teachers.Teacherslist);
        const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
        useEffect(() => {
            dispatch(fetch5Teachers());
            
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
            };
    
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [dispatch]);
    
        if (!teachersData || teachersData.length === 0) {
            return null;
        }
    
        const handleTeacherClick = (teacher) => {
            navigate(`/TeacherDetails/${teacher._id}`);
        };
    
        // Carousel settings with mobile-specific adjustments
        const settings = {
            showArrows: true,
            showStatus: false,
            showIndicators: true,
            infiniteLoop: true,
            centerMode: true,
            centerSlidePercentage: isMobile ? 100 : 33.33,
            emulateTouch: true,
            swipeable: true,
            dynamicHeight: isMobile,
            showThumbs: false,
            useKeyboardArrows: true,
            autoPlay: false,
            interval: 5000,
            transitionTime: 500,
            // Add these settings for better mobile touch handling
            swipeScrollTolerance: 5,
            preventMovementUntilSwipeScrollTolerance: true,
            stopOnHover: true
        };
    
        return (
            <section id="teachers" className="bg-gray-100 py-16">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl md:text-4xl font-bold text-blue-700 mb-4">
                        {t("navigation.ourTeachers")}
                        </h2>
                        <p className="text-lg text-gray-600">
                        {t("navigation.meetTeam2")}
                        </p>
                    </div>
    
                    <div className="max-w-7xl mx-auto">
                        <Carousel {...settings} className="teachers-carousel">
                            {teachersData.map((teacher) => (
                                <div key={teacher._id} className="px-2 pb-6">
                                    <div className="bg-white rounded-lg shadow-md overflow-hidden mx-2 h-full hover:shadow-xl transition-shadow duration-300">
                                        <div className="relative group" onClick={() => handleTeacherClick(teacher)}>
                                            <img
                                                src={teacher.Profile_Image?.[0]
                                                    ? `https://ik.imagekit.io/8s3jwexmv/${teacher.Profile_Image[0]}`
                                                    : '/api/placeholder/300/400'
                                                }
                                                alt={teacher.Username}
                                                className="w-full h-64 object-cover"
                                            />
                                            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedTeacher(teacher);
                                                        setShowTeacherModal(true);
                                                    }}
                                                    className="bg-white text-blue-700 px-6 py-2 rounded-full hover:bg-blue-50 transition-colors"
                                                >
                                                   {t("navigation.viewProfile")} 
                                                </button>
                                            </div>
                                        </div>
                                        <div className="p-6 text-center">
                                            <h3 className="text-xl font-bold mb-2">
                                                {teacher.Username}
                                            </h3>
                                            <p className="text-gray-600">
                                                {teacher.Short_Title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
    
                <style jsx>{`
                    .teachers-carousel .carousel.carousel-slider {
                        touch-action: pan-y pinch-zoom;
                    }
                    
                    .teachers-carousel .carousel .slide {
                        touch-action: pan-y pinch-zoom;
                    }
                `}</style>
            </section>
        );
    };

    // FAQ Section Component
    const FAQSection = () => (
        <section id="faq" className="bg-white text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 text-center animate-fade-in-up">
                {t("navigation.faqTitle")}
                </h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-left hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold">
                        {t("navigation.signupQuestion")}?
                        </h3>
                        <p className="mt-2">
                        {t("navigation.signupAnswer")}.
                        </p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-right hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold">
                        {t("navigation.changeTeacherQuestion")}?
                        </h3>
                        <p className="mt-2">
                        {t("navigation.changeTeacherAnswer")}.
                        </p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-left hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold"> {t("navigation.childrenQuestion")}?</h3>
                        <p className="mt-2">
                        {t("navigation.childrenAnswer")}.
                        </p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg animate-fade-in-right hover:shadow-lg transition-all duration-300 hover:scale-105">
                        <h3 className="text-2xl font-bold">{t("navigation.paymentQuestion")}</h3>
                        <p className="mt-2">
                        {t("navigation.paymentAnswer")}.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
  

    // Contact Section Component
    const ContactSection = () => (
        <section id="contact" className="bg-gray-100 text-gray-800 py-20 px-4 animate-fade-in">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl md:text-4xl font-bold text-blue-700 animate-fade-in-up">
                {t("navigation.contactTitle")}
                </h2>
                <p className="mt-4 text-lg md:text-xl animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                {t("navigation.contactSubtitle")}!
                </p>
                <form className="mt-10 max-w-xl mx-auto animate-fade-in-up" style={{ animationDelay: '400ms' }}>
                    <input
                        type="text"
                        placeholder={t("navigation.yourName")}
                        className="w-full p-3 rounded mb-4 border hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                    />
                    <input
                        type="email"
                        placeholder={t("navigation.yourEmail")}
                        className="w-full p-3 rounded mb-4 border hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                    />
                    <textarea
                        placeholder={t("navigation.yourMessage")}
                        className="w-full p-3 rounded mb-4 border hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                        rows="4"
                    />
                    <button
                        type={t("FillEmailPopup.submit")}
                        className="bg-orange-500 text-white font-bold py-2 px-6 rounded-full hover:bg-orange-600 transition-all duration-300 hover:scale-110 animate-pulse-slow"
                    >
                        {t("navigation.send")}
                    </button>
                </form>
                <div className="mt-10 text-left md:text-center">
                    <div className="space-y-4 animate-fade-in-up" style={{ animationDelay: '600ms' }}>
                        <p className="text-lg hover:text-blue-700 transition-all duration-300">
                            <span className="inline-block">📞</span> +48727710433
                        </p>
                        <p className="text-lg hover:text-blue-700 transition-all duration-300">
                            <span className="inline-block">✉️</span> {t("navigation.email")}
                        </p>
                        <p className="text-lg hover:text-blue-700 transition-all duration-300">
                            <span className="inline-block">🕒</span> {t("navigation.hours")}                        </p>
                        <p className="text-lg hover:text-blue-700 transition-all duration-300">
                            <span className="inline-block">📍</span>{t("navigation.address")}                         </p>
                    </div>
                </div>
            </div>
        </section>
    );

    // Footer Component
    const Footer = () => (
        <footer className="bg-gray-800 text-white py-6 px-4 animate-fade-in">
            <div className="container mx-auto text-center">
                <p className="animate-fade-in-up">
                    &copy; {t("navigation.copyright")}.
                </p>
                <div className="flex justify-center space-x-4 mt-4 animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                    <a href="#" className="hover:text-gray-400 transition-all duration-300 hover:scale-110">{t("navigation.facebook")}</a>
                    <a href="#" className="hover:text-gray-400 transition-all duration-300 hover:scale-110">{t("navigation.instagram")}</a>
                </div>
                <div className="mt-4">
    
    <a href="https://speakable.online/api/pdf/rulesandcondition.pdf" className="text-gray-400 hover:text-white mx-2 transition-all duration-300 hover:scale-110">
        {t("navigation.termsLink")}
    </a>
    <a href="https://speakable.online/api/pdf/privacy.pdf" className="text-gray-400 hover:text-white mx-2 transition-all duration-300 hover:scale-110">
        {t("navigation.privacyLink")}
    </a>
    <a href="https://speakable.online/api/pdf/rulesandcondition.pdf" className="text-gray-400 hover:text-white mx-2 transition-all duration-300 hover:scale-110">
        {t("navigation.cookiesLink")}
    </a>
</div>
            </div>
        </footer>
    );

    // TeacherModal Component with enhanced animations
    const TeacherModal = ({ teacher, onClose, handleChooseTeacher }) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fade-in">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-4 overflow-auto max-h-screen p-6 animate-zoom-in">
                <button 
                    onClick={onClose} 
                    className="text-gray-500 hover:text-gray-700 float-right transition-colors duration-300 hover:scale-110"
                >
                    {t("navigation.close")}
                </button>
                <div className="mt-4">
                    <h2 className="text-2xl font-bold animate-fade-in-up">{teacher.name}</h2>
                    <p className="mt-2 animate-fade-in-up" style={{ animationDelay: '200ms' }}>{teacher.specialization}</p>
                    <p className="mt-4 animate-fade-in-up" style={{ animationDelay: '400ms' }}>{teacher.description}</p>
                    {teacher.video && (
                        <div className="mt-4 animate-fade-in-up" style={{ animationDelay: '600ms' }}>
                            <video controls className="w-full h-auto rounded-lg shadow-lg">
                                <source src={teacher.video} type="video/mp4" />
                                {t("navigation.Yourbrowserdoesntsupportvideoplayback")}.
                            </video>
                        </div>
                    )}
                    <button 
                        onClick={handleChooseTeacher} 
                        className="mt-6 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition-all duration-300 hover:scale-110 animate-fade-in-up"
                        style={{ animationDelay: '800ms' }}
                    >
                        {t("navigation.choose")}
                    </button>
                </div>
            </div>
        </div>
    );

    // FreeLessonBooking Component with enhanced animations
    const FreeLessonBooking = ({ setShowBooking, selectedTeacher, setSelectedTeacher }) => {
        const [step, setStep] = useState(selectedTeacher ? 2 : 1);
        const [selectedDate, setSelectedDate] = useState(null);
        const [selectedTime, setSelectedTime] = useState(null);
        const [formData, setFormData] = useState({
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        });

        const teachers = [
            { id: 1, name: 'Anna', specialization: 'Business English' },
            { id: 2, name: 'John', specialization: 'Native Speaker from USA' },
            { id: 3, name: 'Eva', specialization: 'English for Kids' },
        ];

        const availableTimes = ['10:00', '11:00', '14:00', '16:00'];

        const handleTeacherSelect = (teacher) => {
            setSelectedTeacher(teacher);
            setStep(2);
        };

        const handleDateSelect = (event) => {
            setSelectedDate(event.target.value);
            setStep(3);
        };

        const handleTimeSelect = (time) => {
            setSelectedTime(time);
            setStep(4);
        };

        const handleFormChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };

        const handleFormSubmit = (e) => {
            e.preventDefault();
            setStep(5);
        };

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fade-in">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 overflow-auto max-h-screen animate-zoom-in">
                    <div className="p-6">
                        <button 
                            onClick={() => setShowBooking(false)} 
                            className="text-gray-500 hover:text-gray-700 float-right transition-colors duration-300 hover:scale-110"
                        >
                             {t("navigation.close")}
                        </button>
                        
                        {step === 1 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.chooseTeacher")}</h2>
                                {teachers.map((teacher, index) => (
                                    <div
                                        key={teacher.id}
                                        className="p-4 border rounded mb-2 cursor-pointer hover:bg-gray-100 transition-all duration-300 animate-fade-in-up hover:scale-105"
                                        style={{ animationDelay: `${index * 200}ms` }}
                                        onClick={() => handleTeacherSelect(teacher)}
                                    >
                                        <h3 className="text-xl font-semibold">{teacher.name}</h3>
                                        <p>{teacher.specialization}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                        {step === 2 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.selectDate")}</h2>
                                {selectedTeacher && (
                                    <p className="mb-4 animate-fade-in-up"> {t("navigation.selectedTeacher")}: <strong>{selectedTeacher.name}</strong></p>
                                )}
                                <label className="block mb-2 animate-fade-in-up" style={{ animationDelay: '200ms' }}> {t("navigation.timezone")}:</label>
                                <select className="w-full p-2 border rounded mb-4 hover:border-blue-500 transition-colors duration-300 animate-fade-in-up" style={{ animationDelay: '400ms' }}>
                                    <option>UTC+1 - Warsaw</option>
                                    <option>UTC+2 - Kiev</option>
                                    <option>UTC+0 - London</option>
                                </select>
                                <input 
                                    type="date" 
                                    className="w-full p-2 border rounded mb-4 hover:border-blue-500 transition-colors duration-300 animate-fade-in-up" 
                                    onChange={handleDateSelect}
                                    style={{ animationDelay: '600ms' }}
                                />
                            </div>
                        )}

                        {step === 3 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.selectTime")}</h2>
                                {availableTimes.map((time, index) => (
                                    <button
                                        key={index}
                                        className="w-full p-2 border rounded mb-2 hover:bg-gray-100 transition-all duration-300 animate-fade-in-up hover:scale-105"
                                        style={{ animationDelay: `${index * 200}ms` }}
                                        onClick={() => handleTimeSelect(time)}
                                    >
                                        {time}
                                    </button>
                                ))}
                            </div>
                        )}

                        {step === 4 && (
                            <div className="animate-fade-in">
                                <h2 className="text-2xl font-bold mb-4 animate-fade-in-up"> {t("navigation.yourDetails")}</h2>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="space-y-4">
                                        {[
                                            { label: 'First Name*', name: 'firstName', type: 'text', required: true },
                                            { label: 'Last Name', name: 'lastName', type: 'text', required: false },
                                            { label: 'Email*', name: 'email', type: 'email', required: true },
                                            { label: 'Phone Number', name: 'phone', type: 'tel', required: false }
                                        ].map((field, index) => (
                                            <div 
                                                key={field.name}
                                                className="animate-fade-in-up"
                                                style={{ animationDelay: `${index * 200}ms` }}
                                            >
                                                <label className="block mb-2">{field.label}</label>
                                                <input
                                                    type={field.type}
                                                    name={field.name}
                                                    required={field.required}
                                                    className="w-full p-2 border rounded mb-4 hover:border-blue-500 focus:border-blue-700 transition-all duration-300"
                                                    onChange={handleFormChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button 
                                        type="submit" 
                                        className="w-full bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition-all duration-300 hover:scale-105 animate-fade-in-up"
                                        style={{ animationDelay: '800ms' }}
                                    >
                                         {t("navigation.bookNow")}
                                    </button>
                                </form>
                            </div>
                        )}

                        {step === 5 && (
                            <div className="text-center animate-zoom-in">
                                <h2 className="text-2xl font-bold mb-4"> {t("navigation.thankYou")}!</h2>
                                <p className="animate-fade-in-up" style={{ animationDelay: '200ms' }}>
                                {t("navigation.bookingConfirmed")}
                                </p>
                                <button
                                    onClick={() => setShowBooking(false)}
                                    className="mt-6 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-800 transition-all duration-300 hover:scale-110 animate-fade-in-up"
                                    style={{ animationDelay: '400ms' }}
                                >
                                     {t("navigation.close")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="animate-fade-in">
            <Navigation
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                handleFreeLessonClick={handleFreeLessonClick}
            />
            <HomeSection handleFreeLessonClick={handleFreeLessonClick} />
            <PromotionSection />
            <PlatformSection />
            <InspirationSection />
            <MethodologySection />
            <PricingSection />
            <TeachersSection
                selectedTeacher={selectedTeacher}
                setSelectedTeacher={setSelectedTeacher}
                setShowTeacherModal={setShowTeacherModal}
                showSelectTeacherMessage={showSelectTeacherMessage}
            />
            <FAQSection />
            <ContactSection />
            <Footer />
            {showTeacherModal && (
                <TeacherModal
                    teacher={selectedTeacher}
                    onClose={() => setShowTeacherModal(false)}
                    handleChooseTeacher={handleChooseTeacher}
                />
            )}
            {showBooking && (
                <FreeLessonBooking
                    setShowBooking={setShowBooking}
                    selectedTeacher={selectedTeacher}
                    setSelectedTeacher={setSelectedTeacher}
                />
            )}
        </div>
    );
};

export default Home;