// PaymentPage.jsx
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { CreditCard, X } from 'lucide-react';
import axios from '../../helpers/axiosconfig';
import Premium3DCard from './Premium3DCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storePaymentDetails } from '../../store/actions/paymentActions';
import { useTranslation } from "react-i18next";


const PaymentPage = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const blikInputRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showCardPopup, setShowCardPopup] = useState(false);
  const [showBlikPopup, setShowBlikPopup] = useState(false);
  const [blikCode, setBlikCode] = useState('');
  const [blikError, setBlikError] = useState('');
  const [paymentWindow, setPaymentWindow] = useState(null);
  const [pendingPayment, setPendingPayment] = useState(null);

  const [formData, setFormData] = useState({
    Email: '',
    Phone: '',
    StudentName: '',
    Description: '',
    Package_ID: '',
    totalAmount: 0,
    StudentID: '',
    hours: 1,
    billingAddress: {},
    companyDetails: {},
    installmentDetails: null
  });

  // Initialize form data from localStorage
  useEffect(() => {
    const storedPaymentData = localStorage.getItem('pendingPaymentData');
    if (storedPaymentData) {
      try {
        const paymentData = JSON.parse(storedPaymentData);
        setPendingPayment(paymentData);
        setFormData({
          Email: paymentData.user?.Email || '',
          Phone: paymentData.user?.Phone_Number?.replace(/\s+/g, '') || '',
          StudentName: paymentData.user?.Username || '',
          Description: paymentData.packageName || 'Course Payment',
          Package_ID: paymentData.Package_ID || '',
          totalAmount: parseFloat(paymentData.totalAmount.replace(/[^0-9.]/g, '')),
          StudentID: paymentData.user?._id || '',
          hours: paymentData.hours || 1,
          billingAddress: paymentData.billingAddress || {},
          companyDetails: paymentData.companyDetails || {},
          installmentDetails: paymentData.installmentDetails || null
        });
      } catch (error) {
        console.error('Error parsing payment data:', error);
        setError('Invalid payment data. Please try again.');
        navigate('/Student-dashboard/Packages');
      }
    } else {
      navigate('/Student-dashboard/Packages');
    }
  }, [navigate]);

  // Handle payment window events
  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event.data.paymentStatus) {
        if (paymentWindow) {
          paymentWindow.close();
          setPaymentWindow(null);
        }
        if (event.data.paymentStatus === 'success') {
          localStorage.removeItem('pendingPaymentData');
          navigate('/Student-dashboard/PaymentSuccess');
        }
      }
    };

    window.addEventListener('message', handlePaymentMessage);
    return () => window.removeEventListener('message', handlePaymentMessage);
  }, [paymentWindow, navigate]);

  // Monitor popup window status
  useEffect(() => {
    let checkInterval;
    if (paymentWindow) {
      checkInterval = setInterval(() => {
        if (paymentWindow.closed) {
          clearInterval(checkInterval);
          setPaymentWindow(null);
          setLoading(false);
        }
      }, 500);
    }
    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [paymentWindow]);

  // Initialize PayU and fetch payment methods
  useEffect(() => {
    const initializePayu = async () => {
      try {
        setLoading(true);
        setError(null);
        const tokenResponse = await axios.get('payu/token');
        
        if (tokenResponse.data.token) {
          const methodsResponse = await axios.get('payu/payment-methods');
          if (methodsResponse.data?.paymentMethods?.payByLinks) {
            setPaymentMethods(methodsResponse.data.paymentMethods.payByLinks);
          } else {
            setError('Invalid payment methods data structure');
          }
        } else {
          setError('Failed to obtain authorization token');
        }
      } catch (error) {
        console.error('PayU initialization error:', error);
        setError(error.response?.data?.message || error.message || 'An error occurred during payment initialization');
      } finally {
        setLoading(false);
      }
    };

    initializePayu();
  }, []);

  const openPaymentPopup = (url) => {
    const width = 800;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      url,
      'PayU Payment',
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
    );

    if (popup) {
      setPaymentWindow(popup);
    } else {
      setError('Please enable popups to proceed with the payment');
      setLoading(false);
    }
  };

  const handleMethodSelect = (method) => {
    setSelectedMethod(method);
    if (method.value === 'c') {
      setShowCardPopup(true);
    } else if (method.value === 'blik') {
      setShowBlikPopup(true);
      setBlikError('');
      setBlikCode('');
    }
  };

  const handleBlikCodeChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '').substring(0, 6);
    setBlikCode(value);
    setBlikError('');
    // Keep focus on the input
    blikInputRef.current?.focus();
  };

  const validateBlikCode = () => {
    if (blikCode.length !== 6) {
      setBlikError('BLIK code must be 6 digits');
      return false;
    }
    return true;
  };

  // Validate form data
  const validateFormData = () => {
    console.log('Validating form data:', {
      email: formData.Email,
      phone: formData.Phone,
      studentName: formData.StudentName,
      studentId: formData.StudentID,
      hours: formData.hours,
      selectedMethod: selectedMethod
    });
  
    // Check each field individually and log the specific failure
    if (!formData.Email) {
      console.log('Validation failed: Missing email');
      setError('Email is required');
      return false;
    }
    
    if (!formData.Phone) {
      console.log('Validation failed: Missing phone');
      setError('Phone number is required');
      return false;
    }
    
    if (!formData.StudentName) {
      console.log('Validation failed: Missing student name');
      setError('Student name is required');
      return false;
    }
    
    if (!formData.StudentID) {
      console.log('Validation failed: Missing student ID');
      setError('Student ID is required');
      return false;
    }
    
    if (!formData.hours) {
      console.log('Validation failed: Missing hours');
      setError('Hours must be specified');
      return false;
    }
    
    if (formData.hours < 1) {
      console.log('Validation failed: Invalid hours', formData.hours);
      setError('Number of hours must be at least 1');
      return false;
    }
    
    if (!selectedMethod) {
      console.log('Validation failed: No payment method selected');
      setError('Please select a payment method');
      return false;
    }
  
    console.log('Form validation passed');
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFormData()) return;
    
    if (selectedMethod.value === 'blik' && !validateBlikCode()) {
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const paymentMethodMapping = {
        'ms': 'm',
        'os': 'o',
        'pks': 'p',
        'c': 'c',
        'blik': 'blik',
        'ap': 'ap',
        'jp': 'jp',
        'dpkl': 'dpkl',
        'dpp': 'dpp'
      };

      const mappedPaymentMethod = paymentMethodMapping[selectedMethod.value] || selectedMethod.value;

      const paymentData = {
        totalAmount: Math.round(formData.totalAmount * 100),
        Email: formData.Email,
        Phone: formData.Phone,
        StudentName: formData.StudentName,
        StudentID: formData.StudentID,
        hours: parseInt(formData.hours),
        paymentMethod: mappedPaymentMethod,
        Description: formData.Description,
        Package_ID: formData.Package_ID,
        billingAddress: formData.billingAddress,
        companyDetails: formData.companyDetails,
        installmentDetails: formData.installmentDetails,
        ...(selectedMethod.value === 'blik' && { blikCode })
      };

      const response = await axios.post('Make-Payment', paymentData);

      if (response.data.redirectUri || response.data.orderId) {
        // Store payment details in Redux before redirecting
        const paymentDetailsForStore = {
          Student_ID: formData.StudentID,
          Package_ID: formData.Package_ID,
          Purchase_Amount: formData.totalAmount,
          Sessions: formData.hours,
          Status: 'Pending',
          OrderID: response.data.orderId,
          installment: formData.installmentDetails?.numberOfInstallments,
          invoice: {
            orderId: response.data.orderId,
            packageName: formData.Description,
            studentName: formData.StudentName,
            studentEmail: formData.Email
          },
          orderFulfilled: false
        };

        // Store payment details in localStorage
        localStorage.setItem('pendingPaymentData', JSON.stringify(paymentDetailsForStore));
        
        // Store in Redux as well
        await dispatch(storePaymentDetails(paymentDetailsForStore));

        if (selectedMethod.value === 'blik') {
          // For BLIK, open status check in the same window
          const width = 800;
          const height = 600;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;

          const statusWindow = window.open(
            `/Your-Bookings-has-been-confirmed?orderId=${response.data.orderId}`,
            'PayU Payment Status',
            `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
          );

          if (statusWindow) {
            setPaymentWindow(statusWindow);
          } else {
            setError('Please enable popups to check payment status');
          }
        } else {
          // For other methods, open PayU redirect
          openPaymentPopup(response.data.redirectUri);
        }
      } else {
        throw new Error('Invalid response from payment server');
      }
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.response?.data?.message || 'Payment processing failed');
    } finally {
      if (!error) {
        setLoading(false);
        if (selectedMethod.value === 'blik') {
          setShowBlikPopup(false);
          setBlikCode('');
        }
      }
    }
  };

  const formatAmount = useMemo(() => (amount) => {
    return (amount / 100).toFixed(2);
  }, []);

  const CardPopup = () => {
    if (!showCardPopup) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div 
          className="absolute inset-0 bg-black/50 backdrop-blur-sm"
          onClick={() => setShowCardPopup(false)}
        />
        <div className="relative z-10">
          <button
            onClick={() => setShowCardPopup(false)}
            className="absolute -top-12 right-0 text-white hover:text-gray-200 transition-colors"
            aria-label={t("PaymentPage.Close")}
          >
            <X className="w-6 h-6" />
          </button>
          <Premium3DCard />
        </div>
      </div>
    );
  };

  const BlikPopup = () => {
    if (!showBlikPopup) return null;
    


    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div 
          className="absolute inset-0 bg-black/50 backdrop-blur-sm"
          onClick={() => setShowBlikPopup(false)}
        />
        <div className="relative bg-white rounded-lg p-6 w-96 z-10">
          <button
            onClick={() => setShowBlikPopup(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
            aria-label={t("PaymentPage.Close")}
          >
            <X className="w-6 h-6" />
          </button>
          
          <h3 className="text-xl font-semibold mb-4">{t("PaymentPage.EnterBlikCode")}</h3>
          
          <div className="space-y-4">
            <div>
              <label htmlFor="blikCode" className="block text-sm font-medium text-gray-700 mb-1">
                {t("PaymentPage.BlikCodeLabel")}
              </label>
              <input
  ref={blikInputRef}
  type="number"
  inputMode="numeric"
  id="blikCode"
  value={blikCode}
  onChange={handleBlikCodeChange}
  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
  placeholder={t("PaymentPage.Enter6DigitCode")}
  autoComplete="off"
  autoFocus
/>
              {blikError && (
                <p className="mt-1 text-sm text-red-600">{blikError}</p>
              )}
            </div>
            
            <button
              onClick={handleSubmit}
              disabled={blikCode.length !== 6 || loading}
              className={`w-full py-3 px-4 rounded-md font-medium transition-colors ${
                blikCode.length !== 6 || loading
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 text-white'
              }`}
            >
              {loading ? t("PaymentPage.Processing") : t("PaymentPage.ConfirmPayment")}
            </button>
          </div>
          
          <p className="mt-4 text-xs text-gray-500">
            {t("PaymentPage.BlikInstructions")}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-4 min-h-screen bg-gray-50">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Package Summary */}
        {pendingPayment && (
          <div className="mb-6 p-4 bg-gray-50 rounded-lg">
            <h2 className="text-lg font-semibold mb-2">{t("PaymentPage.PackageSummary")}</h2>
            <div className="space-y-2">
              <p><span className="font-medium">{t("PaymentPage.Package")}:</span> {pendingPayment.packageName}</p>
              <p><span className="font-medium">{t("PaymentPage.Hours")}:</span> {pendingPayment.hours}</p>
              <p><span className="font-medium">{t("PaymentPage.Amount")}:</span> {pendingPayment.totalAmount}</p>
              {pendingPayment.installmentDetails && (
                <div className="mt-2 pt-2 border-t border-gray-200">
                  <p>
                    <span className="font-medium">{t("PaymentPage.InstallmentPlan")}:</span> 
                    {t("PaymentPage.MonthsCount", { count: pendingPayment.installmentDetails.numberOfInstallments })}
                  </p>
                  <p>
                    <span className="font-medium">{t("PaymentPage.MonthlyPayment")}:</span> 
                    {pendingPayment.installmentDetails.monthlyPayment} PLN
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Payment Methods Section */}
        <div className="border-t border-b py-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">{t("PaymentPage.SelectPaymentMethodTitle")}</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {paymentMethods.map((method) => (
              <button
                key={method.value}
                className={`relative p-4 border rounded-lg transition-all duration-300 flex flex-col items-center justify-center h-32 gap-2 ${
                  method.status === 'DISABLED' 
                    ? 'opacity-50 cursor-not-allowed bg-gray-50' 
                    : 'hover:border-gray-400 hover:shadow-lg'
                } ${
                  selectedMethod?.value === method.value 
                    ? 'border-blue-500 ring-2 ring-blue-200' 
                    : ''
                }`}
                onClick={() => method.status === 'ENABLED' && handleMethodSelect(method)}
                disabled={method.status === 'DISABLED'}
              >
                <div className="relative w-full h-12 flex items-center justify-center">
                  <img
                    src={method.brandImageUrl}
                    alt={method.name}
                    className="max-h-full max-w-full object-contain"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/api/placeholder/120/40';
                    }}
                  />
                </div>
                <span className="text-xs text-center text-gray-600 font-medium">
                  {method.name}
                </span>
                {method.minAmount > 0 && (
                  <span className="text-xs text-gray-400">
                    {t("PaymentPage.MinAmount")}: {formatAmount(method.minAmount)} PLN
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600">{error}</p>
          </div>
        )}

        {/* Payment Button */}
        <button
          className={`w-full py-4 px-8 rounded-lg font-medium transition-colors ${
            !selectedMethod || loading
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
          disabled={!selectedMethod || loading}
          onClick={handleSubmit}
        >
          {loading ? t("PaymentPage.Processing") : t("PaymentPage.ProceedToPayment")}
        </button>

        {/* Footer Info */}
        <div className="mt-6 text-xs text-gray-500 space-y-2">
          <p>{t("PaymentPage.PaymentSecurityNote")}</p>
          <p>{t("PaymentPage.PopupEnableNote")}</p>
        </div>
      </div>

      <CardPopup />
      <BlikPopup />

      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-2 text-sm text-gray-600">{t("PaymentPage.ProcessingPayment")}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;