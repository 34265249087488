import { createSlice } from "@reduxjs/toolkit";
import { 
    Add_booking_Trial_Form, 
  Get_Trial_Booking,
  Cancel_Trial_Booking,
  Get_Student_Trial_Bookings,
  Update_Trial_Booking_Status,
  Get_Trial_Bookings_By_Package
} from "../actions/trialPreferencesActions";

const initialState = {
  loading: false,
  error: null,
  currentBooking: null,
  studentBookings: [],
  packageBookings: [],
  success: false,
  count: 0 
};

const trialBookingSlice = createSlice({
  name: "trialBooking",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // Add Trial Booking
      .addCase(Add_booking_Trial_Form.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Add_booking_Trial_Form.fulfilled, (state, action) => {
        state.loading = false;
        state.currentBooking = action.payload.data;
        state.success = true;
      })
      .addCase(Add_booking_Trial_Form.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get Trial Booking
      .addCase(Get_Trial_Booking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Get_Trial_Booking.fulfilled, (state, action) => {
        state.loading = false;
        state.currentBooking = action.payload.data;
      })
      .addCase(Get_Trial_Booking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Cancel Trial Booking
      .addCase(Cancel_Trial_Booking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Cancel_Trial_Booking.fulfilled, (state, action) => {
        state.loading = false;
        state.currentBooking = action.payload.data;
        state.success = true;
      })
      .addCase(Cancel_Trial_Booking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get Student Trial Bookings
      .addCase(Get_Student_Trial_Bookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Get_Student_Trial_Bookings.fulfilled, (state, action) => {
        state.loading = false;
        state.studentBookings = action.payload.data;
      })
      .addCase(Get_Student_Trial_Bookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Trial Booking Status
      .addCase(Update_Trial_Booking_Status.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Update_Trial_Booking_Status.fulfilled, (state, action) => {
        state.loading = false;
        state.currentBooking = action.payload.data;
        state.success = true;
      })
      .addCase(Update_Trial_Booking_Status.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(Get_Trial_Bookings_By_Package.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Get_Trial_Bookings_By_Package.fulfilled, (state, action) => {
        state.loading = false;
        state.packageBookings = action.payload.data;
        state.count = action.payload.count;
      })
      .addCase(Get_Trial_Bookings_By_Package.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearErrors, resetSuccess } = trialBookingSlice.actions;
export default trialBookingSlice.reducer;