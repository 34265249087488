import React, { useState, useEffect } from 'react';
import './GuidePopup.css';

const GuidePopup = ({ isOpen, onClose, onTimezoneChange, currentTimezone }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [hasSelectedTimezone, setHasSelectedTimezone] = useState(false);

  useEffect(() => {
    if (currentStep === 1) {
      const timezoneSelect = document.querySelector('.timezone-selector');
      if (timezoneSelect) {
        timezoneSelect.classList.add('highlight-element');
      }
    }

    return () => {
      const timezoneSelect = document.querySelector('.timezone-selector');
      if (timezoneSelect) {
        timezoneSelect.classList.remove('highlight-element');
      }
    };
  }, [currentStep]);

  // Watch for timezone changes
  useEffect(() => {
    if (currentStep === 1 && currentTimezone && currentTimezone !== 'Select Timezone') {
      setHasSelectedTimezone(true);
    }
  }, [currentTimezone, currentStep]);

  const steps = [
    {
      title: "Update Your Timezone",
      description: "Your current timezone might need to be updated for accurate meeting times.",
      icon: "⏰"
    },
    {
      title: "Select Your Timezone",
      description: (
        <div className="guidance-content">
          <p>Please select your timezone from the dropdown in the sidebar:</p>
          <div className="dropdown-indicator">
            <div className="arrow-pointer">
              <span>👈</span>
              <div className="arrow-line"></div>
            </div>
            <div className="timezone-status">
              {hasSelectedTimezone ? (
                <div className="success-message">
                  <span className="check-icon">✅</span>
                  <span>Timezone selected! Click Next to continue.</span>
                </div>
              ) : (
                <div className="pending-message">
                  <span className="pending-icon">⏳</span>
                  <span>Please select your timezone to continue</span>
                </div>
              )}
            </div>
          </div>
        </div>
      ),
      icon: "🌍"
    },
    {
      title: "All Set!",
      description: "Perfect! Your meetings will now be displayed in your local time.",
      icon: "✅"
    }
  ];

  const handleNext = () => {
    if (currentStep === 1 && !hasSelectedTimezone) {
      // Don't proceed if timezone hasn't been selected
      return;
    }
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handlePrev = () => setCurrentStep(prev => Math.max(prev - 1, 0));

  if (!isOpen) return null;

  return (
    <div className="guide-overlay">
      <div className="guide-modal">
        <button className="close-button" onClick={onClose}>×</button>
        
        <div className="progress-bar">
          {steps.map((_, index) => (
            <div 
              key={index}
              className={`progress-dot ${index === currentStep ? 'active' : ''} 
                         ${index < currentStep ? 'completed' : ''}`}
            />
          ))}
        </div>

        <div className="step-content">
          <div className="step-icon">{steps[currentStep].icon}</div>
          <h2 className="step-title">{steps[currentStep].title}</h2>
          <div className="step-description">{steps[currentStep].description}</div>
        </div>

        <div className="button-container">
          <button 
            className="nav-button"
            onClick={handlePrev}
            disabled={currentStep === 0}
          >
            Previous
          </button>
          <button 
            className={`nav-button primary ${currentStep === 1 && !hasSelectedTimezone ? 'disabled' : ''}`}
            onClick={currentStep === steps.length - 1 ? onClose : handleNext}
            disabled={currentStep === 1 && !hasSelectedTimezone}
          >
            {currentStep === steps.length - 1 ? 'Got it!' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuidePopup;