import React from 'react';
import { useTranslation } from "react-i18next";


const BookingFilters = ({ selectedFilters, onFilterChange }) => {
  const [t] = useTranslation("global");

  const filterOptions = [
    { id: 'upcoming', label: t('status.Upcoming'), color: 'bg-blue-100 text-blue-800' },
    { id: 'past', label: t('status.Past'), color: 'bg-gray-100 text-gray-800' },
    { id: 'completed', label: t('status.Completed'), color: 'bg-green-100 text-green-800' },
    { id: 'inProgress', label: t('status.inProgress'), color: 'bg-orange-100 text-orange-800' },
    { id: 'scheduled', label: t('status.Scheduled'), color: 'bg-blue-100 text-blue-800' },
    { id: 'cancelled', label: t('status.Cancelled'), color: 'bg-red-100 text-red-800' }
  ];

  const toggleFilter = (filterId) => {
    if (selectedFilters.includes(filterId)) {
      onFilterChange(selectedFilters.filter(id => id !== filterId));
    } else {
      onFilterChange([...selectedFilters, filterId]);
    }
  };

  return (
    <div className="mb-6">
      <h2 className="text-sm font-medium text-gray-700 mb-3"></h2>
      <div className="flex flex-wrap gap-2">
        {filterOptions.map((filter) => (
          <button
            key={filter.id}
            onClick={() => toggleFilter(filter.id)}
            className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium
                      transition-all duration-200 ${filter.color} 
                      ${selectedFilters.includes(filter.id) 
                        ? 'ring-2 ring-offset-2 ring-blue-500' 
                        : 'opacity-70 hover:opacity-100'}`}
          >
            {filter.label}
            {selectedFilters.includes(filter.id) && (
              <span className="ml-2 text-sm" aria-hidden="true">&times;</span>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BookingFilters;