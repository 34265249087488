import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

// ACTIONS : api calls
export const fetchAllpayments = createAsyncThunk(
  "payments/getpayments",
  async () => {
    const response = await axios.get(`getpayments`);
    return response.data;
  }
);

export const Add_payment = createAsyncThunk(
  "payments/Add_payment",
  async () => {
    const response = await axios.post(`/Add_Payment`);
    return response.data;
  }
);

export const Deletepayment = createAsyncThunk(
  "payments/Deletepayment",
  async (PaymentID) => {
    try {
      const response = await axios.get(`Delete_Payment/${PaymentID}`);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const Updatepayment = createAsyncThunk(
  "payments/Updatepayment",
  async ({ PaymentID, updatedData }) => {
    try {
      const response = await axios.post(
        `Update_Payment/${PaymentID}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
);

export const GetPaymentsByStudentID = createAsyncThunk(
  "payments/GetPaymentsByStudentID",
  async (id) => {
    try {
      const response = await axios.get(`GetPaymentsByStudentID/${id}`);
      return response.data.Payments;
    } catch (error) {
      console.error("Error fetching payments:", error);
      throw error;
    }
  }
);

export const Create_Payment = createAsyncThunk(
  "packages/Create_Payment",
  async (formData) => {
    try {
      const response = await axios.post(`Make-Payment`, formData);
      document.cookie = `orderId=${response.data.orderId}; path=/`;
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "packages/getOrderDetails",
  async (orderId) => {
    try {
      const response = await axios.get(`authorizePayment/${orderId}`);
      return response.data;
    } catch (error) {
      console.log(error.response?.data?.message || error.message);
      throw error;
    }
  }
);

export const SearchPaymentbyStudentUsername = createAsyncThunk(
  "packages/SearchPaymentbyStudentUsername",
  async (input) => {
    try {
      const response = await axios.post(`SearchPaymentbyStudentUsername/${input}`);
      return response.data;
    } catch (error) {
      console.log(error.response?.data?.message || error.message);
      throw error;
    }
  }
);

export const storePaymentDetails = createAsyncThunk(
  "payments/storePaymentDetails",
  async (paymentData) => {
    try {
      const response = await axios.post(`payment-details`, paymentData);
      return response.data;
    } catch (error) {
      console.error("Error storing payment details:", error);
      throw error;
    }
  }
);

export const fetchPaymentDetails = createAsyncThunk(
  "payments/fetchPaymentDetails",
  async () => {
    try {
      const response = await axios.get(`payment-details`);
      return response.data;
    } catch (error) {
      console.error("Error fetching payment details:", error);
      throw error;
    }
  }
);

export const getPayuAuthToken = createAsyncThunk(
  "payments/getPayuAuthToken",
  async () => {
    try {
      const response = await axios.get(`payu/token`);
      return {
        success: true,
        token: response.data.token.access_token, // Fix the path
        tokenType: response.data.token.token_type,
        expiresIn: response.data.token.expires_in
      };
    } catch (error) {
      console.error("Error getting PayU auth token:", error);
      throw error;
    }
  }
);

export const getPayuPaymentMethods = createAsyncThunk(
  "payments/getPayuPaymentMethods",
  async () => {
    try {
      const response = await axios.get(`payu/payment-methods`);
      // Return the response exactly as received
      return response.data;
    } catch (error) {
      console.error("Error getting PayU payment methods:", error);
      throw error;
    }
  }
);
