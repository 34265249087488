import { createSlice } from "@reduxjs/toolkit";
import {
  CreatePackage,
  Delete_Package,
  GetPackageByTeacherID,
  SearchPackagebyPackageName,
  Search_Free_Package,
  fetchAllpackages,
  fetchPackage,
  updatePackage,
  fetchStudentPackages,
  deductFreeHoursFromPackage,
  addFreeHour,
  getStudentFreeHourUsage,
} from "../actions/packagesActions";
import { toast } from "react-toastify";

let initialState = {
  packageslist: {
    packages: []
  },
  currentPackage: null,
  Teacher_Packages: [],
  FreePackage: null,
  studentPackages: [],
  studentFreeHourUsage: [],
  loading: false,
  error: null
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Delete_Package.pending, (state) => {
        state.loading = true;
      })
      .addCase(Delete_Package.fulfilled, (state, action) => {
        state.packageslist.packages = state.packageslist.packages.filter(
          (pkg) => pkg._id !== action.payload.DeletedPackage._id
        );
        state.loading = false;
        toast.success("Package deleted successfully");
      })
      .addCase(Delete_Package.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to delete package");
      })
      .addCase(fetchAllpackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllpackages.fulfilled, (state, action) => {
        state.packageslist = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllpackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(CreatePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreatePackage.fulfilled, (state, action) => {
        state.packageslist.packages.push(action.payload.newPackage);
        state.loading = false;
        toast.success("Package created successfully");
      })
      .addCase(CreatePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to create package");
      })
      .addCase(updatePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        const index = state.packageslist.packages.findIndex(
          pkg => pkg._id === action.payload.updatedPackage._id
        );
        if (index !== -1) {
          state.packageslist.packages[index] = action.payload.updatedPackage;
        }
        state.loading = false;
        toast.success("Package updated successfully");
      })
      .addCase(updatePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to update package");
      })
      .addCase(fetchPackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPackage.fulfilled, (state, action) => {
        state.currentPackage = action.payload;
        state.loading = false;
      })
      .addCase(fetchPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GetPackageByTeacherID.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetPackageByTeacherID.fulfilled, (state, action) => {
        state.Teacher_Packages = action.payload;
        state.loading = false;
      })
      .addCase(GetPackageByTeacherID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(Search_Free_Package.pending, (state) => {
        state.loading = true;
      })
      .addCase(Search_Free_Package.fulfilled, (state, action) => {
        state.FreePackage = action.payload.FreePackage;
        state.loading = false;
      })
      .addCase(Search_Free_Package.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to fetch free package");
      })
      .addCase(SearchPackagebyPackageName.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchPackagebyPackageName.fulfilled, (state, action) => {
        state.packageslist = action.payload.foundedPackage || [];
        state.loading = false;
      })
      .addCase(SearchPackagebyPackageName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to search package");
      })
      .addCase(fetchStudentPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStudentPackages.fulfilled, (state, action) => {
        state.studentPackages = action.payload;
        state.loading = false;
      })
      .addCase(fetchStudentPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to fetch student packages");
      })
      .addCase(deductFreeHoursFromPackage.fulfilled, (state, action) => {
        const updatedPackage = action.payload;
        const index = state.packageslist.findIndex(pkg => pkg._id === updatedPackage._id);
        if (index !== -1) {
          state.packageslist[index] = updatedPackage;
        }
        if (state.currentPackage && state.currentPackage._id === updatedPackage._id) {
          state.currentPackage = updatedPackage;
        }
      })
      .addCase(addFreeHour.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFreeHour.fulfilled, (state, action) => {
        state.loading = false;
        // Update the relevant package in the state
        const updatedPackage = action.payload.freeHours;
        const index = state.packageslist.findIndex(pkg => pkg._id === updatedPackage.packageId);
        if (index !== -1) {
          state.packageslist[index] = {
            ...state.packageslist[index],
            freeHours: updatedPackage
          };
        }
        // Update current package if it's the one being modified
        if (state.currentPackage && state.currentPackage._id === updatedPackage.packageId) {
          state.currentPackage = {
            ...state.currentPackage,
            freeHours: updatedPackage
          };
        }
        // Update student packages if necessary
        const studentPackageIndex = state.studentPackages.findIndex(pkg => pkg._id === updatedPackage.packageId);
        if (studentPackageIndex !== -1) {
          state.studentPackages[studentPackageIndex] = {
            ...state.studentPackages[studentPackageIndex],
            freeHours: updatedPackage
          };
        }
        toast.success(action.payload.Free_hour_used ? "Free hour used successfully" : "Free hour returned successfully");
      })
      .addCase(addFreeHour.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to use/return free hour");
      })
      .addCase(getStudentFreeHourUsage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStudentFreeHourUsage.fulfilled, (state, action) => {
        state.loading = false;
        state.studentFreeHourUsage = action.payload.data;
      })
      .addCase(getStudentFreeHourUsage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Failed to fetch student's free hour usage");
      });
  },
});

export default packageSlice.reducer;