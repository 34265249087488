import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { motion } from 'framer-motion';



const EnhancedProfileTour = ({ steps, isRunning, onComplete, styles }) => {
  const [tourState, setTourState] = useState({
    run: isRunning,
    steps: steps.map(step => ({
      ...step,
      content: (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-gray-600">{step.content}</p>
          {step.emoji && (
            <span className="text-4xl mt-2 block animate-bounce">{step.emoji}</span>
          )}
        </motion.div>
      ),
    })),
  });

  useEffect(() => {
    setTourState(prev => ({ ...prev, run: isRunning }));
  }, [isRunning]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      onComplete?.();
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      showProgress
      showSkipButton
      run={tourState.run}
      steps={tourState.steps}
      styles={styles || {
        options: {
          arrowColor: '#ffffff',
          backgroundColor: '#ffffff',
          primaryColor: '#22C55E', // Primary green
          textColor: '#1F2937', // Neutral text color
          zIndex: 1000,
          spotlightPadding: 16,
        },
        tooltip: {
          padding: '24px',
          borderRadius: '16px',
          boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        },
        tooltipContainer: {
          textAlign: 'center',
          minWidth: '300px',
        },
        tooltipContent: {
          fontSize: '1.1rem',
          lineHeight: '1.6',
          color: '#4B5563', // Neutral text color
        },
        buttonNext: {
          backgroundColor: '#22C55E', // Green button
          padding: '12px 24px',
          fontSize: '1rem',
          fontWeight: '600',
          borderRadius: '12px',
          border: 'none',
          color: '#ffffff',
          transition: 'all 0.3s',
        },
        buttonBack: {
          marginRight: '12px',
          padding: '12px 24px',
          fontSize: '1rem',
          fontWeight: '600',
          borderRadius: '12px',
          border: '2px solid #22C55E', // Green border
          backgroundColor: '#ffffff',
          color: '#22C55E',
          transition: 'all 0.3s',
        },
        buttonSkip: {
          color: '#6B7280',
          fontSize: '1rem',
          fontWeight: '600',
          transition: 'all 0.3s',
        },
        spotlight: {
          borderRadius: '16px',
        },
      }}
    />
  );
};

export default EnhancedProfileTour;
