import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import { DownloadOutlined, MessageOutlined } from "@ant-design/icons";
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend } from 'recharts';
import { Tooltip, Modal, Input, Select, Button, Tabs, Card, List, Avatar, Badge, Tag, Spin, Progress, message } from 'antd';
import { FaRegQuestionCircle, FaMoneyBillWave, FaBookOpen, FaCheckCircle, FaClock, FaBan, FaSearch, FaCalendarAlt, FaChartBar, FaListUl, FaUser } from 'react-icons/fa';
import moment from 'moment-timezone';
import axios from 'axios';
import Cookies from 'js-cookie';
import { GetBookingsByTeacherID } from '../../store/actions/bookingActions';
import { Get_Trial_Bookings_By_Package } from '../../store/actions/trialPreferencesActions';
import { GetPackageByTeacherID } from '../../store/actions/packagesActions';
import { teacherJoinSession, getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import YourMaterialModal from './YourMaterialModal';
import ChatPopup from '../../components/admin-dashboard-components/ChatPopup';

const { Option } = Select;
const { TabPane } = Tabs;

const TeacherDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const teacher = useSelector((state) => state.students.user);
  const packages = useSelector((state) => state.packages.Teacher_Packages);
  const bookings = useSelector((state) => state.bookings.Teacher_Bookings);
  const [selectedTimezone, setSelectedTimezone] = useState(localStorage.getItem('selectedTimezone') || 'America/New_York');
  const [isYourMaterialModalVisible, setIsYourMaterialModalVisible] = useState(false);
  const [date, setDate] = useState(moment().tz(selectedTimezone).toDate());
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [teacherJoinedStatus, setTeacherJoinedStatus] = useState('Not joined');
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isDateFiltered, setIsDateFiltered] = useState(false);
  const [stats, setStats] = useState({
    income: 0,
    totalLectures: 0,
    completedSessions: 0,
    pendingOrScheduledSessions: 0,
    cancelledSessions: 0,
    rescheduledSessions: 0,
    trialSessions: 0  // Add this line
  });
  const [filterStatus, setFilterStatus] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [monthlyBookingsData, setMonthlyBookingsData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [showTimezonePrompt, setShowTimezonePrompt] = useState(false);
  const [initialTimezone, setInitialTimezone] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [datesWithBookings, setDatesWithBookings] = useState([]);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const students = useSelector((state) => state.students.studentslist);
  const trailBookings = useSelector((state) => state.bookings.Trail_Bookings);
  const [isTrialPreferencesModalVisible, setIsTrialPreferencesModalVisible] = useState(false);
const [trialPreferencesData, setTrialPreferencesData] = useState(null);
const [trialPreferencesMap, setTrialPreferencesMap] = useState({});


const handleViewTrialPreferences = async (bookingId) => {
  try {
    const result = await dispatch(Get_Trial_Bookings_By_Package(bookingId));
    if (result.payload?.data?.[0]) {
      setTrialPreferencesData(result.payload.data[0]);
      setIsTrialPreferencesModalVisible(true);
    } else {
      message.info('No trial preferences found for this booking');
    }
  } catch (error) {
    message.error('Failed to fetch trial preferences');
    console.error('Error fetching trial preferences:', error);
  }
};

const fetchTrialPreferences = useCallback(async (bookingId) => {
  try {
    const result = await dispatch(Get_Trial_Bookings_By_Package(bookingId));
    if (result.payload?.data?.[0]) {
      setTrialPreferencesMap(prev => ({
        ...prev,
        [bookingId]: result.payload.data[0]
      }));
      return result.payload.data[0];
    }
  } catch (error) {
    console.error('Error fetching trial preferences:', error);
  }
  return null;
}, [dispatch]);

  const getBookingStatus = useCallback((date, start, end, originalStatus) => {
    if (!date || !start || !end) return originalStatus;
    
    const now = moment().utc();
    const bookingStart = moment.utc(`${date} ${start}`);
    const bookingEnd = moment.utc(`${date} ${end}`);
  
    if (originalStatus === "Rescheduled") {
      if (now.isAfter(bookingEnd)) return "Completed";
      if (now.isSameOrAfter(bookingStart) && now.isBefore(bookingEnd)) return "In Progress";
      return "Rescheduled";
    }
    if (originalStatus === "Cancelled") return "Cancelled";
    if (now.isAfter(bookingEnd)) return "Completed";
    if (now.isSameOrAfter(bookingStart) && now.isBefore(bookingEnd)) return "In Progress";
    return "Scheduled";
  }, []);

  const getCurrentAndUpcomingBookings = useCallback((bookings, trailBookings) => {
    const now = moment().tz(selectedTimezone);
    const todayStart = now.clone().startOf('day');
    
    // Process regular bookings
    const regularBookings = bookings?.filter(booking => {
      return booking?.Scheduled_Dates?.some(dateObj => {
        const date = Object.keys(dateObj)[0];
        const slots = dateObj[date];
        
        return slots?.some(slot => {
          const endDateTime = moment.tz(
            `${date} ${slot.end}`, 
            'YYYY-MM-DD HH:mm', 
            'UTC'
          ).tz(selectedTimezone);
          
          return endDateTime.isSameOrAfter(todayStart);
        });
      });
    }) || [];

    
  
    // Process trail bookings
    const processedTrialBookings = trailBookings?.filter(booking => {
      if (booking.Status !== "Trial") return false;
      
      return booking?.Scheduled_Dates?.some(dateObj => {
        const date = Object.keys(dateObj)[0];
        const slots = dateObj[date];
        
        return slots?.some(slot => {
          const endDateTime = moment.tz(
            `${date} ${slot.end}`, 
            'YYYY-MM-DD HH:mm', 
            'UTC'
          ).tz(selectedTimezone);
          
          return endDateTime.isSameOrAfter(todayStart);
        });
      });
    }) || [];
  
    return [...regularBookings, ...processedTrialBookings];
  }, [selectedTimezone]);

  // First useEffect to fetch initial bookings
useEffect(() => {
  dispatch(GetBookingsByTeacherID(teacher._id));
  fetchTimezone();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, [dispatch, teacher._id]); // Removed bookings dependency

// Separate useEffect to handle trial bookings when bookings length changes
useEffect(() => {
  const fetchTrialBookings = async () => {
    const currentLength = bookings?.length || 0;
    
    if (currentLength > 0) {
      for (const booking of bookings) {
        try {
          const result = await dispatch(Get_Trial_Bookings_By_Package(booking._id));
          console.log(`Trial bookings for booking ${booking._id}:`, result.payload);
        } catch (error) {
          console.error(`Error fetching trial bookings for booking ${booking._id}:`, error);
        }
      }
    }
  };

  fetchTrialBookings();
}, [bookings?.length, dispatch]); // Only depends on bookings length, not the entire bookings array

const mergeConsecutiveBookings = useCallback((bookings) => {
  if (!Array.isArray(bookings)) {
    console.error('Bookings is not an array:', bookings);
    return [];
  }
  
  const mergedBookings = [];
  let currentMergedBooking = null;

  bookings.forEach(booking => {
    if (!booking?.Scheduled_Dates || !Array.isArray(booking.Scheduled_Dates)) {
      console.warn('Invalid booking structure:', booking);
      return;
    }

    booking.Scheduled_Dates.forEach(dateObj => {
      if (!dateObj || typeof dateObj !== 'object') {
        console.warn('Invalid date object:', dateObj);
        return;
      }
      
      const date = Object.keys(dateObj)[0];
      if (!date || !dateObj[date]) {
        console.warn('Invalid date or time slots:', dateObj);
        return;
      }

      let timeSlots = Array.isArray(dateObj[date]) ? dateObj[date] : [dateObj[date]];
      timeSlots = timeSlots.filter(slot => slot?.start && slot?.end);

      timeSlots.sort((a, b) => {
        const startA = moment(a.start, 'HH:mm');
        const startB = moment(b.start, 'HH:mm');
        return startA.diff(startB);
      });

      timeSlots.forEach(slot => {
        const currentStatus = getBookingStatus(date, slot.start, slot.end, booking.Status);
        
        if (!currentMergedBooking) {
          currentMergedBooking = {
            ...booking,
            Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
            isConsecutive: false,
            status: currentStatus
          };
        } else {
          try {
            const lastDate = Object.keys(currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1])[0];
            const lastSlots = currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1][lastDate];
            
            if (!Array.isArray(lastSlots)) {
              throw new Error('Last slots is not an array');
            }

            const lastSlot = lastSlots[lastSlots.length - 1];

            if (date === lastDate && 
                moment(slot.start, 'HH:mm').isSame(moment(lastSlot.end, 'HH:mm'))) {
              lastSlot.end = slot.end;
              lastSlot.isConsecutive = true;
              currentMergedBooking.isConsecutive = true;
              currentMergedBooking.status = currentStatus;
            } else {
              mergedBookings.push(currentMergedBooking);
              currentMergedBooking = {
                ...booking,
                Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
                isConsecutive: false,
                status: currentStatus
              };
            }
          } catch (error) {
            console.error('Error processing time slots:', error);
            currentMergedBooking = {
              ...booking,
              Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
              isConsecutive: false,
              status: currentStatus
            };
          }
        }
      });
    });
  });

  if (currentMergedBooking) {
    mergedBookings.push(currentMergedBooking);
  }

  return mergedBookings;
}, [getBookingStatus]);

  // Update the chatUsers memo to filter students from bookings
  const chatUsers = useMemo(() => {
    // Get unique students from bookings
    const uniqueStudents = new Map();
    
    bookings?.forEach(booking => {
      const student = booking.Student_ID;
      if (student && student._id && !uniqueStudents.has(student._id)) {
        uniqueStudents.set(student._id, {
          id: student._id,
          name: student.Username,
          email: student.Email,
          type: 'student',
          avatar: student.Profile_Image?.[0] ? 
            `https://ik.imagekit.io/8s3jwexmv/${student.Profile_Image[0]}` : 
            null
        });
      }
    });
  
    return [
      ...Array.from(uniqueStudents.values()),
      {
        id: '667fd808df2795ef057c93b5',
        name: 'admin',
        email: 'admin@speakable.com',
        type: 'admin',
        avatar: null
      }
    ];
  }, [bookings]); // Only depend on bookings, remove students dependency

  useEffect(() => {
    setDate(moment(date).tz(selectedTimezone).toDate());
  }, [selectedTimezone]);

  useEffect(() => {
    dispatch(GetBookingsByTeacherID(teacher._id));
    fetchTimezone();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, teacher._id]);

  useEffect(() => {
    setDate(moment(date).tz(selectedTimezone).toDate());
  }, [selectedTimezone]);

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dateString = moment(date).tz(selectedTimezone).format('YYYY-MM-DD');
      return datesWithBookings.includes(dateString) ? (
        <div className="booked-date-indicator">
          L
        </div>
      ) : null;
    }
    return null;
  };

  const renderTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const momentTime = moment.utc().hour(hours).minute(minutes);
    return hourFormat === '12' 
      ? momentTime.tz(selectedTimezone).format('hh:mm A')
      : momentTime.tz(selectedTimezone).format('HH:mm');
  };

  useEffect(() => {
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      setSelectedTimezone(storedTimezone);
    }

    const storedHourFormat = localStorage.getItem('hourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  }, []);

  const filteredBookingsList = useMemo(() => {
    const currentAndUpcomingBookings = getCurrentAndUpcomingBookings(
      isDateFiltered ? filteredBookings : bookings,
      isDateFiltered ? filteredBookings : trailBookings
    );
    
    return mergeConsecutiveBookings(currentAndUpcomingBookings)?.filter(booking =>
      (booking?.Student_ID?.Username || '').toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterStatus === 'All' || 
       booking.Status === filterStatus || 
       (filterStatus === 'Trial' && booking.Status === 'Trial'))
    );
  }, [getCurrentAndUpcomingBookings, isDateFiltered, filteredBookings, bookings, trailBookings, mergeConsecutiveBookings, searchTerm, filterStatus]);

  useEffect(() => {
    if (bookings?.length > 0 || trailBookings?.length > 0) {
      const currentAndUpcomingBookings = getCurrentAndUpcomingBookings(bookings, trailBookings);
      const mergedBookings = mergeConsecutiveBookings(currentAndUpcomingBookings);
      
      const completedSessions = mergedBookings.filter(booking => booking.Status === 'Completed').length;
      const trialSessions = mergedBookings.filter(booking => 
        booking.Status === 'Trial' || booking.Status === 'Trail'
      ).length;
      const pendingOrScheduledSessions = mergedBookings.filter(booking => 
        ['Scheduled', 'Trial', 'Trail'].includes(booking.Status)
      ).length;
      const rescheduledSessions = mergedBookings.filter(booking => booking.Status === 'Rescheduled').length;
      const cancelledSessions = mergedBookings.filter(booking => booking.Status === 'Cancelled').length;
  
      setStats({
        income: completedSessions * teacher.Purchase_Price,
        totalLectures: mergedBookings.length,
        completedSessions,
        pendingOrScheduledSessions,
        cancelledSessions,
        rescheduledSessions,
        trialSessions
      });

      // Update chart data with current and upcoming bookings
      const currentMonth = moment().month();
      const currentYear = moment().year();
      const daysInMonth = moment().daysInMonth();
      
      const bookingsThisMonth = mergedBookings.filter(booking => 
        moment(booking.createdAt).month() === currentMonth &&
        moment(booking.createdAt).year() === currentYear
      );

      const dailyBookings = Array(daysInMonth).fill(0);
      bookingsThisMonth.forEach(booking => {
        const day = moment(booking.createdAt).date() - 1; 
        dailyBookings[day]++;
      });

      const chartData = dailyBookings.map((count, index) => ({
        day: index + 1,
        bookings: count
      }));

      setMonthlyBookingsData(chartData);

      setPieChartData([
        { name: 'Completed', value: completedSessions },
        { name: 'Pending', value: pendingOrScheduledSessions },
        { name: 'Rescheduled', value: rescheduledSessions },
        { name: 'Cancelled', value: cancelledSessions },
        { name: 'Trail', value: stats.trialSessions } // Add trail sessions to pie chart
      ]);

      const bookingDates = new Set();
      mergedBookings.forEach(booking => {
        booking.Scheduled_Dates?.forEach(dateObj => {
          const date = Object.keys(dateObj)[0];
          const startDateTime = moment.tz(`${date} ${dateObj[date][0].start}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
          const endDateTime = moment.tz(`${date} ${dateObj[date][0].end}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
          
          // Add both start and end dates to account for midnight crossings
          bookingDates.add(startDateTime.format('YYYY-MM-DD'));
          if (!startDateTime.isSame(endDateTime, 'day')) {
            bookingDates.add(endDateTime.format('YYYY-MM-DD'));
          }
        });
      });
      setDatesWithBookings(Array.from(bookingDates));
    }
  }, [bookings, trailBookings, selectedTimezone]);

  


  const formatStudentName = useCallback((student, booking) => {
    // First check if it's a trial booking
    if (booking.Status === 'Trial' || booking.Status === 'Trail') {
      // Check if we have trial preferences data for this booking
      const trialData = trialPreferencesMap[booking._id];
      if (trialData?.studentDetails?.name) {
        return trialData.studentDetails.name;
      }
      
      // Fallback to student name from booking if trial data is not available
      if (student?.Username) {
        const [firstName, ...lastNameParts] = student.Username.split(' ');
        const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
        return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
      }
      
      // Final fallback for trial bookings
      return 'Trial Student';
    }
    
    // Regular booking - format name as before
    if (!student?.Username) return 'Unknown Student';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
  }, [trialPreferencesMap]);

  

  

  const getStatusColor = useCallback((status) => {
    switch (status) {
      case 'Completed':
        return 'purple';
      case 'In Progress':
        return 'orange';
      case 'Scheduled':
        return 'green';
      case 'Cancelled':
        return 'red';
      case 'Rescheduled':
        return 'blue';
      case 'Trial':        // Changed from Trail to Trial
        return 'cyan';
      default:
        return 'default';
    }
  }, []);

  useEffect(() => {
    const fetchAllTrialPreferences = async () => {
      const trialBookings = bookings?.filter(
        booking => booking.Status === 'Trial' || booking.Status === 'Trail'
      ) || [];
      
      for (const booking of trialBookings) {
        try {
          const result = await dispatch(Get_Trial_Bookings_By_Package(booking._id));
          if (result.payload?.data?.[0]) {
            setTrialPreferencesMap(prev => ({
              ...prev,
              [booking._id]: result.payload.data[0]
            }));
          }
        } catch (error) {
          console.error('Error fetching trial preferences:', error);
        }
      }
    };

    fetchAllTrialPreferences();
  }, [bookings, dispatch]);

  const renderBookingDateTime = (booking) => {
    try {
      return booking.Scheduled_Dates?.map((dateObj, dateIndex) => {
        const originalDate = Object.keys(dateObj)[0];
        if (!originalDate || !dateObj[originalDate]) return null;

        return (
          <div key={`${dateIndex}-${originalDate}`}>
            {Array.isArray(dateObj[originalDate]) ? dateObj[originalDate].map((slot, slotIndex) => {
              if (!slot?.start || !slot?.end) return null;

              const startDateTime = moment.tz(
                `${originalDate} ${slot.start}`, 
                'YYYY-MM-DD HH:mm', 
                'UTC'
              ).tz(selectedTimezone);
              
              const endDateTime = moment.tz(
                `${originalDate} ${slot.end}`, 
                'YYYY-MM-DD HH:mm', 
                'UTC'
              ).tz(selectedTimezone);

              if (!startDateTime.isValid() || !endDateTime.isValid()) return null;

              return (
                <p key={`${dateIndex}-${originalDate}-${slotIndex}`}>
                  Date: {startDateTime.format('ddd MMM DD YYYY')}
                  <br />
                  Time: {startDateTime.format('HH:mm')} - {endDateTime.format('HH:mm')}
                  {slot.isConsecutive && (
                    <Tag color="blue" style={{ marginLeft: 8 }}>
                      {t('TeacherDash.ConsecutiveBooking')}
                    </Tag>
                  )}
                  {!startDateTime.isSame(endDateTime, 'day') && (
                    <Tag color="orange" style={{ marginLeft: 8 }}>
                      Overnight Session
                    </Tag>
                  )}
                </p>
              );
            }) : null}
          </div>
        );
      });
    } catch (error) {
      console.error('Error rendering booking date/time:', error);
      return null;
    }
  };

  const fetchTimezone = async () => {
    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = ipResponse.data.ip;
  
      const timezoneResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=132a6bee31081e`);
      const detectedTimezone = timezoneResponse.data.timezone;
      setInitialTimezone(detectedTimezone);
  
      if (detectedTimezone !== selectedTimezone) {
        setShowTimezonePrompt(true);
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  const handleTimezoneChange = (newTimezone) => {
    localStorage.setItem('selectedTimezone', newTimezone);
    setSelectedTimezone(newTimezone);
    setShowTimezonePrompt(false);
    Cookies.remove('keepCurrentTimezone');
  };

  const handleKeepCurrentTimezone = () => {
    setShowTimezonePrompt(false);
    Cookies.set('keepCurrentTimezone', 'true', { expires: 10/24 });
  };

  const handleCalendarClick = (value) => {
    const selectedDate = moment(value).tz(selectedTimezone).startOf('day');
    const endOfSelectedDate = selectedDate.clone().endOf('day');
  
    const filtered = bookings?.filter(booking =>
      booking?.Scheduled_Dates?.some(dateObj => {
        const bookingDate = Object.keys(dateObj)[0];
        const bookingStartTime = dateObj[bookingDate][0].start;
        const bookingEndTime = dateObj[bookingDate][0].end;
  
        const bookingStartDateTime = moment.tz(`${bookingDate} ${bookingStartTime}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
        const bookingEndDateTime = moment.tz(`${bookingDate} ${bookingEndTime}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
  
        return (
          (bookingStartDateTime.isSameOrAfter(selectedDate) && bookingStartDateTime.isSameOrBefore(endOfSelectedDate)) ||
          (bookingEndDateTime.isSameOrAfter(selectedDate) && bookingEndDateTime.isSameOrBefore(endOfSelectedDate)) ||
          (bookingStartDateTime.isBefore(selectedDate) && bookingEndDateTime.isAfter(endOfSelectedDate))
        );
      })
    );
  
    setFilteredBookings(filtered);
    setDate(selectedDate.toDate());
    setIsDateFiltered(true);
  };

  const resetDateFilter = () => {
    setFilteredBookings([]);
    setIsDateFiltered(false);
  };

  const isWithinScheduledTime = (scheduledDate, startTimeUTC, endTimeUTC) => {
    const now = moment().tz(selectedTimezone);
    const startDateTime = moment.tz(`${scheduledDate} ${startTimeUTC}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
    const endDateTime = moment.tz(`${scheduledDate} ${endTimeUTC}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
    
    if (endDateTime.isBefore(startDateTime)) {
      endDateTime.add(1, 'day');
    }
    
    const tenMinutesBefore = startDateTime.clone().subtract(10, 'minutes');
  
    return now.isSameOrAfter(tenMinutesBefore) && now.isSameOrBefore(endDateTime);
  };

  const statsData = [
    { name: t('TeacherDash.Income'), value: stats.income, icon: FaMoneyBillWave, color: 'text-success' },
    { name: t('TeacherDash.TotalLectures'), value: stats.totalLectures, icon: FaBookOpen, color: 'text-primary' },
    { name: t('TeacherDash.CompletedSessions'), value: stats.completedSessions, icon: FaCheckCircle, color: 'text-info' },
    { name: t('TeacherDash.PendingSessions'), value: stats.pendingOrScheduledSessions, icon: FaClock, color: 'text-warning' },
    { name: t('TeacherDash.CancelledSessions'), value: stats.cancelledSessions, icon: FaBan, color: 'text-danger' },
    { name: t('TeacherDash.Rescheduled'), value: stats.rescheduledSessions, icon: FaBan, color: 'text-secondary' },
    { 
      name: t('TeacherDash.Trial'), 
      value: stats.trialSessions, 
      icon: FaBookOpen, 
      color: 'text-primary' 
    },
  ];

  

  const renderStatusFilter = () => (
    <Select
  defaultValue="All"
  style={{ width: '100%', maxWidth: 120 }}
  onChange={(value) => setFilterStatus(value)}
>
  <Option value="All">{t('TeacherDash.AllStatus')}</Option>
  <Option value="Trial">{t('TeacherDash.Trial')}</Option>
  <Option value="Scheduled">{t('TeacherDash.Scheduled')}</Option>
  <Option value="Completed">{t('TeacherDash.Completed')}</Option>
  <Option value="Rescheduled">{t('TeacherDash.Rescheduled')}</Option>
  <Option value="Cancelled">{t('TeacherDash.Cancelled')}</Option>
</Select>
  );

  const handleMaterialsClick = (e, bookingId) => {
    e.stopPropagation();
    setSelectedBookingId(bookingId);
    setIsYourMaterialModalVisible(true);
  };

  const joinRoom = useCallback(async (bookingId) => {
    setJoiningRoom(true);
    try {
      const joinSessionResult = await dispatch(teacherJoinSession({ bookingId, teacherId: teacher._id }));
      
      if (joinSessionResult.error) {
        throw new Error(joinSessionResult.error.message);
      }
  
      message.success('Successfully joined the room.');
      setCurrentBookingId(bookingId);
      
      const roleInfo = encodeURIComponent(JSON.stringify({ role: 'teacher', teacherId: teacher._id }));
      window.open(`/room/meeting/${bookingId}?roleInfo=${roleInfo}`, '_blank', 'noopener,noreferrer');
      
      setTeacherJoinedStatus('Joined');
  
      const sessionStatusResult = await dispatch(getTeacherSessionStatus({ bookingId, teacherId: teacher._id }));
      console.log('Session status:', sessionStatusResult.payload);
  
    } catch (error) {
      console.error('Error joining the room:', error);
      message.error(`Failed to join the room: ${error.message}`);
    } finally {
      setJoiningRoom(false);
    }
  }, [dispatch, teacher._id]);

  const renderJoinButton = (booking) => {
    const scheduledDate = Object.keys(booking.Scheduled_Dates[0])[0];
    const startTime = booking.Scheduled_Dates[0][scheduledDate][0].start;
    const endTime = booking.Scheduled_Dates[0][scheduledDate][0].end;
    
    const isWithinTime = isWithinScheduledTime(scheduledDate, startTime, endTime);
    const isTrial = booking.Status === "Trial" || booking.Status === "Trail";
    
    return (
      <>
        <Button
          onClick={() => joinRoom(booking._id)}
          className={`join-room-btn ${isWithinTime ? "btn-success" : "btn-secondary"} ${isTrial ? "trial-btn" : ""}`}
          disabled={!isWithinTime}
        >
          {isTrial ? t("TeacherDash.JoinRoom") : t("TeacherDash.JoinRoom")}
        </Button>
        {booking.Status !== "Cancelled" && !isWithinTime && (
          <Tooltip title={t("TeacherDash.JoinRoomTooltip")}>
            <FaRegQuestionCircle className="ms-2 text-muted" />
          </Tooltip>
        )}
      </>
    );
  };

  

  const MobileBookingsChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={pieChartData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][index % 4]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );

  const DesktopBookingsChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={monthlyBookingsData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="day"
          tick={{ fontSize: 12 }}
          tickFormatter={(value) => value % 5 === 0 ? value : ''}
        />
        <YAxis tick={{ fontSize: 12 }} />
        <RechartsTooltip />
        <Bar dataKey="bookings" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );

  const MobileMeetingsList = () => (
    <List
      itemLayout="vertical"
      dataSource={filteredBookingsList}
      renderItem={(booking) => (
        <Card className="mb-3">
          <List.Item
            key={booking?._id}
            actions={[
              <div key="actions" className="d-flex flex-wrap gap-2 w-100">
                {renderJoinButton(booking)}
                <Button
                  type="default"
                  size="middle"
                  onClick={(e) => handleMaterialsClick(e, booking._id)}
                >
                  {t('TeacherDash.YourMaterial')}
                </Button>
                {(booking.Status === "Trial" || booking.Status === "Trail") && (
                  <Button
                    type="primary"
                    size="middle"
                    icon={<FaBookOpen />}
                    onClick={() => handleViewTrialPreferences(booking._id)}
                  >
                    View Preferences
                  </Button>
                )}
              </div>
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<FaUser />} />}
              title={formatStudentName(booking?.Student_ID, booking)}
              description={
                <>
                  <Badge
                    status={
                      booking.Status === 'Completed' ? 'success' :
                      booking.Status === 'In Progress' ? 'processing' :
                      booking.Status === 'Scheduled' ? 'warning' :
                      booking.Status === 'Cancelled' ? 'error' :
                      (booking.Status === 'Trial' || booking.Status === 'Trail') ? 'cyan' :
                      'default'
                    }
                    text={booking.Status}
                  />
                  {renderBookingDateTime(booking)}
                </>
              }
            />
          </List.Item>
        </Card>
      )}
    />
  );

  useEffect(() => {
    const statusUpdateInterval = setInterval(() => {
      const updatedBookings = mergeConsecutiveBookings(bookings);
      setFilteredBookings(isDateFiltered ? 
        updatedBookings.filter(booking => 
          booking?.Student_ID?.Username.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (filterStatus === 'All' || booking.status === filterStatus)
        ) : []);
    }, 60000); // Update every minute

    return () => clearInterval(statusUpdateInterval);
  }, [bookings, isDateFiltered, searchTerm, filterStatus, mergeConsecutiveBookings]);

  const TrialPreferencesModal = ({ visible, onClose, data }) => {
    if (!data) return null;
    
    const { studentDetails, learningPreferences, timeSlot, scheduledDate } = data;
    
    return (
      <Modal
        title="Trial Preferences"
        open={visible}
        onCancel={onClose}
        footer={[
          <Button key="close" onClick={onClose}>
            Close
          </Button>
        ]}
        width={700}
      >
        <div className="space-y-4">
          <Card className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Student Information</h3>
            <p><strong>Name:</strong> {studentDetails.name}</p>
            <p><strong>Email:</strong> {studentDetails.email}</p>
            <p><strong>Phone:</strong> {studentDetails.phone}</p>
            <p><strong>Age:</strong> {learningPreferences.sessionPreferences.age}</p>
          </Card>
  
          <Card className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Schedule</h3>
            <p><strong>Date:</strong> {moment(scheduledDate).format('MMMM DD, YYYY')}</p>
            <p><strong>Time:</strong> {timeSlot.start} - {timeSlot.end}</p>
          </Card>
  
          <Card className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Learning Objectives</h3>
            {learningPreferences.learningObjectives.map((objective, index) => (
              <Tag key={index} color="blue" className="m-1">
                {objective.skillType} ({objective.proficiencyLevel}) - {objective.priority} priority
              </Tag>
            ))}
          </Card>
  
          <Card className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Session Preferences</h3>
            <div className="mb-2">
              <strong>Focus Areas:</strong>
              <div>
                {learningPreferences.sessionPreferences.focusAreas.map((area, index) => (
                  <Tag key={index} color="green" className="m-1">
                    {area.replace(/_/g, ' ')}
                  </Tag>
                ))}
              </div>
            </div>
            
            <div className="mb-2">
              <strong>Preferred Topics:</strong>
              <div>
                {learningPreferences.sessionPreferences.preferredTopics.map((topic, index) => (
                  <Tag key={index} color="purple" className="m-1">
                    {topic.replace(/_/g, ' ')}
                  </Tag>
                ))}
              </div>
            </div>
  
            <div className="mb-2">
              <strong>Custom Topics:</strong>
              <div>
                {learningPreferences.sessionPreferences.customTopics.map((topic, index) => (
                  <Tag key={index} color="orange" className="m-1">
                    {topic}
                  </Tag>
                ))}
              </div>
            </div>
  
            <p><strong>Lesson Style:</strong> {learningPreferences.sessionPreferences.lessonStyle}</p>
          </Card>
        </div>
      </Modal>
    );
  };

  return (
    <div className="container-fluid p-4">
      <h1 className="mb-4">{t('TeacherDash.head')}</h1>

      {/* <ChatPopup 
  currentUser={{
    id: teacher._id,
    name: teacher.Username,
    type: 'teacher'
  }}
  availableUsers={chatUsers}
/> */}

{/* <Tooltip title="Chat Feature Coming Soon!" placement="left">
  <div style={{ 
    position: 'fixed', 
    right: '20px',  
    bottom: '20px', 
    zIndex: 1000 
  }}>
    <Button
      icon={<MessageOutlined style={{ fontSize: '20px' }}/>}
      shape="circle"
      size="large"
      disabled
      style={{
        opacity: 0.6,
        cursor: 'not-allowed'
      }}
    >
      <span style={{
        position: 'absolute',
        top: -2,
        right: -2,
        width: '10px',
        height: '10px',
        backgroundColor: '#1890ff',
        borderRadius: '50%',
        animation: 'pulse 2s infinite'
      }} />
    </Button>
  </div>
</Tooltip> */}
      
      <div className="row mb-4">
        {statsData.map((stat, index) => (
          <div key={index} className="col-6 col-md-4 col-lg-2 mb-3">
            <div className="card h-100">
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <stat.icon className={`fs-1 mb-2 ${stat.color}`} />
                <h5 className="card-title text-center">{stat.name}</h5>
                <p className="card-text text-center fs-4 fw-bold">{stat.value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="row mb-4">
        <div className="col-md-12 col-lg-8 mb-4 mb-lg-0">
          <div className="card h-100">
            <div className="card-header">
              <h2>{t('TeacherDash.MonthlyBookings')}</h2>
            </div>
            <div className="card-body">
              {isMobile ? <MobileBookingsChart /> : <DesktopBookingsChart />}
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="card h-100">
            <div className="card-header">
              <h2>{t('TeacherDash.Events')}</h2>
            </div>
            <div className="card-body d-flex justify-content-center align-items-center">
              <Calendar
                value={date}
                onChange={handleCalendarClick}
                className="w-100"
                tileContent={tileContent}
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="card mb-4">
        <div className="card-header">
          <h2>{t('TeacherDash.Meetings')}</h2>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mt-3">
            <Input
              placeholder={t('TeacherDash.SearchStudent')}
              prefix={<FaSearch />}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%', maxWidth: 200, marginBottom: isMobile ? 16 : 0 }}
            />
            <Select
  defaultValue="All"
  style={{ width: '100%', maxWidth: 120 }}
  onChange={(value) => setFilterStatus(value)}
>
  <Option value="All">{t('TeacherDash.AllStatus')}</Option>
  <Option value="Trial">{t('TeacherDash.Trial')}</Option>
  <Option value="Scheduled">{t('TeacherDash.Scheduled')}</Option>
  <Option value="Completed">{t('TeacherDash.Completed')}</Option>
  <Option value="Rescheduled">{t('TeacherDash.Rescheduled')}</Option>
  <Option value="Cancelled">{t('TeacherDash.Cancelled')}</Option>
</Select>
          </div>
          {isDateFiltered && (
            <div className="mt-3">
              <Button onClick={resetDateFilter}>
                {t('TeacherDash.ShowAllMeetings')}
              </Button>
            </div>
          )}
        </div>
        <div className="card-body">
          {filteredBookingsList.length > 0 ? (
            isMobile ? (
              <MobileMeetingsList />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>{t('TeacherDash.Meetingshead1')}</th>
                      <th>{t('TeacherDash.Meetingshead2')}</th>
                      <th>{t('TeacherDash.Meetingshead3')}</th>
                      <th>{t('TeacherDash.Meetingshead4')}</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  
{filteredBookingsList?.map((booking, index) => (
  <tr key={booking?._id}>
    <td>{formatStudentName(booking?.Student_ID, booking)}</td>
    <td>
      {booking.Scheduled_Dates?.map((dateObj, dateIndex) => {
        const originalDate = Object.keys(dateObj)[0];
        return (
          <div key={`${dateIndex}-${originalDate}`}>
            {dateObj[originalDate].map((slot, slotIndex) => {
              const startDateTime = moment.tz(`${originalDate} ${slot.start}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
              const endDateTime = moment.tz(`${originalDate} ${slot.end}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(selectedTimezone);
              return (
                <p key={`${dateIndex}-${originalDate}-${slotIndex}`}>
                  Date: {startDateTime.format('ddd MMM DD YYYY')}
                  <br />
                  Time: {startDateTime.format('HH:mm')} - {endDateTime.format('HH:mm')}
                  {slot.isConsecutive && (
                    <Tag color="blue" style={{ marginLeft: 8 }}>
                      {t('TeacherDash.ConsecutiveBooking')}
                    </Tag>
                  )}
                  {!startDateTime.isSame(endDateTime, 'day') && (
                    <Tag color="orange" style={{ marginLeft: 8 }}>
                      Overnight Session
                    </Tag>
                  )}
                </p>
              );
            })}
          </div>
        );
      })}
    </td>
    <td>{booking?.Status}</td>
    <td>
      {renderJoinButton(booking)}
    </td>
    <td>
      <div className="d-flex gap-2">
        <button 
          className="btn btn-warning" 
          onClick={(e) => handleMaterialsClick(e, booking._id)}
        >
          {t('TeacherDash.YourMaterial')}
        </button>
        {(booking.Status === "Trial" || booking.Status === "Trail") && (
          <button
            className="btn btn-primary d-flex align-items-center gap-2"
            onClick={() => handleViewTrialPreferences(booking._id)}
          >
            <FaBookOpen /> View Preferences
          </button>
        )}
      </div>
    </td>
  </tr>
))}
                  </tbody>
                </table>
              </div>
            )
          ) : (
            <p>{t('TeacherDash.NoMeetingsAvailable')}</p>
          )}
        </div>
      </div>

      {showTimezonePrompt && (
        <Modal
          title="Timezone Update"
          open={showTimezonePrompt}
          onOk={() => handleTimezoneChange(initialTimezone)}
          onCancel={handleKeepCurrentTimezone}
          footer={[
            <Button key="keep" onClick={handleKeepCurrentTimezone} style={{ color: '#107314', borderColor: '#107314' }}>
              No, Keep Current
            </Button>,
            <Button key="update" type="primary" onClick={() => handleTimezoneChange(initialTimezone)} style={{ backgroundColor: '#107314', borderColor: '#107314' }}>
              Yes, Update
            </Button>,
          ]}
        >
          <p>
            Your detected timezone ({initialTimezone}) is different from the saved timezone ({selectedTimezone}).
            Would you like to update it?
          </p>
        </Modal>
      )}

      <Modal
        visible={isJoiningRoom}
        title={t("TeacherDash.JoiningRoom")}
        footer={null}
        closable={false}
      >
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
          <p style={{ marginTop: 16 }}>{t("TeacherDash.ConnectingToRoom")}</p>
        </div>
      </Modal>

      <YourMaterialModal
        isVisible={isYourMaterialModalVisible}
        onClose={() => setIsYourMaterialModalVisible(false)}
        bookingId={selectedBookingId}
      />
      <TrialPreferencesModal
  visible={isTrialPreferencesModalVisible}
  onClose={() => setIsTrialPreferencesModalVisible(false)}
  data={trialPreferencesData}
/>
    </div>
  );
};

export default TeacherDashboard;  