import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import Slider from 'react-slick';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  CircularProgress,
  Chip
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  Language as LanguageIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import { fetch5Teachers, fetchTeacherDetails, getVideo } from '../../store/actions/teachersActions';
import SmallCalendar from '../SmallCalendar';
import noimage from "../../assets/No-Image-Placeholder.svg.webp";

const CustomArrow = ({ direction, onClick }) => (
  <div className={`btl-custom-arrow btl-${direction}-arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M15 18l-6-6 6-6" />
    </svg>
  </div>
);

const BookTrialLesson = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const { Teacherslist: teachers, loading } = useSelector((state) => state.teachers);
  
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [transformedAvailability, setTransformedAvailability] = useState({});

  useEffect(() => {
    dispatch(fetch5Teachers());
  }, [dispatch]);

  const transformAvailabilityData = (slots) => {
    return slots.reduce((acc, slot) => {
      const date = moment(slot.start).format('YYYY-MM-DD');
      const timeSlot = {
        start: moment(slot.start).format('HH:mm'),
        end: moment(slot.end).format('HH:mm')
      };

      if (!acc[date]) {
        acc[date] = { slots: [] };
      }
      acc[date].slots.push(timeSlot);
      return acc;
    }, {});
  };

  const handleTeacherSelect = async (teacherId) => {
    try {
      const teacherData = await dispatch(fetchTeacherDetails(teacherId)).unwrap();
      setSelectedTeacher(teacherData);
      setTransformedAvailability(transformAvailabilityData(teacherData.Availability?.slots || []));
      
      if (teacherData.Video) {
        const url = await dispatch(getVideo(teacherData.Video)).unwrap();
        setVideoUrl(url);
      }
    } catch (error) {
      console.error('Error fetching teacher details:', error);
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="btl-container">
      <Box className="btl-hero">
        <Container maxWidth="lg">
          <Typography variant="h2" className="btl-hero-title">{t("BookTrial.startJourney")}</Typography>
          <Typography variant="h5" className="btl-hero-subtitle">{t("BookTrial.bookLesson")}</Typography>
          <Box className="btl-feature-chips">
            <Chip icon={<SchoolIcon />} label={t("BookTrial.expertTeachers")} className="btl-chip" />
            <Chip icon={<AccessTimeIcon />} label={t("BookTrial.flexibleSchedule")} className="btl-chip" />
            <Chip icon={<LanguageIcon />} label={t("BookTrial.multipleLanguages")} className="btl-chip" />
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" className="btl-main-content">
        {!selectedTeacher ? (
          <div className="btl-teachers-section">
            <Typography variant="h4" className="btl-section-title">{t("header.OurTeachers")}</Typography>
            <Slider {...sliderSettings}>
              {teachers?.map((teacher) => (
                <div key={teacher._id} className="btl-teacher-card">
                  <Card className="btl-card-inner">
                    <CardMedia
                      component="img"
                      height="200"
                      image={teacher.Profile_Image ? `https://ik.imagekit.io/8s3jwexmv/${teacher.Profile_Image}` : noimage}
                      alt={`${teacher.Username || t("OurTeachers.Teacher")}${t("OurTeachers.ProfileOf")}`}
                    />
                    <CardContent>
                      <Typography variant="h6" className="btl-teacher-name">{teacher.Username?.split(' ')[0] || t("OurTeachers.Unknown")}</Typography>
                      <Typography variant="subtitle1" className="btl-teacher-title">{teacher.Short_Title || t("OurTeachers.NoTitleAvailable")}</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleTeacherSelect(teacher._id)}
                        className="btl-book-button"
                      >
                        {t("BookTrial.bookTrial")}
                      </Button>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card className="btl-selected-teacher">
                <CardMedia
                  component="img"
                  height="300"
                  image={selectedTeacher.Profile_Image ? `https://ik.imagekit.io/8s3jwexmv/${selectedTeacher.Profile_Image}` : noimage}
                  alt={selectedTeacher.Username}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>{selectedTeacher.Username}</Typography>
                  <Typography variant="subtitle1" color="textSecondary">{selectedTeacher.Short_Title}</Typography>
                  <Typography variant="body1" className="btl-teacher-description">{selectedTeacher.Description}</Typography>
                  {videoUrl && (
                    <div className="btl-video-container">
                      <iframe
                        src={videoUrl}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title={t("TeacherDetails.introVideo")}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card className="btl-booking-section">
                <CardContent>
                  <Typography variant="h6" gutterBottom>{t("BookTrial.selectTime")}</Typography>
                  <SmallCalendar 
                    onChange={setSelectedDate}
                    availableDates={transformedAvailability}
                    selectedDate={selectedDate}
                    selectedTimeSlots={selectedTimeSlots}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default BookTrialLesson;