import React, { useEffect, useState } from "react";
import AdminNav from "./AdminNav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Delete_Package, fetchAllpackages } from "../../store/actions/packagesActions";
import { useTranslation } from "react-i18next";

const LectureConfigs = ({ lectures = [] }) => {
  return (
    <div className="d-flex flex-column gap-1">
      {lectures.map((lecture, idx) => (
        <div key={idx} className="small">
          {lecture.count} lectures ({lecture.discount}% off) - 
          ₹{lecture.basePrice?.toLocaleString()} 
          {lecture.discount > 0 && ` → ₹${lecture.finalPrice?.toLocaleString()}`}
        </div>
      ))}
    </div>
  );
};

const AdminPackages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const packagesData = useSelector((state) => state.packages.packageslist || { packages: [] });
  const [searchInput, setSearchInput] = useState("");
  const [t] = useTranslation("global");

  useEffect(() => {
    dispatch(fetchAllpackages());
  }, [dispatch]);

  const DeletePackage = (id) => {
    if (window.confirm('Are you sure you want to delete this package?')) {
      dispatch(Delete_Package(id));
    }
  };

  const EditPackage = (PackageID) => {
    navigate(`/Admin-Dashboard/Packages/edit-package/${PackageID}`);
  };

  // Safely handle packages data
  const packages = Array.isArray(packagesData?.packages) ? packagesData.packages : [];
  const filteredPackages = searchInput.trim() 
    ? packages.filter((pack) => 
        pack?.Package_Name?.toLowerCase().includes(searchInput.toLowerCase())
      )
    : packages;


  return (
    <>
      <AdminNav
        value={searchInput}
        setValue={setSearchInput}
        placeholder={t("AdminPackages.placeholder")}
      />

      <div className="Packages_mainPage_style">
        <div className="Packages_header_style d-flex justify-content-between align-items-center mb-4">
          <h6 className="text-dark m-0">{t("AdminPackages.heading")}</h6>
          <Link
            to="/Admin-Dashboard/Packages/add-package"
            className="btn btn-outline-success"
          >
            {t("AdminPackages.AddPackage")}
          </Link>
        </div>

        <div className="Packages_list_style">
          {filteredPackages.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-hover table-borderless">
                <thead className="table-light">
                  <tr>
                    <th className="th">{t("AdminPackages.subheading1")}</th>
                    <th className="th">Teachers</th>
                    <th className="th">Course Details</th>
                    <th className="th">Package Amount</th>
                    <th className="th">Lecture Configurations</th>
                    <th className="th">Summary</th>
                    <th className="th">Status</th>
                    <th className="th">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPackages.map((pack) => (
                    <tr
                      key={pack._id}
                      className="border-bottom"
                    >
                      <td className="td">{pack.Package_Name}</td>
                      <td className="td">
                        {pack?.Teacher_IDs?.map((teacher, index) => (
                          <div key={index} className="small">
                            {teacher.Username}
                          </div>
                        ))}
                      </td>
                      <td className="td">
                        <div className="small fw-bold">{pack?.Course_IDs?.[0]?.Course_Name}</div>
                        <div className="small text-muted">
                          Base Price: ₹{pack?.Course_IDs?.[0]?.Purchase_Price?.toLocaleString()}
                        </div>
                      </td>
                      <td className="td">
                        ₹{pack?.Package_Amount?.toLocaleString()}
                      </td>
                      <td className="td">
                        <LectureConfigs lectures={pack.lectures} />
                      </td>
                      <td className="td">
                        <div className="small">
                          Total Lectures: {pack?.totalLectures}
                        </div>
                        <div className="small">
                          Avg. Discount: {pack?.averageDiscount}%
                        </div>
                      </td>
                      <td className="td">
                        {pack?.Free_Package ? (
                          <span className="badge bg-success">Free Trial</span>
                        ) : (
                          <span className="badge bg-primary">Paid</span>
                        )}
                      </td>
                      <td className="td">
                        <div className="d-flex gap-2">
                          <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={() => EditPackage(pack?._id)}
                            title="Edit Package"
                          >
                            <i className="bi bi-pen-fill"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => DeletePackage(pack?._id)}
                            title="Delete Package"
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">
              <p className="mb-0">{t("AdminPackages.footer")}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminPackages;