import React, { useEffect } from 'react';
import { Modal, Button, List, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { 
  listLocalFiles, 
  downloadLocalFile 
} from '../../store/actions/teachersActions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const YourMaterialModal2 = ({ isVisible, onClose, bookingId }) => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  
  const { localFiles, fileLoading, fileError, downloadLoading, downloadError } = useSelector(state => {
    return state.teachers || {};
  });

  useEffect(() => {
    if (isVisible && bookingId) {
      dispatch(listLocalFiles(bookingId));
    }
  }, [isVisible, bookingId, dispatch]);

  const handleDownload = async (fileName) => {
    try {
      await dispatch(downloadLocalFile({ bookingId, fileName }));
    } catch (error) {
      console.error('File download failed:', error);
      message.error(t('YourMaterialModal2.fileDownloadFailed'));
    }
  };

  return (
    <Modal
      title={t("YourMaterialModal2.title")}
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('YourMaterialModal2.close')}
        </Button>
      ]}
    >
      {fileError && (
        <div className="text-red-500 mb-4">
          {t('YourMaterialModal2.error')}: {fileError}
        </div>
      )}
      
      {downloadError && (
        <div className="text-red-500 mb-4">
          {t('YourMaterialModal2.downloadError')}: {downloadError}
        </div>
      )}
      
      {fileLoading || downloadLoading ? (
        <div className="text-gray-600 text-center py-4">
          {t('YourMaterialModal2.loading')}
        </div>
      ) : localFiles && localFiles.length > 0 ? (
        <List
          className="max-h-96 overflow-y-auto"
          itemLayout="horizontal"
          dataSource={localFiles}
          renderItem={(file) => (
            <List.Item
              actions={[
                <Button 
                  key="download" 
                  type="text" 
                  icon={<DownloadOutlined />} 
                  onClick={() => handleDownload(file.name)}
                  loading={downloadLoading}
                >
                  {t('YourMaterialModal2.download')}
                </Button>
              ]}
            >
              <List.Item.Meta 
                title={file.originalName || t('YourMaterialModal2.unnamedFile')}
                description={
                  file.createdAt 
                    ? moment(file.createdAt).format('MMMM D, YYYY, h:mm A') 
                    : t('YourMaterialModal2.dateNotAvailable')
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <div className="text-gray-600 text-center py-8">
          {t('YourMaterialModal2.noFilesAvailable')}
        </div>
      )}
    </Modal>
  );
};

export default YourMaterialModal2;