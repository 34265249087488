import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminNav from "../AdminNav";
import { notification, Select, Button, Switch, Modal, Row, Col, Typography, Divider, Checkbox } from "antd";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "../Calendar.css";
import { updateTeacher, fetchTeacherDetails } from "../../../store/actions/teachersActions";
import { GetBookingsByTeacherID } from "../../../store/actions/bookingActions";
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CalendarOutlined } from '@ant-design/icons';
import { 
  addDays, 
  format, 
  startOfMonth, 
  endOfMonth, 
  startOfWeek,
  endOfWeek,
  addMonths,
  isSameMonth,
  isSameDay,
  parseISO
} from 'date-fns';
import "./AdminEditTeacherEditAvailability.css";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title, Text } = Typography;
const { Option } = Select;

const timeZones = moment.tz.names();
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

// Define predefined time slots (24-hour format)
const PREDEFINED_SLOTS = [
  { start: "00:00", end: "00:25" },
  { start: "00:30", end: "00:55" },
  { start: "01:00", end: "01:25" },
  { start: "01:30", end: "01:55" },
  { start: "02:00", end: "02:25" },
  { start: "02:30", end: "02:55" },
  { start: "03:00", end: "03:25" },
  { start: "03:30", end: "03:55" },
  { start: "04:00", end: "04:25" },
  { start: "04:30", end: "04:55" },
  { start: "05:00", end: "05:25" },
  { start: "05:30", end: "05:55" },
  { start: "06:00", end: "06:25" },
  { start: "06:30", end: "06:55" },
  { start: "07:00", end: "07:25" },
  { start: "07:30", end: "07:55" },
  { start: "08:00", end: "08:25" },
  { start: "08:30", end: "08:55" },
  { start: "09:00", end: "09:25" },
  { start: "09:30", end: "09:55" },
  { start: "10:00", end: "10:25" },
  { start: "10:30", end: "10:55" },
  { start: "11:00", end: "11:25" },
  { start: "11:30", end: "11:55" },
  { start: "12:00", end: "12:25" },
  { start: "12:30", end: "12:55" },
  { start: "13:00", end: "13:25" },
  { start: "13:30", end: "13:55" },
  { start: "14:00", end: "14:25" },
  { start: "14:30", end: "14:55" },
  { start: "15:00", end: "15:25" },
  { start: "15:30", end: "15:55" },
  { start: "16:00", end: "16:25" },
  { start: "16:30", end: "16:55" },
  { start: "17:00", end: "17:25" },
  { start: "17:30", end: "17:55" },
  { start: "18:00", end: "18:25" },
  { start: "18:30", end: "18:55" },
  { start: "19:00", end: "19:25" },
  { start: "19:30", end: "19:55" },
  { start: "20:00", end: "20:25" },
  { start: "20:30", end: "20:55" },
  { start: "21:00", end: "21:25" },
  { start: "21:30", end: "21:55" },
  { start: "22:00", end: "22:25" },
  { start: "22:30", end: "22:55" },
  { start: "23:00", end: "23:25" },
  { start: "23:30", end: "23:55" }
];

const CustomDay = ({ day, selectedDates }) => {
  const dateStr = format(day, 'yyyy-MM-dd');
  const isSelected = selectedDates[dateStr];

  return (
    <div className={`custom-day ${isSelected ? 'selected' : ''}`}>
      {format(day, 'd')}
    </div>
  );
};

const MiniCalendar = ({ selectedDates, onDateToggle, currentMonth, setCurrentMonth }) => {
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const startDate = startOfWeek(firstDayOfMonth);
  const endDate = endOfWeek(lastDayOfMonth);

  const dateFormat = "d";
  const rows = [];
  let days = [];
  let day = startDate;

  const changeMonth = (amount) => {
    setCurrentMonth(addMonths(currentMonth, amount));
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const cloneDay = day;
      const dateStr = format(cloneDay, 'yyyy-MM-dd');
      days.push(
        <div
          className={`calendar-day ${
            !isSameMonth(day, firstDayOfMonth) ? "disabled" :
            isSameDay(day, new Date()) ? "today" : ""
          } ${selectedDates[dateStr] ? "selected" : ""}`}
          key={day}
          onClick={() => onDateToggle(dateStr)}
        >
          <span>{format(day, dateFormat)}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="calendar-row" key={day}>
        {days}
      </div>
    );
    days = [];
  }

  return (
    <div className="mini-calendar-container">
      <div className="calendar-header">
        <button onClick={() => changeMonth(-1)}>&lt;</button>
        <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
        <button onClick={() => changeMonth(1)}>&gt;</button>
      </div>
      <div className="calendar-days">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div className="calendar-day-header" key={day}>{day}</div>
        ))}
      </div>
      <div className="calendar-body">
        {rows}
      </div>
    </div>
  );
};

const AdminEditTeacherEditAvailability = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const teacherData = useSelector(state => state.teachers.currentTeacher);
  
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [userTimezone, setUserTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [hourFormat, setHourFormat] = useState('24');
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isLongRangeModalVisible, setIsLongRangeModalVisible] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [bookedSlots, setBookedSlots] = useState({});
  const [selectedMonths, setSelectedMonths] = useState({});
  const [existingBookings, setExistingBookings] = useState({});
  const [hasLoadedBookings, setHasLoadedBookings] = useState(false);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });

  const handleTimeRangeChange = (type, value) => {
    setTimeRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const applyTimeRange = (day) => {
    const startIndex = PREDEFINED_SLOTS.findIndex(slot => slot.start === timeRange.start);
    const endIndex = PREDEFINED_SLOTS.findIndex(slot => slot.end === timeRange.end);
    
    if (startIndex === -1 || endIndex === -1) return;

    setAvailability(prev => {
      const newSlots = new Set(prev[day].selectedSlots);
      
      // Get currently selected dates for this day
      const selectedDatesForDay = Object.entries(selectedDates)
        .filter(([dateStr, isDateSelected]) => {
          if (!isDateSelected) return false;
          const dayOfWeek = format(new Date(dateStr), 'EEEE');
          return dayOfWeek === day;
        })
        .map(([dateStr]) => dateStr);

      for (let i = startIndex; i <= endIndex; i++) {
        const slot = PREDEFINED_SLOTS[i];
        const slotStr = `${slot.start}-${slot.end}`;
        
        // Check if slot is booked for any selected date
        const isBooked = selectedDatesForDay.some(dateStr => {
          const bookingsForDate = bookedSlots[dateStr] || [];
          return bookingsForDate.some(booking => 
            booking.start === slot.start && booking.end === slot.end
          );
        });

        // Only add slot if it's not booked
        if (!isBooked) {
          newSlots.add(slotStr);
        }
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          selectedSlots: newSlots
        }
      };
    });
  };

  const [timeRange, setTimeRange] = useState({
    start: "00:00",
    end: "23:55"
  });

  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });

  const [availability, setAvailability] = useState(() => {
    return daysOfWeek.reduce((acc, day) => {
      acc[day] = { 
        enabled: false,
        selectedSlots: new Set(),
      };
      return acc;
    }, {});
  });

  useEffect(() => {
    dispatch(fetchTeacherDetails(id));
  }, [dispatch, id]);
  

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await dispatch(GetBookingsByTeacherID(id));
        // Process bookings into a more usable format
        const bookedTimeslots = {};
        
        response.payload.forEach(booking => {
          booking.Scheduled_Dates.forEach(dateObj => {
            const date = Object.keys(dateObj)[0];
            const slots = dateObj[date].map(slot => {
              // Convert UTC times to user's timezone
              const startTime = dayjs.utc(`${date}T${slot.start}`).tz(userTimezone);
              const endTime = dayjs.utc(`${date}T${slot.end}`).tz(userTimezone);
              
              return {
                start: startTime.format('HH:mm'),
                end: endTime.format('HH:mm'),
                date: startTime.format('YYYY-MM-DD') // This might be different from original date due to timezone
              };
            });
  
            // Group by the converted date
            slots.forEach(slot => {
              if (!bookedTimeslots[slot.date]) {
                bookedTimeslots[slot.date] = [];
              }
              bookedTimeslots[slot.date].push({
                start: slot.start,
                end: slot.end
              });
            });
          });
        });
        
        setBookedSlots(bookedTimeslots);
        setHasLoadedBookings(true);
      } catch (err) {
        console.error("Error fetching bookings:", err);
      }
    };
  
    if (id && !hasLoadedBookings) {
      fetchBookings();
    }
  }, [dispatch, id, hasLoadedBookings, userTimezone]); 
  

  useEffect(() => {
    if (teacherData) {
      const newTimezone = teacherData.userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      setUserTimezone(newTimezone);
      setHourFormat(teacherData.hourFormat || '24');
      setMinDate(new Date());
      
      if (teacherData.Availability && teacherData.Availability.slots) {
        const newSelectedDates = {};
        const newAvailability = daysOfWeek.reduce((acc, day) => {
          acc[day] = { 
            enabled: false,
            selectedSlots: new Set(),
          };
          return acc;
        }, {});
        
        teacherData.Availability.slots.forEach(slot => {
          const startDate = dayjs.utc(slot.start).tz(newTimezone);
          const endDate = dayjs.utc(slot.end).tz(newTimezone);
          const dateStr = startDate.format('YYYY-MM-DD');
          const dayOfWeek = startDate.format('dddd');
          const timeStr = `${startDate.format('HH:mm')}-${endDate.format('HH:mm')}`;
          
          newSelectedDates[dateStr] = true;
          newAvailability[dayOfWeek].enabled = true;
          newAvailability[dayOfWeek].selectedSlots.add(timeStr);
        });
        
        setSelectedDates(newSelectedDates);
        setAvailability(newAvailability);

        const dateKeys = Object.keys(newSelectedDates);
        if (dateKeys.length > 0) {
          setState({
            selection: {
              startDate: parseISO(dateKeys[0]),
              endDate: parseISO(dateKeys[dateKeys.length - 1]),
              key: 'selection'
            }
          });
        }
      }
      
      if (teacherData.selectedDays) {
        setSelectedDays(teacherData.selectedDays);
      }
    }
  }, [teacherData]);

  const handleTimezoneChange = (newTimezone) => {
    setUserTimezone(newTimezone);
  };

  const handleDayCheckboxChange = (day) => {
    setSelectedDays(prev => {
      const newDays = { ...prev, [day]: !prev[day] };
      
      setAvailability(prevAvail => ({
        ...prevAvail,
        [day]: { 
          ...prevAvail[day], 
          enabled: newDays[day],
          selectedSlots: new Set() // Reset slots when toggling day
        }
      }));
  
      setSelectedDates(prevDates => {
        const newDates = { ...prevDates };
        const { startDate, endDate } = state.selection;
        let currentDate = new Date(startDate);
  
        while (currentDate <= endDate) {
          const dateStr = format(currentDate, 'yyyy-MM-dd');
          const dayOfWeek = format(currentDate, 'EEEE');
          
          if (dayOfWeek === day) {
            newDates[dateStr] = newDays[day];
          }
  
          currentDate = addDays(currentDate, 1);
        }
  
        return newDates;
      });
  
      return newDays;
    });
  };


  useEffect(() => {
    setHasLoadedBookings(false); // This will trigger a reload of bookings with new timezone
  }, [userTimezone]);
  const handleSlotToggle = (day, slotIndex) => {
    const slot = PREDEFINED_SLOTS[slotIndex];
    const slotStr = `${slot.start}-${slot.end}`;
    
    // Get currently selected dates for this day of week
    const selectedDatesForDay = Object.entries(selectedDates)
      .filter(([dateStr, isDateSelected]) => {
        if (!isDateSelected) return false;
        const dayOfWeek = format(new Date(dateStr), 'EEEE');
        return dayOfWeek === day;
      })
      .map(([dateStr]) => dateStr);
  
    // Check if slot is booked for any of the selected dates of this day
    const isBooked = selectedDatesForDay.some(dateStr => {
      const bookingsForDate = bookedSlots[dateStr] || [];
      return bookingsForDate.some(booking => 
        booking.start === slot.start && booking.end === slot.end
      );
    });
    
    // If slot is booked, don't allow toggling
    if (isBooked) return;
    
    setAvailability(prev => {
      const newAvailability = { ...prev };
      const dayAvailability = new Set(prev[day].selectedSlots);
      
      if (dayAvailability.has(slotStr)) {
        dayAvailability.delete(slotStr);
      } else {
        dayAvailability.add(slotStr);
      }
      
      newAvailability[day] = {
        ...prev[day],
        selectedSlots: dayAvailability
      };
      
      return newAvailability;
    });
  };

  const handleRangeChange = (item) => {
    const newSelectedDates = { ...selectedDates };
    let currentDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
  
    while (currentDate <= endDate) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      const dayName = format(currentDate, 'EEEE');
      newSelectedDates[dateStr] = selectedDays[dayName];
      currentDate = addDays(currentDate, 1);
    }
  
    setSelectedDates(newSelectedDates);
    setState({ ...state, ...item });
  };

  const handleDateToggle = (dateStr) => {
    setSelectedDates(prev => {
      const newSelectedDates = { ...prev, [dateStr]: !prev[dateStr] };
      const selectedDatesList = Object.keys(newSelectedDates).filter(date => newSelectedDates[date]);
      
      if (selectedDatesList.length > 0) {
        setState({
          ...state,
          selection: {
            startDate: new Date(selectedDatesList[0]),
            endDate: new Date(selectedDatesList[selectedDatesList.length - 1]),
            key: 'selection'
          }
        });
      }
      
      return newSelectedDates;
    });
  };

  const handleLongRangeSelection = () => {
    const newSelectedDates = {};
    selectedYears.forEach(year => {
      const startOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, 11, 31);
      let currentDate = startOfYear;

      while (currentDate <= endOfYear) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        const monthStr = format(currentDate, 'yyyy-MM');
        const dayName = format(currentDate, 'EEEE');

        if (selectedMonths[monthStr] !== false) {
          newSelectedDates[dateStr] = selectedDays[dayName];
        }

        currentDate = addDays(currentDate, 1);
      }
    });

    setSelectedDates(newSelectedDates);
    setState({
      ...state,
      selection: {
        startDate: new Date(Math.min(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        endDate: new Date(Math.max(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        key: 'selection'
      }
    });
    setIsLongRangeModalVisible(false);
  };

  const handleDeleteEntireAvailability = () => {
    setSelectedDates({});
    setAvailability(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = { enabled: false, selectedSlots: new Set() };
        return acc;
      }, {})
    );
    setSelectedDays(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = false;
        return acc;
      }, {})
    );
    setIsDeleteConfirmVisible(false);
  };

  const handleSubmit = async () => {
    try {
      const availabilitySlots = [];
  
      Object.entries(selectedDates).forEach(([date, isSelected]) => {
        if (isSelected) {
          const dayOfWeek = format(new Date(date), 'EEEE');
          if (selectedDays[dayOfWeek] && availability[dayOfWeek]) {
            availability[dayOfWeek].selectedSlots.forEach(slotStr => {
              const [start, end] = slotStr.split('-');
              const slotDate = dayjs.tz(date, userTimezone);
              const fromTime = slotDate.hour(parseInt(start.split(':')[0])).minute(parseInt(start.split(':')[1]));
              const toTime = slotDate.hour(parseInt(end.split(':')[0])).minute(parseInt(end.split(':')[1]));
  
              availabilitySlots.push({
                start: fromTime.utc().format('YYYY-MM-DDTHH:mm'),
                end: toTime.utc().format('YYYY-MM-DDTHH:mm')
              });
            });
          }
        }
      });
  
      const updatedData = {
        ...teacherData,
        Availability: { slots: availabilitySlots },
        selectedDays,
        userTimezone,
      };
  
      await dispatch(updateTeacher({
        teacherId: id,
        updatedData,
      }));
  
      notification.success({
        message: 'Success',
        description: 'Teacher availability updated successfully.',
      });
  
      setIsModalVisible(false);
      navigate("/Admin-Dashboard/Teachers");
    } catch (error) {
      console.error("Error editing teacher:", error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error editing the teacher. Please try again.',
      });
    }
  };

  return (
    <>
      <AdminNav />
      <div className="styled-container">
        <div className="calendar-container">
          <h2>Edit Teacher Availability</h2>
          
          <div className="day-selection">
            {Object.keys(selectedDays).map(day => (
              <div key={day} className="day-switch">
                <Switch
                  checked={selectedDays[day]}
                  onChange={() => handleDayCheckboxChange(day)}
                  className="day-switch-toggle"
                />
                <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                  {day}
                </span>
              </div>
            ))}
          </div>
          
          <DateRangePicker
            onChange={item => handleRangeChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            direction="horizontal"
            minDate={minDate}
            dayContentRenderer={day => <CustomDay day={day} selectedDates={selectedDates} />}
          />
          
          <div className="mini-calendar-switch">
            <Switch
              checked={showMiniCalendar}
              onChange={setShowMiniCalendar}
              checkedChildren="Edit Specific Dates"
              unCheckedChildren="Edit Specific Dates"
            />
          </div>
          
          {showMiniCalendar && (
            <MiniCalendar 
              selectedDates={selectedDates}
              onDateToggle={handleDateToggle}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          )}
          
          <div className="action-buttons">
            <Button 
              icon={<CalendarOutlined />}
              onClick={() => setIsLongRangeModalVisible(true)}
              className="select-long-range-button"
            >
              Select Long Range
            </Button>
            <Button 
              onClick={() => setIsModalVisible(true)}
              type="primary"
              className="set-availability-button"
            >
              Set Availability for Selected Dates
            </Button>
            <Button 
              onClick={() => setIsDeleteConfirmVisible(true)}
              type="danger"
              className="delete-availability-button"
            >
              Delete Entire Availability
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title="Weekly Availability"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
        width={1000}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="modal-content">
            <div className="timezone-selector">
              <label>Timezone availability:</label>
              <Select
                style={{ width: 200 }}
                value={userTimezone}
                onChange={handleTimezoneChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {timeZones.map(tz => (
                  <Option key={tz} value={tz}>{tz}</Option>
                ))}
              </Select>
            </div>
            
            {daysOfWeek.map((day, dayIndex) => (
              <div key={day} className="day-availability">
                <div className="day-header">
                  <div className="day-controls">
                    <Switch
                      checked={selectedDays[day]}
                      onChange={() => handleDayCheckboxChange(day)}
                      className="day-switch-toggle"
                    />
                    <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                      {day}
                    </span>
                    {selectedDays[day] && (
                      <>
                        <div className="copy-controls">
                          <span>Copy from:</span>
                          <Select
                            size="small"
                            style={{ width: 120, marginLeft: 8 }}
                            placeholder="Select day"
                            onChange={(selectedDay) => {
                              setAvailability(prev => ({
                                ...prev,
                                [day]: {
                                  ...prev[day],
                                  selectedSlots: new Set([...prev[selectedDay].selectedSlots])
                                }
                              }));
                            }}
                          >
                            {daysOfWeek
                              .filter(d => d !== day && selectedDays[d])
                              .map(d => (
                                <Option key={d} value={d}>
                                  {d}
                                </Option>
                              ))}
                          </Select>
                        </div>
                        <div className="time-range-controls">
                          <Select
                            size="small"
                            style={{ width: 100 }}
                            value={timeRange.start}
                            onChange={(value) => handleTimeRangeChange('start', value)}
                          >
                            {PREDEFINED_SLOTS.map(slot => (
                              <Option key={slot.start} value={slot.start}>
                                {slot.start}
                              </Option>
                            ))}
                          </Select>
                          <span style={{ margin: '0 8px' }}>to</span>
                          <Select
                            size="small"
                            style={{ width: 100 }}
                            value={timeRange.end}
                            onChange={(value) => handleTimeRangeChange('end', value)}
                          >
                            {PREDEFINED_SLOTS.map(slot => (
                              <Option key={slot.end} value={slot.end}>
                                {slot.end}
                              </Option>
                            ))}
                          </Select>
                          <Button
                            size="small"
                            type="primary"
                            style={{ marginLeft: 8 }}
                            onClick={() => applyTimeRange(day)}
                          >
                            Apply Range
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                
                {selectedDays[day] && (
                  <div className="time-slots-grid">
                    {PREDEFINED_SLOTS.map((slot, index) => {
                      const slotStr = `${slot.start}-${slot.end}`;
                      const isSelected = availability[day].selectedSlots.has(slotStr);
                      
                      // Get currently selected dates for this day of week
                      const selectedDatesForDay = Object.entries(selectedDates)
                        .filter(([dateStr, isDateSelected]) => {
                          if (!isDateSelected) return false;
                          const dayOfWeek = format(new Date(dateStr), 'EEEE');
                          return dayOfWeek === day;
                        })
                        .map(([dateStr]) => dateStr);

                      // Check if slot is booked for any of the selected dates of this day
                      const isBooked = selectedDatesForDay.some(dateStr => {
                        const bookingsForDate = bookedSlots[dateStr] || [];
                        return bookingsForDate.some(booking => 
                          booking.start === slot.start && booking.end === slot.end
                        );
                      });
                      
                      return (
                        <Checkbox
                          key={index}
                          checked={isSelected || isBooked}
                          onChange={() => handleSlotToggle(day, index)}
                          disabled={isBooked}
                          className={`slot-checkbox ${isBooked ? 'booked-slot' : ''}`}
                        >
                          <span className={isBooked ? 'booked-text' : ''}>
                            {`${slot.start} - ${slot.end}`}
                            {isBooked && ' (Booked)'}
                          </span>
                        </Checkbox>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        </LocalizationProvider>
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteConfirmVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={handleDeleteEntireAvailability}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete the entire availability? This action cannot be undone.</p>
      </Modal>

      <Modal
        title={<Title level={3}><CalendarOutlined /> Select Long Range</Title>}
        visible={isLongRangeModalVisible}
        onCancel={() => setIsLongRangeModalVisible(false)}
        onOk={handleLongRangeSelection}
        width={800}
        footer={[
          <Button key="cancel" onClick={() => setIsLongRangeModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleLongRangeSelection}>
            Apply Selection
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Years</Title>
          <Row gutter={[16, 16]}>
            {[...Array(10)].map((_, index) => {
              const year = new Date().getFullYear() + index;
              return (
                <Col key={year} span={6}>
                  <Checkbox
                    checked={selectedYears.includes(year)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedYears([...selectedYears, year]);
                        setSelectedMonths(prev => ({
                          ...prev,
                          ...Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).reduce((acc, month) => {
                            acc[month] = true;
                            return acc;
                          }, {})
                        }));
                      } else {
                        setSelectedYears(selectedYears.filter(y => y !== year));
                        setSelectedMonths(prev => {
                          const newMonths = { ...prev };
                          Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).forEach(month => {
                            delete newMonths[month];
                          });
                          return newMonths;
                        });
                      }
                    }}
                  >
                    <Text strong>{year}</Text>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </div>

        <Divider />

        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Months</Title>
          {selectedYears.map(year => (
            <div key={year}>
              <Title level={5} style={{ marginBottom: '10px' }}>{year}</Title>
              <Row gutter={[16, 16]}>
                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
                  const monthStr = `${year}-${String(index + 1).padStart(2, '0')}`;
                  return (
                    <Col key={monthStr} span={6}>
                      <Checkbox
                        checked={selectedMonths[monthStr] !== false}
                        onChange={(e) => {
                          setSelectedMonths(prev => ({
                            ...prev,
                            [monthStr]: e.target.checked
                          }));
                        }}
                      >
                        <Text>{month}</Text>
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
              <Divider />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default AdminEditTeacherEditAvailability;