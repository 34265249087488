import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { 
  Clock, Globe, Mail, MapPin, Phone, User, Edit2, Camera, Award,
  Check, X, Shield, Bell, CreditCard, Calendar, Lock, Settings,
  
  Eye, EyeOff
} from 'lucide-react';
import "react-phone-input-2/lib/style.css";
import Joyride from 'react-joyride';
import { updatePhoneNumber } from '../../store/actions/studentsActions';
import { updateStudent, Reset_Password } from "../../store/actions/studentsActions";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { imageUpload } from '../../store/actions/teachersActions'; 
import Select from 'react-select';


// Progress component
const Progress = ({ value }) => (
  <div className="w-full bg-gray-200 rounded-full h-2">
    <div 
      className="bg-indigo-500 h-2 rounded-full transition-all duration-300"
      style={{ width: `${value}%` }}
    />
  </div>
);

// Toggle Switch component
const ToggleSwitch = ({ checked, onChange, color = "bg-indigo-500" }) => (
  <button
    onClick={() => onChange?.(!checked)}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
      checked ? color : 'bg-gray-200'
    }`}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
        checked ? 'translate-x-6' : 'translate-x-1'
      } shadow-lg`}
    />
  </button>
);



// Modal Component
// Modal Component
const Modal = ({ isOpen, onClose, title }) => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const student = useSelector((state) => state.students.user);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPasswords, setShowPasswords] = useState({
    new: false,
    confirm: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!newPassword || !confirmPassword) {
      setError(t('Password.allFieldsRequired'));
      return;
    }

    if (newPassword !== confirmPassword) {
      setError(t('Password.passwordsDoNotMatch'));
      return;
    }

    if (newPassword.length < 8) {
      setError(t('Password.minimumLength'));
      return;
    }

    try {
      const result = await dispatch(Reset_Password({
        id: student._id,
        New_Password: newPassword
      })).unwrap();

      if (result.success) {
        onClose();
      } else {
        setError(result.message || t('Password.resetFailed'));
      }
    } catch (error) {
      setError(error.message || t('Password.resetFailed'));
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-2 md:p-4">
      <div className="bg-white rounded-lg md:rounded-xl p-3 md:p-6 w-full max-w-md mx-auto shadow-xl">
        <h3 className="text-base md:text-xl font-semibold mb-2 md:mb-4">{title || t("Password.changePassword")}</h3>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* New Password */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t("StudentProfile.new")}
            </label>
            <div className="relative">
              <input
                type={showPasswords.new ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder={t("StudentProfile.enterNew")}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('new')}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
              >
                {showPasswords.new ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
              </button>
            </div>
          </div>

          {/* Confirm New Password */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t("StudentProfile.confirm")}
            </label>
            <div className="relative">
              <input
                type={showPasswords.confirm ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder={t("StudentProfile.confirmNew")}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('confirm')}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
              >
                {showPasswords.confirm ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
              </button>
            </div>
          </div>

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          <div className="flex justify-end gap-2 md:gap-4 mt-6">
            <button 
              type="button"
              onClick={onClose}
              className="px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-base border border-gray-300 rounded-md md:rounded-lg hover:bg-gray-50 transition-colors"
            >
              {t("common.Cancel")}
            </button>
            <button 
              type="submit"
              className="px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-base bg-indigo-500 text-white rounded-md md:rounded-lg hover:bg-indigo-600 transition-colors"
            >
              {t("common.Confirm")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Image Upload Modal Component
const ImageUploadModal = ({ isOpen, onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const { t } = useTranslation("global");

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      try {
        await onUpload(selectedFile);
        onClose();
        setSelectedFile(null);
        setPreviewUrl(null);
      } finally {
        setIsUploading(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl p-6 w-full max-w-md mx-auto shadow-xl">
        <h3 className="text-xl font-semibold mb-4">{t("StudentProfile.uploadImage")}</h3>
        <div className="space-y-4">
          {previewUrl && (
            <div className="relative w-32 h-32 mx-auto">
              <img 
                src={previewUrl} 
                alt="Preview" 
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          )}
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            className="hidden"
            disabled={isUploading}
          />
          <button
            onClick={() => fileInputRef.current?.click()}
            className={`w-full p-2 border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 transition-colors ${
              isUploading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isUploading}
          >
            {t("StudentProfile.selectImage")}
          </button>
          <div className="flex justify-end gap-4">
            <button
              onClick={onClose}
              className={`px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors ${
                isUploading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isUploading}
            >
              {t("common.Cancel")}
            </button>
            <button
              onClick={handleUpload}
              disabled={!selectedFile || isUploading}
              className={`px-4 py-2 rounded-lg text-white transition-colors flex items-center justify-center min-w-[80px] ${
                selectedFile && !isUploading ? 'bg-indigo-500 hover:bg-indigo-600' : 'bg-gray-300'
              }`}
            >
              {isUploading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                t("common.Upload")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Info Card Component
const InfoCard = ({ icon: Icon, title, color, fields = [], children, onSave, showEdit = true }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation("global");

  const handleStartEdit = () => {
    const initialEdits = {};
    fields.forEach(field => {
      if (field.editable !== false) {
        initialEdits[field.label] = field.value;
      }
    });
    setEditedFields(initialEdits);
    setIsEditing(true);
    setError(null);
  };

  

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await onSave?.(editedFields);
      setIsEditing(false);
    } catch (err) {
      setError(err.message || t("common.ErrorOccurred"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedFields({});
    setError(null);
  };

  const renderFieldValue = (field) => {
    // If the value is null or undefined, return empty string
    if (field.value == null) {
      return '';
    }
  
    // Handle nested objects (like Address)
    if (typeof field.value === 'object' && !Array.isArray(field.value)) {
      if ('street' in field.value) return field.value.street;
      if ('city' in field.value) return field.value.city;
      if ('postalCode' in field.value) return field.value.postalCode;
      if ('country' in field.value) return field.value.country;
    }
  
    // For primitive values (strings, numbers, etc.)
    return field.value.toString();
  };

  const renderEditField = (field) => {
    if (field.type === 'tel') {
      return (
        <PhoneInput
          international
          defaultCountry="PL"
          value={editedFields[field.label] || ''}
          onChange={(value) => setEditedFields({
            ...editedFields,
            [field.label]: value
          })}
          className="w-full mt-1 px-2 py-1 text-xs md:text-base border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
      );
    } else if (field.type === 'select') {
      return (
        <select
          value={editedFields[field.label] || ''}
          onChange={(e) => setEditedFields({
            ...editedFields,
            [field.label]: e.target.value
          })}
          className="w-full mt-1 px-2 py-1 text-xs md:text-base border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        >
          {field.options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else if (field.type === 'toggle') {
      return (
        <ToggleSwitch
          checked={editedFields[field.label] || false}
          onChange={(checked) => setEditedFields({
            ...editedFields,
            [field.label]: checked
          })}
          color={color.bg}
        />
      );
    } else {
      return (
        <input
          type={field.type || "text"}
          value={editedFields[field.label] || ''}
          onChange={(e) => setEditedFields({
            ...editedFields,
            [field.label]: e.target.value
          })}
          className="w-full mt-1 px-2 py-1 text-xs md:text-base border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
      );
    }
  };

  return (
    <div className="overflow-hidden rounded-md md:rounded-xl bg-white shadow-lg transition-transform hover:scale-[1.02]">
      <div className={`p-2 md:p-4 ${color.bg} border-b`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5 md:gap-3">
            <div className={`p-1 md:p-2 bg-white rounded-md md:rounded-lg shadow-sm`}>
              <Icon className={`w-3 h-3 md:w-5 md:h-5 ${color.icon}`} />
            </div>
            <h3 className={`text-xs md:text-base font-semibold ${color.text}`}>{title}</h3>
          </div>
          {showEdit && !isEditing && fields.length > 0 && (
            <button
              onClick={handleStartEdit}
              className="p-1 md:p-2 hover:bg-white/50 rounded-full transition-colors"
              disabled={isLoading}
            >
              <Edit2 className="w-3 h-3 md:w-4 md:h-4 text-gray-600" />
            </button>
          )}
          {showEdit && isEditing && (
            <div className="flex gap-2">
              <button
                onClick={handleSave}
                className="p-1 md:p-2 hover:bg-white/50 rounded-full transition-colors text-green-600"
                disabled={isLoading}
              >
                <Check className="w-3 h-3 md:w-4 md:h-4" />
              </button>
              <button
                onClick={handleCancel}
                className="p-1 md:p-2 hover:bg-white/50 rounded-full transition-colors text-red-600"
                disabled={isLoading}
              >
                <X className="w-3 h-3 md:w-4 md:h-4" />
              </button>
            </div>
          )}
        </div>
      </div>
  
      <div className="p-2 md:p-4 space-y-2 md:space-y-4">
        {fields.map((field, i) => (
          <div key={i} className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 md:gap-0">
            <div className="flex-1">
              <div className="text-xs md:text-sm text-gray-500">{field.label}</div>
              {isEditing && field.editable !== false ? (
                field.type === 'tel' ? (
                  <PhoneInput
                    international
                    defaultCountry="PL"
                    value={editedFields[field.label] || ''}
                    onChange={(value) => setEditedFields({
                      ...editedFields,
                      [field.label]: value
                    })}
                    className="w-full mt-1"
                  />
                ) : field.type === 'select' ? (
                  <select
                    value={editedFields[field.label] || ''}
                    onChange={(e) => setEditedFields({
                      ...editedFields,
                      [field.label]: e.target.value
                    })}
                    className="w-full mt-1 px-2 py-1 text-xs md:text-base border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    {field.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field.type === 'toggle' ? (
                  <ToggleSwitch
                    checked={editedFields[field.label] || false}
                    onChange={(checked) => setEditedFields({
                      ...editedFields,
                      [field.label]: checked
                    })}
                    color={color.bg}
                  />
                ) : (
                  <input
                    type={field.type || "text"}
                    value={editedFields[field.label] || ''}
                    onChange={(e) => setEditedFields({
                      ...editedFields,
                      [field.label]: e.target.value
                    })}
                    className="w-full mt-1 px-2 py-1 text-xs md:text-base border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                )
              ) : field.type === 'toggle' ? (
                <ToggleSwitch
                  checked={field.value}
                  onChange={null}
                  color={color.bg}
                />
              ) : (
                <div className="text-xs md:text-base font-medium text-gray-900">
                  {renderFieldValue(field)}
                </div>
              )}
            </div>
            
            {field.dropdown && !isEditing && (
              <div className="relative language-select w-full md:w-auto mt-1 md:mt-0">
                <div className="space-y-2">
                  {field.dropdown.options.map((option) => (
                    <button
                      key={option.code}
                      onClick={() => field.dropdown.onChange(option.code, option.name)}
                      className={`w-full px-3 py-2 rounded-lg flex items-center gap-3 transition-colors duration-200 ${
                        option.active
                          ? `${color.bg} ${color.text}`
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      <img
                        src={require(`../../assets/${option.flag}`)}
                        alt={option.name}
                        className="w-5 h-3 object-cover rounded-sm border border-gray-200"
                      />
                      <span className="text-sm">{option.name}</span>
                      {option.active && (
                        <Check className="w-4 h-4 ml-auto" />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
        
        {error && (
          <div className="text-red-500 text-xs md:text-sm mt-2">
            {error}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

// Main Profile Component
const Profile = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const student = useSelector((state) => state.students.user);
  const [localStudent, setLocalStudent] = useState(student);
  const [currentTimezone, setCurrentTimezone] = useState(() => {
    // Priority: localStorage > user preferences > default
    return localStorage.getItem('currentTimezone') || student?.Timezone || "Europe/Warsaw";
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation("global");

  const [timeFormat, setTimeFormat] = useState(() => {
    return localStorage.getItem('timeFormat') || '24';
  });
  
  // States
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [timezoneSearch, setTimezoneSearch] = useState('');
const [showTimezoneDropdown, setShowTimezoneDropdown] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    // Get language from localStorage
    const savedLanguage = localStorage.getItem('i18nextLng');
    // If saved language exists, use it to determine the display name
    if (savedLanguage) {
      return savedLanguage === 'en' ? t("languages.English") : t("languages.Polish");
    }
    // Default to browser language
    return i18n.language === 'en' ? t("languages.English") : t("languages.Polish");
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  const [pendingLanguage, setPendingLanguage] = useState({ code: '', name: '' });
  const [notificationPreferences, setNotificationPreferences] = useState(() => {
    const savedPreferences = localStorage.getItem('notificationPreferences');
    return savedPreferences ? JSON.parse(savedPreferences) : {
      email: true,
      sms: false,
      inApp: true
    };
  });
  const [securitySettings, setSecuritySettings] = useState({
    twoFactorEnabled: true,
    lastPasswordChange: '30 days ago'
  });
  const [profileCompletion, setProfileCompletion] = useState(90);

  const getFilteredTimezones = () => {
    const timezones = getOrganizedTimezones();
    if (!timezoneSearch) return timezones;
    return timezones.filter(tz => 
      tz.toLowerCase().includes(timezoneSearch.toLowerCase())
    );
  };

   // Update localStorage when notification preferences change
   useEffect(() => {
    localStorage.setItem('notificationPreferences', JSON.stringify(notificationPreferences));
  }, [notificationPreferences]);

  // Update localStorage when language changes
  useEffect(() => {
    localStorage.setItem('currentLanguage', currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  // Update localStorage when timezone changes
  useEffect(() => {
    localStorage.setItem('currentTimezone', currentTimezone);
  }, [currentTimezone]);

  // Update localStorage when time format changes
  useEffect(() => {
    localStorage.setItem('timeFormat', timeFormat);
  }, [timeFormat]);

  // Tour state
  const [tourState, setTourState] = useState({
    run: false,
    stepIndex: 0,
    steps: [
      {
        target: '.profile-image',
        title: '✨ Your Profile Picture',
        content: 'Upload or change your profile picture here',
        placement: 'bottom',
      },
      {
        target: '.language-select',
        title: '🌍 Language Settings',
        content: 'Choose your preferred language here',
        placement: 'right',
      },
      {
        target: '.security-settings',
        title: '🔒 Security',
        content: 'Manage your account security settings',
        placement: 'left',
      },
      {
        target: '.notifications-preferences',
        title: '🔔 Notifications',
        content: 'Control how you receive updates',
        placement: 'right',
      }
    ]
  });
  console.log('Current student:', student); // Add this at the top of your Profile component


  const cardColors = {
    header: { bg: 'bg-indigo-100', text: 'text-indigo-900', icon: 'bg-indigo-500' },
    personal: { bg: 'bg-blue-50', text: 'text-blue-900', icon: 'bg-blue-500' },
    contact: { bg: 'bg-emerald-50', text: 'text-emerald-900', icon: 'bg-emerald-500' },
    location: { bg: 'bg-amber-50', text: 'text-amber-900', icon: 'bg-amber-500' },
    language: { bg: 'bg-violet-50', text: 'text-violet-900', icon: 'bg-violet-500' },
    billing: { bg: 'bg-rose-50', text: 'text-rose-900', icon: 'bg-rose-500' },
    security: { bg: 'bg-teal-50', text: 'text-teal-900', icon: 'bg-teal-500' },
    notifications: { bg: 'bg-orange-50', text: 'text-orange-900', icon: 'bg-orange-500' },
    account: { bg: 'bg-cyan-50', text: 'text-cyan-900', icon: 'bg-cyan-500' }
  };

  useEffect(() => {
    const calculateProfileCompletion = () => {
      const requiredFields = {
        personal: [student?.firstName, student?.lastName],
        contact: [student?.Phone_Number, student?.Email],
        address: [student?.Address],
        timezone: [student?.Timezone],
        profileImage: [student?.Profile_Image],
        billing: [
          student?.billingName,
          student?.billingAddress,
          student?.taxId
        ],
        // Add any other required fields
      };
  
      let completedFields = 0;
      let totalFields = 0;
  
      // Count completed fields
      Object.values(requiredFields).flat().forEach(field => {
        totalFields++;
        if (field && String(field).trim()) {
          completedFields++;
        }
      });
  
      // Calculate percentage
      const percentage = Math.round((completedFields / totalFields) * 100);
      setProfileCompletion(percentage);
    };
  
    calculateProfileCompletion();
  }, [student]);

  const languageOptions = [
    { 
      code: "en", 
      name: t("languages.English"), 
      flag: "English.webp",
      active: i18n.language === 'en'
    },
    { 
      code: "pl", 
      name: t("languages.Polish"), 
      flag: "Polish.png",
      active: i18n.language === 'pl'
    }
  ];

  // Handlers
  

  const handleChangeLanguage = useCallback((lang, langName) => {
    // Change the language in i18next
    i18n.changeLanguage(lang);
    // Update the display name state
    setCurrentLanguage(langName);
    // Save both the language code and display name
    localStorage.setItem('i18nextLng', lang);
    localStorage.setItem('currentLanguage', langName);
  }, [i18n, t]);

  const handleSavePersonalDetails = async (fields) => {
    try {
      setIsLoading(true);
      const updatedData = {
        firstName: fields[t("EditStudentProfile.FirstName")],
        lastName: fields[t("EditStudentProfile.LastName")],
        Username: `${fields[t("EditStudentProfile.FirstName")]} ${fields[t("EditStudentProfile.LastName")]}`
      };
      
      // Update Redux
      const result = await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();
  
      // Immediately update local state
      setLocalStudent(prev => ({
        ...prev,
        ...updatedData
      }));
      
      return true;
    } catch (error) {
      console.error('Update error:', error);
      throw new Error(t("PersonalDetails.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSaveContact = async (fields) => {
    try {
      setIsLoading(true);
      const updatedData = {
        Phone_Number: fields[t("StudentProfile.Phone")], // This will now contain the full international format
        Email: fields[t("StudentProfile.Email")]
      };
      
      const result = await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();
  
      setLocalStudent(prev => ({
        ...prev,
        ...updatedData
      }));
      
      return true;
    } catch (error) {
      console.error('Contact update error:', error);
      throw new Error(t("Contact.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSaveTimeSettings = async (fields) => {
    try {
      setIsLoading(true);
      // Keep existing timezone if not provided in fields
      const newTimezone = fields[t("Profile.selectTimezone")] || currentTimezone;
      const newTimeFormat = fields[t("Profile.timeFormat")] === '24-hour' ? '24' : '12';
      
      const updatedData = {
        Timezone: newTimezone,
        timeFormat: newTimeFormat
      };
      
      await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();

      // Update states and localStorage
      setTimeFormat(newTimeFormat);
      // Only update timezone if it was provided
      if (fields[t("Profile.selectTimezone")]) {
        setCurrentTimezone(newTimezone);
        localStorage.setItem('currentTimezone', newTimezone);
      }
      localStorage.setItem('timeFormat', newTimeFormat);
      
      // Update local student state while preserving other fields
      setLocalStudent(prev => ({
        ...prev,
        Timezone: newTimezone,
        timeFormat: newTimeFormat
      }));
      
      return true;
    } catch (error) {
      console.error('Time settings update error:', error);
      throw new Error(t("TimeSettings.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Sync with backend data if available
    if (student) {
      const backendNotificationPreferences = student.notificationPreferences;
      const backendTimezone = student.Timezone;
      const backendTimeFormat = student.timeFormat;

      // Only update if backend data exists and differs from localStorage
      if (backendNotificationPreferences) {
        setNotificationPreferences(prev => {
          const newPreferences = { ...prev, ...backendNotificationPreferences };
          localStorage.setItem('notificationPreferences', JSON.stringify(newPreferences));
          return newPreferences;
        });
      }

      if (backendTimezone) {
        setCurrentTimezone(backendTimezone);
        localStorage.setItem('currentTimezone', backendTimezone);
      }

      if (backendTimeFormat) {
        setTimeFormat(backendTimeFormat);
        localStorage.setItem('timeFormat', backendTimeFormat);
      }
    }
  }, [student]);
  
  const handleSaveLocation = async (fields) => {
    try {
      setIsLoading(true);
      const updatedData = {
        Address: {
          street: fields[t("StudentProfile.street")],
          city: fields[t("StudentProfile.city")],
          postalCode: fields[t("StudentProfile.postalCode")],
          country: fields[t("StudentProfile.country")]
        }
      };
      
      await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();
      
      // Immediately update local state
      setLocalStudent(prev => ({
        ...prev,
        ...updatedData
      }));
      
      return true;
    } catch (error) {
      console.error('Location update error:', error);
      throw new Error(t("Location.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSaveBilling = async (fields) => {
    try {
      setIsLoading(true);
      const updatedData = {
        billingName: fields[t("StudentProfile.fullName")],
        billingAddress: fields[t("AddStudentProfileress.street")],
        taxId: fields[t("StudentProfile.taxId")],
        billingCity: fields[t("StudentProfile.city")],
        billingPostalCode: fields[t("StudentProfile.postalCode")],
        billingCountry: fields[t("StudentProfile.country")]
      };
      
      // Update Redux
      await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();
  
      // Immediately update local state with the full billing information
      setLocalStudent(prev => ({
        ...prev,
        billingName: updatedData.billingName,
        billingAddress: updatedData.billingAddress,
        taxId: updatedData.taxId,
        billingCity: updatedData.billingCity,
        billingPostalCode: updatedData.billingPostalCode,
        billingCountry: updatedData.billingCountry
      }));
      
      return true;
    } catch (error) {
      console.error('Billing update error:', error);
      throw new Error(t("Billing.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSaveSecuritySettings = async (settings) => {
    try {
      setIsLoading(true);
      const updatedData = {
        securitySettings: {
          ...student.securitySettings,
          ...settings
        }
      };
      
      await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();
  
      // Update both states
      setSecuritySettings(prev => ({ ...prev, ...settings }));
      setLocalStudent(prev => ({
        ...prev,
        securitySettings: {
          ...prev.securitySettings,
          ...settings
        }
      }));
      
      return true;
    } catch (error) {
      console.error('Security settings update error:', error);
      throw new Error(t("Security.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveTimezone = async (fields) => {
    try {
      setIsLoading(true);
      const updatedData = {
        Timezone: fields[t("Profile.selectTimezone")]
      };
      
      // Update Redux
      const result = await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();

      // Update local state
      setLocalStudent(prev => ({
        ...prev,
        Timezone: updatedData.Timezone
      }));
      setCurrentTimezone(updatedData.Timezone);
      
      return true;
    } catch (error) {
      console.error('Timezone update error:', error);
      throw new Error(t("Timezone.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };

  // Organize timezones by region for better UX
  const getOrganizedTimezones = () => {
    const timezones = moment.tz.names();
    return timezones.map(tz => {
      const offset = moment.tz(tz).format('Z');
      return `(UTC${offset}) ${tz}`;
    }).sort();
  };
  
  const handleToggleNotification = async (type, enabled) => {
    try {
      setIsLoading(true);
      const updatedPreferences = {
        ...notificationPreferences,
        [type]: enabled
      };
      
      const updatedData = {
        notificationPreferences: updatedPreferences
      };
      
      await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: updatedData
      })).unwrap();
  
      // Update state and localStorage
      setNotificationPreferences(updatedPreferences);
      localStorage.setItem('notificationPreferences', JSON.stringify(updatedPreferences));
      
      return true;
    } catch (error) {
      console.error('Notification update error:', error);
      throw new Error(t("Notifications.updateFailed"));
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleImageUpload = async (file) => {
    try {
      // Dispatch the imageUpload action
      const filename = await dispatch(imageUpload(file)).unwrap();
      
      if (!filename) {
        throw new Error(t("Profile.imageUploadFailed"));
      }
  
      // Update the profile image in database
      await dispatch(updateStudent({
        student_ID: student._id,
        updatedData: {
          Profile_Image: filename
        }
      })).unwrap();
  
      // Update local state
      setLocalStudent(prev => ({
        ...prev,
        Profile_Image: filename
      }));
  
    } catch (error) {
      console.error('Image upload failed:', error);
      throw new Error(t("Profile.imageUploadFailed"));
    }
  };

  // Calculate profile completion
  useEffect(() => {
    const calculateCompletion = () => {
      const fields = [
        // Personal Info
        localStudent?.firstName,
        localStudent?.lastName,
        localStudent?.UserType,
        
        // Contact Info
        localStudent?.Phone_Number,
        localStudent?.Email,
        
        // Address Fields
        localStudent?.Address?.street,
        localStudent?.Address?.city,
        localStudent?.Address?.postalCode,
        localStudent?.Address?.country,
        
        // Billing Info
        localStudent?.billingName,
        localStudent?.billingAddress,
        localStudent?.taxId,
        localStudent?.billingCity,
        localStudent?.billingPostalCode,
        localStudent?.billingCountry,
        
        // Security Settings
        localStudent?.securitySettings?.twoFactorEnabled,
        localStudent?.securitySettings?.lastPasswordChange,
        
        // Notification Preferences
        localStudent?.notificationPreferences?.email,
        localStudent?.notificationPreferences?.sms,
        localStudent?.notificationPreferences?.inApp,
        
        // Profile Image
        localStudent?.Profile_Image,
        
        // Timezone
        localStudent?.Timezone
      ];
      
      const filledFields = fields.filter(field => {
        // Handle different types of fields
        if (typeof field === 'string') {
          return field?.trim().length > 0;
        }
        if (typeof field === 'number') {
          return true; // Numbers are considered filled
        }
        if (typeof field === 'boolean') {
          return field !== null && field !== undefined; // Consider false as filled but undefined/null as empty
        }
        if (typeof field === 'object' && field !== null) {
          return Object.keys(field).length > 0; // Check if object has properties
        }
        return false; // Undefined, null, or other types are considered empty
      }).length;
  
      const completion = Math.round((filledFields / fields.length) * 100);
      setProfileCompletion(completion);
    };
  
    calculateCompletion();
  }, [localStudent]);

  useEffect(() => {
    setLocalStudent(student);
  }, [student]);

  return (
    <div className="min-h-screen p-2 md:p-6 pb-20 bg-gray-50">
      {/* Progress Card - New, separate card above everything */}
      <div className="mb-3 md:mb-6 overflow-hidden rounded-lg md:rounded-xl bg-white shadow-lg">
        <div className={`p-3 md:p-6 ${cardColors.header.bg}`}>
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-sm md:text-base font-semibold">{t("StudentProfile.ProfileCompletion")}</h2>
                <span className="text-sm font-medium">{profileCompletion}%</span>
              </div>
              <div className="w-full bg-white/50 rounded-full h-2">
                <div
                  className="bg-indigo-500 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${profileCompletion}%` }}
                />
              </div>
              {profileCompletion < 100 && (
                <p className="text-xs mt-2 text-indigo-900">
                  {t("Profile.Completeyourprofiletounlockallfeatures")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
  
      {/* Existing Header Card */}
      <div className="mb-3 md:mb-6 overflow-hidden rounded-lg md:rounded-xl bg-white shadow-lg">
        <div className={`p-3 md:p-6 ${cardColors.header.bg}`}>
          <div className={`flex justify-between items-center ${cardColors.header.text}`}>
            <div>
              <h1 className="text-lg md:text-2xl font-bold">{t("StudentProfile.ProfileSettings")}</h1>
              <p className="text-xs md:text-base opacity-90">{t("StudentProfile.ManageAccount")}</p>
            </div>
            <Clock className="w-5 h-5 md:w-8 md:h-8" />
          </div>
        </div>

        {/* Profile Image Section */}
        <div className="p-3 md:p-6 relative">
          <div className="flex flex-col md:flex-row gap-3 md:gap-6 items-start">
            <div className="relative -mt-8 md:-mt-16 profile-image">
              <img
                src={`https://ik.imagekit.io/8s3jwexmv/${localStudent?.Profile_Image}`}
                alt={t("StudentProfile.ProfileImage")}
                className="w-16 h-16 md:w-32 md:h-32 rounded-lg md:rounded-xl border-4 border-white shadow-lg object-cover"
              />
              <button 
                onClick={() => setShowImageUpload(true)}
                className="absolute bottom-0 right-0 p-1 md:p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <Camera className="w-3 h-3 md:w-4 md:h-4 text-gray-600" />
              </button>
            </div>
            
            <div className="flex-1 w-full">
              <div className="flex flex-col md:flex-row justify-between items-start gap-2 md:gap-0">
                <div>
                  <h2 className="text-lg md:text-2xl font-bold text-gray-900">
                    {localStudent?.firstName} {localStudent?.lastName}
                  </h2>
                  <p className="text-xs md:text-base text-gray-500">
                    {t(`UserType.${localStudent?.UserType}`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 gap-4 sm:gap-6 mb-20">
        {/* Personal Details */}
        <InfoCard 
          icon={User} 
          title={t("StudentProfile.PersonalDetails")} 
          color={cardColors.personal}
          fields={[
            { 
              label: t("EditStudentProfile.FirstName"), 
              value: localStudent?.firstName,
              type: "text"
            },
            { 
              label: t("EditStudentProfile.LastName"), 
              value: localStudent?.lastName,
              type: "text"
            },
            { 
              label: t("StudentProfile.StudentId"), 
              value: localStudent?._id, 
              editable: false 
            }
          ]}
          onSave={handleSavePersonalDetails}
        />

        {/* Contact Info */}
        <InfoCard 
          icon={Phone} 
          title={t("StudentProfile.ContactInfo")} 
          color={cardColors.contact}
          fields={[
            { 
              label: t("StudentProfile.Phone"), 
              value: localStudent?.Phone_Number,
              type: "tel"
            },
            { 
              label: t("StudentProfile.Email"), 
              value: localStudent?.Email,
              type: "email"
            }
          ]}
          onSave={handleSaveContact}
        />

        {/* Residential Address */}
        <InfoCard 
          icon={MapPin} 
          title={t("StudentProfile.ResidentialAddress")} 
          color={cardColors.location}
          fields={[
            { 
              label: t("StudentProfile.street"), 
              value: localStudent?.Address?.street || ''
            },
            { 
              label: t("StudentProfile.city"), 
              value: localStudent?.Address?.city || 'Białystok'
            },
            { 
              label: t("StudentProfile.postalCode"), 
              value: localStudent?.Address?.postalCode || '15-001'
            },
            { 
              label: t("StudentProfile.country"), 
              value: localStudent?.Address?.country || 'Poland'
            }
          ]}
          onSave={handleSaveLocation}
        />

        {/* Billing Details */}
        <InfoCard 
          icon={CreditCard} 
          title={t("StudentProfile.BillingDetails")} 
          color={cardColors.billing}
          fields={[
            { 
              label: t("StudentProfile.fullName"), 
              value: localStudent?.billingName || `${localStudent?.firstName} ${localStudent?.lastName}`
            },
            { 
              label: t("StudentProfile.taxId"), 
              value: localStudent?.taxId || "123-456-78-90"
            },
            { 
              label: t("StudentProfile.street"), 
              value: localStudent?.billingAddress || localStudent?.Address?.street || ''
            },
            { 
              label: t("StudentProfile.city"), 
              value: localStudent?.billingCity || "Białystok"
            },
            { 
              label: t("StudentProfile.postalCode"), 
              value: localStudent?.billingPostalCode || "15-001"
            },
            { 
              label: t("StudentProfile.country"), 
              value: localStudent?.billingCountry || "Poland"
            }
          ]}
          onSave={handleSaveBilling}
        />

        {/* Security Settings */}
        <InfoCard 
          icon={Shield} 
          title={t("StudentProfile.SecuritySettings")} 
          color={cardColors.security}
          className="security-settings"
        >
          <div className="space-y-4">
            <button 
              onClick={() => setShowPasswordModal(true)}
              className="w-full px-4 py-2 text-sm bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors flex items-center gap-2"
            >
              <Lock className="w-4 h-4" />
              {t("StudentProfile.changePassword")}
            </button>
            <div className="flex items-center justify-between">
              {/* <div>
                <p className="text-sm font-medium">{t("StudentProfile.twoFactor")}</p>
                <p className="text-xs text-gray-500">{t("StudentProfile.twoFactorDesc")}</p>
              </div>
              <ToggleSwitch 
                checked={securitySettings.twoFactorEnabled}
                onChange={(enabled) => handleSaveSecuritySettings({ twoFactorEnabled: enabled })}
                color="bg-teal-500"
              /> */}
            </div>
          </div>
        </InfoCard>

        {/* Notification Preferences */}
        <InfoCard 
          icon={Bell} 
          title={t("StudentProfile.NotificationPreferences")} 
          color={cardColors.notifications}
          className="notifications-preferences"
        >
          <div className="space-y-4">
            {Object.entries(notificationPreferences).map(([type, enabled]) => (
              <div key={type} className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium">
                    {t(`${type}`)}
                  </p>
                  <p className="text-xs text-gray-500">
                    {t(`${type}`)}
                  </p>
                </div>
                <ToggleSwitch 
                  checked={enabled}
                  onChange={(newValue) => handleToggleNotification(type, newValue)}
                  color="bg-orange-500"
                />
              </div>
            ))}
          </div>
        </InfoCard>

        {/* Language & Timezone */}
<InfoCard 
  icon={Globe} 
  title={t("StudentProfile.LanguageAndTimezone")} 
  color={cardColors.language}
  fields={[
    { 
      label: t("common.Language"),
      value: currentLanguage,
      dropdown: {
        value: currentLanguage,
        options: languageOptions,
        onChange: handleChangeLanguage
      }
    }
  ]}
>
  <div className="mt-4 space-y-4">
  <div className="flex flex-col space-y-2">
  <label className="text-sm font-medium text-gray-700">
    {t("Profile.selectTimezone")}
  </label>
  <div className="w-full">
    <Select
      value={{ 
        value: currentTimezone,
        label: `(UTC${moment.tz(currentTimezone).format('Z')}) ${currentTimezone}`
      }}
      onChange={(option) => handleSaveTimezone({ [t("Profile.selectTimezone")]: option.value })}
      options={moment.tz.names().map(tz => ({
        value: tz,
        label: `(UTC${moment.tz(tz).format('Z')}) ${tz}`
      })).sort((a, b) => {
        const offsetA = moment.tz(a.value).format('Z');
        const offsetB = moment.tz(b.value).format('Z');
        return offsetA === offsetB 
          ? a.value.localeCompare(b.value) 
          : offsetA.localeCompare(offsetB);
      })}
      isSearchable={true}
      isClearable={false}
      placeholder="Search for a timezone..."
      classNamePrefix="timezone-select"
      styles={{
        control: (base) => ({
          ...base,
          borderColor: '#e5e7eb',
          '&:hover': {
            borderColor: '#6366f1'
          }
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected ? '#4f46e5' : state.isFocused ? '#eef2ff' : 'white',
          color: state.isSelected ? 'white' : '#111827',
          ':active': {
            backgroundColor: state.isSelected ? '#4f46e5' : '#e0e7ff'
          },
          padding: '8px 12px'
        }),
        input: (base) => ({
          ...base,
          color: '#111827'
        }),
        singleValue: (base) => ({
          ...base,
          color: '#111827'
        }),
        menu: (base) => ({
          ...base,
          zIndex: 50
        })
      }}
    />

    
  </div>
</div>
    <div className="flex items-center justify-between">
      <label className="text-sm font-medium text-gray-700">
        {t("StudentProfile.timeFormat")}
      </label>
      <div className="flex gap-3">
        <button
          onClick={() => handleSaveTimeSettings({ [t("Profile.timeFormat")]: '24-hour' })}
          className={`px-3 py-1 text-sm rounded-md transition-colors ${
            timeFormat === '24' ? 'bg-violet-100 text-violet-900' : 'hover:bg-gray-100'
          }`}
        >
          24h
        </button>
        <button
          onClick={() => handleSaveTimeSettings({ [t("Profile.timeFormat")]: '12-hour' })}
          className={`px-3 py-1 text-sm rounded-md transition-colors ${
            timeFormat === '12' ? 'bg-violet-100 text-violet-900' : 'hover:bg-gray-100'
          }`}
        >
          12h
        </button>
      </div>
    </div>
  </div>
</InfoCard>

{/* Account Information */}
<InfoCard 
          icon={Calendar} 
          title={t("StudentProfile.AccountInformation")} 
          color={cardColors.account}
          fields={[
            { 
              label: t("StudentProfile.creationDate"), 
              value: "2024-10-01", 
              editable: false 
            },
            { 
              label: t("StudentProfile.lastLogin"), 
              value: "2024-12-08, 09:59", 
              editable: false 
            }
          ]}
          showEdit={false}
        />
      </div>

      {/* Modals */}
      <ImageUploadModal
        isOpen={showImageUpload}
        onClose={() => setShowImageUpload(false)}
        onUpload={handleImageUpload}
      />

<Modal
  isOpen={showConfirmation}  // Language confirmation modal
  onClose={() => setShowConfirmation(false)}
  title={t("LanguageModal.title")}
  onConfirm={() => {
    i18n.changeLanguage(pendingLanguage.code);
    setCurrentLanguage(pendingLanguage.name);
    setShowConfirmation(false);
  }}
/>

<Modal
  isOpen={showPasswordModal}  // Password modal
  onClose={() => setShowPasswordModal(false)}
  title={t("StudentProfile.changePassword")}
/>

      {/* Tour Setup */}
      <Joyride
        steps={tourState.steps}
        run={tourState.run}
        continuous
        showSkipButton
        styles={{
          options: {
            primaryColor: '#4F46E5',
            zIndex: 1000,
          },
          buttonNext: {
            backgroundColor: '#4F46E5'
          },
          buttonBack: {
            color: '#4F46E5'
          }
        }}
        callback={(data) => {
          const { status, type } = data;
          if (status === 'finished' || status === 'skipped') {
            setTourState(prev => ({ ...prev, run: false }));
          }
        }}
      />
    </div>
  );
};

export default Profile;