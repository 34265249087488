// src/store/actions/evaluationActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

export const createEvaluation = createAsyncThunk(
  "evaluations/createEvaluation",
  async (evaluationData) => {
    try {
      const response = await axios.post(`evaluations`, evaluationData);
      return response.data.evaluation;
    } catch (error) {
      throw error.response?.data?.message || "Failed to create evaluation";
    }
  }
);

export const getAllEvaluations = createAsyncThunk(
  "evaluations/getAllEvaluations",
  async () => {
    try {
      const response = await axios.get(`evaluations`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || "Failed to fetch evaluations";
    }
  }
);

export const getStudentEvaluations = createAsyncThunk(
    "evaluations/getStudentEvaluations",
    async (studentId) => {
      try {
        const response = await axios.get(`evaluations/student/${studentId}`);
        // Return the full response data to match the API structure
        return response.data;
      } catch (error) {
        throw error.response?.data?.message || "Failed to fetch student evaluations";
      }
    }
  );

export const getTeacherEvaluations = createAsyncThunk(
  "evaluations/getTeacherEvaluations",
  async (teacherId) => {
    try {
      const response = await axios.get(`evaluations/teacher/${teacherId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || "Failed to fetch teacher evaluations";
    }
  }
);

export const getEvaluation = createAsyncThunk(
  "evaluations/getEvaluation",
  async (id) => {
    try {
      const response = await axios.get(`evaluations/${id}`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || "Failed to fetch evaluation";
    }
  }
);

export const updateEvaluation = createAsyncThunk(
  "evaluations/updateEvaluation",
  async ({ id, updatedData }) => {
    try {
      const response = await axios.put(`evaluations/${id}`, updatedData);
      return response.data.evaluation;
    } catch (error) {
      throw error.response?.data?.message || "Failed to update evaluation";
    }
  }
);

export const deleteEvaluation = createAsyncThunk(
  "evaluations/deleteEvaluation",
  async (id) => {
    try {
      const response = await axios.delete(`evaluations/${id}`);
      return { id, message: response.data.message };
    } catch (error) {
      throw error.response?.data?.message || "Failed to delete evaluation";
    }
  }
);

export const getEvaluationStats = createAsyncThunk(
    "evaluations/getEvaluationStats",
    async (studentId) => {
      try {
        const response = await axios.get(`evaluations/stats/${studentId}`);
        // Return the full response data to match the API structure
        return response.data;
      } catch (error) {
        throw error.response?.data?.message || "Failed to fetch evaluation stats";
      }
    }
  );