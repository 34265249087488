import React, { useEffect, useState } from "react";
import AdminNav from './AdminNav';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllstudents } from '../../store/actions/studentsActions';
import { GetTeachers } from '../../store/actions/teachersActions';
import { DownloadOutlined, MessageOutlined } from "@ant-design/icons";
import { fetchAllpayments } from '../../store/actions/paymentActions';
import { useWebSocket } from '../hooks/useWebSocket';
import ChatPopup from './ChatPopup';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { 
  Box, 
  Typography, 
  Grid, 
  Avatar, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Button,
} from '@mui/material';
import { 
  People as PeopleIcon, 
  School as SchoolIcon, 
  AttachMoney as AttachMoneyIcon,
  TrendingUp as TrendingUpIcon,
  AccessTime as AccessTimeIcon,
  SignalWifi4Bar as ConnectedIcon,
  SignalWifiOff as DisconnectedIcon,
} from '@mui/icons-material';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

// Custom theme based on the provided color scheme
const theme = createTheme({
  palette: {
    primary: {
      main: '#107314',
    },
    secondary: {
      main: '#0a5a0e',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const AdminDash = () => {
  const dispatch = useDispatch();
  const PaymentData = useSelector((state) => state.payments.Allpaymentlist);
  const [showTimezonePrompt, setShowTimezonePrompt] = useState(false);
  const [initialTimezone, setInitialTimezone] = useState('');
  const [searchInput, setSearchInput] = useState("");
  const [hasCheckedTimezone, setHasCheckedTimezone] = useState(false);
  const { isConnected } = useWebSocket();
  const timezone = localStorage.getItem('selectedTimezone') || 'America/New_York';
  const [t] = useTranslation("global");
  const students = useSelector((state) => state.students.studentslist);
  const teachers = useSelector((state) => state.teachers.Teacherslist); 

  useEffect(() => {
    dispatch(fetchAllstudents());
    dispatch(GetTeachers());
    dispatch(fetchAllpayments());
  
    const hasCheckedTimezoneThisSession = sessionStorage.getItem('hasCheckedTimezone');
    
    if (!hasCheckedTimezoneThisSession) {
      fetchTimezone();
    }
  }, [dispatch]);

  const currentUser = {
    id: "667fd808df2795ef057c93b5", 
    name: "admin",
    type: "admin"
};
  
  const chatUsers = React.useMemo(() => {
    const allUsers = [
      ...students.map(student => ({
        id: student._id,
        name: student.Username,
        email: student.Email,
        type: 'student',
        avatar: student.Profile_Image?.[0] ? 
          `https://ik.imagekit.io/8s3jwexmv/${student.Profile_Image[0]}` : 
          null
      })),
      ...teachers.map(teacher => ({
        id: teacher._id,
        name: teacher.Username,
        email: teacher.Email,
        type: 'teacher',
        avatar: teacher.Profile_Image?.[0] ? 
          `https://ik.imagekit.io/8s3jwexmv/${teacher.Profile_Image[0]}` : 
          null
      }))
    ];

    return allUsers;
  }, [students, teachers]);

  const fetchTimezone = async () => {
    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = ipResponse.data.ip;
  
      const timezoneResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=132a6bee31081e`);
      const detectedTimezone = timezoneResponse.data.timezone;
      setInitialTimezone(detectedTimezone);
  
      const savedTimezone = localStorage.getItem('AdminTimezone') || 'America/New_York';
      
      if (detectedTimezone !== savedTimezone) {
        setShowTimezonePrompt(true);
      } else {
        sessionStorage.setItem('hasCheckedTimezone', 'true');
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  

  const handleTimezoneChange = (newTimezone) => {
    localStorage.setItem('AdminTimezone', newTimezone);
    sessionStorage.setItem('hasCheckedTimezone', 'true');
    setShowTimezonePrompt(false);
  };

  const handleKeepCurrentTimezone = () => {
    sessionStorage.setItem('hasCheckedTimezone', 'true');
    setShowTimezonePrompt(false);
  };

  const totalIncome = PaymentData.reduce((total, payment) => {
    if (payment.Purchase_Amount && !isNaN(payment.Purchase_Amount)) {
        return total + payment.Purchase_Amount;
    }
    return total;
  }, 0);

  // Mock data for the area chart
  const revenueData = [
    { name: 'Jan', revenue: 4000 },
    { name: 'Feb', revenue: 3000 },
    { name: 'Mar', revenue: 5000 },
    { name: 'Apr', revenue: 4500 },
    { name: 'May', revenue: 6000 },
    { name: 'Jun', revenue: 5500 },
  ];

  // Mock data for the pie chart
  const courseData = [
    { name: 'Beginner', value: 400 },
    { name: 'Intermediate', value: 300 },
    { name: 'Advanced', value: 200 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <>
      <div className="Admin-Dash_main_style">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '80vw' }}>
            <AdminNav
              value={searchInput}
              setValue={setSearchInput}
              placeholder={t("AdminDash.searchPlaceholder")}
            />
            <Box component="main" sx={{ flexGrow: 1, p: 0.5, width: '100%', overflowX: 'hidden' }}>
              <Card sx={{ p: 3, borderRadius: 4, boxShadow: 3 }}>
                <Typography variant="h4" gutterBottom fontWeight="bold" color="primary" sx={{ mt: 2, mb: 4 }}>
                  {t("AdminDash.head")}
                </Typography>
                <Chip
            icon={isConnected ? <ConnectedIcon /> : <DisconnectedIcon />}
            label={isConnected ? "Connected" : "Disconnected"}
            color={isConnected ? "success" : "error"}
            variant="outlined"
            sx={{ ml: 2 }}
          />
                  
                  {/* <ChatPopup 
    currentUser={currentUser}
    availableUsers={chatUsers}
/> */}

{/* <Tooltip title="Chat Feature Coming Soon!" placement="left">
  <div style={{ 
    position: 'fixed', 
    right: '20px',  
    bottom: '20px', 
    zIndex: 1000 
  }}>
    <Button
      icon={<MessageOutlined style={{ fontSize: '20px' }}/>}
      shape="circle"
      size="large"
      disabled
      style={{
        opacity: 0.6,
        cursor: 'not-allowed'
      }}
    >
      <span style={{
        position: 'absolute',
        top: -2,
        right: -2,
        width: '10px',
        height: '10px',
        backgroundColor: '#1890ff',
        borderRadius: '50%',
        animation: 'pulse 2s infinite'
      }} />
    </Button>
  </div>
</Tooltip> */}
    
                
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6" color="text.secondary">Total Students</Typography>
                          <Avatar sx={{ bgcolor: 'primary.main' }}>
                            <PeopleIcon />
                          </Avatar>
                        </Box>
                        <Typography variant="h4" component="div" mt={2}>
                          {students.length}
                        </Typography>
                        <Typography variant="body2" color="success.main" mt={1}>
                          <TrendingUpIcon fontSize="small" /> +5% from last month
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={3}>
                    <Box>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6" color="text.secondary">Total Teachers</Typography>
                          <Avatar sx={{ bgcolor: 'secondary.main' }}>
                            <SchoolIcon />
                          </Avatar>
                        </Box>
                        <Typography variant="h4" component="div" mt={2}>
                          {teachers.length}
                        </Typography>
                        <Typography variant="body2" color="success.main" mt={1}>
                          <TrendingUpIcon fontSize="small" /> +2% from last month
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={3}>
                    <Box>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6" color="text.secondary">Total Revenue</Typography>
                          <Avatar sx={{ bgcolor: 'success.main' }}>
                            <AttachMoneyIcon />
                          </Avatar>
                        </Box>
                        <Typography variant="h4" component="div" mt={2}>
                          PLN {totalIncome.toFixed(2)}
                        </Typography>
                        <Typography variant="body2" color="success.main" mt={1}>
                          <TrendingUpIcon fontSize="small" /> +8% from last month
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={3}>
                    <Box>
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6" color="text.secondary">Course Completion</Typography>
                          <Avatar sx={{ bgcolor: 'info.main' }}>
                            <AccessTimeIcon />
                          </Avatar>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={75} />
                          </Box>
                          <Box minWidth={35}>
                            <Typography variant="body2" color="text.secondary">75%</Typography>
                          </Box>
                        </Box>
                        <Typography variant="body2" mt={1}>
                          345 of 460 students completed their courses
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
  
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={8}>
                    <Box>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>Revenue Overview</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart data={revenueData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="revenue" stroke="#107314" fill="#107314" fillOpacity={0.3} />
                          </AreaChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} md={4}>
                    <Box>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>Course Distribution</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={courseData}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {courseData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                        <Box display="flex" justifyContent="center" mt={2}>
                          {courseData.map((entry, index) => (
                            <Chip
                              key={entry.name}
                              label={entry.name}
                              sx={{
                                backgroundColor: COLORS[index % COLORS.length],
                                color: 'white',
                                margin: '0 4px',
                              }}
                            />
                          ))}
                        </Box>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
  
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>Recent Students</Typography>
                        <List>
                          {students?.slice(0, 5).map((student) => (
                            <ListItem key={student._id} disablePadding>
                              <ListItemAvatar>
                              <Avatar src={student.Profile_Image?.length > 0 ? `https://ik.imagekit.io/8s3jwexmv/${student.Profile_Image[0]}` : null}>

                                  {student?.Username.slice(0,1).toUpperCase()}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={student?.Username}
                                secondary={student?.Email}
                              />
                              <Chip label="New" color="primary" size="small" />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Box>
                  </Grid>
  
                  <Grid item xs={12} md={6}>
                    <Box>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>Recent Payments</Typography>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Student</TableCell>
                                <TableCell>Course</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {PaymentData.slice(0, 5).map((payment) => (
                                <TableRow key={payment._id}>
                                  <TableCell>{payment.student_name}</TableCell>
                                  <TableCell>{payment.course_name}</TableCell>
                                  <TableCell align="right">PLN {payment.Purchase_Amount}</TableCell>
                                  <TableCell>
                                    <Chip label="Completed" color="success" size="small" />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Box>
          
          {showTimezonePrompt && (
  <Box sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }}>
    <Card sx={{
      width: 400,
      padding: 3,
      borderRadius: 4,
    }}>
      <Typography variant="h6" gutterBottom>
        Timezone Update
      </Typography>
      <Typography variant="body1" paragraph>
        Your detected timezone ({initialTimezone}) is different from the saved timezone. Would you like to update it?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button onClick={() => handleTimezoneChange(initialTimezone)} color="primary" variant="contained" sx={{ mr: 1 }}>
          Yes, Update
        </Button>
        <Button onClick={handleKeepCurrentTimezone} color="secondary" variant="outlined">
          No, Keep Current
        </Button>
      </Box>
    </Card>
  </Box>
)}
        </ThemeProvider>
      </div>
    </>
  );
};

export default AdminDash;