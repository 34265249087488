import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

// Store Trial Booking Data
export const Add_booking_Trial_Form = createAsyncThunk(
  "trialBooking/add",
  async (bookingData, { rejectWithValue }) => {
    try {
      // Format the data according to the API requirements
      const formattedData = {
        studentDetails: {
          firstName: bookingData.studentDetails.firstName,
          lastName: bookingData.studentDetails.lastName,
          email: bookingData.studentDetails.email,
          phoneNumber: bookingData.studentDetails.phoneNumber
        },
        scheduledDate: bookingData.scheduledDate,
        timeSlot: {
          start: bookingData.timeSlot.start,
          end: bookingData.timeSlot.end
        },
        learningPreferences: {
          learningObjectives: bookingData.learningPreferences.learningObjectives.map(obj => ({
            skillType: obj.skillType,
            proficiencyLevel: obj.proficiencyLevel || 'beginner',
            priority: obj.priority || 'medium'
          })),
          sessionPreferences: {
            focusAreas: bookingData.learningPreferences.sessionPreferences.focusAreas || [],
            preferredTopics: bookingData.learningPreferences.sessionPreferences.preferredTopics || [],
            customTopics: bookingData.learningPreferences.sessionPreferences.customTopics || [],
            lessonStyle: bookingData.learningPreferences.sessionPreferences.lessonStyle || 'mixed',
            age: bookingData.learningPreferences.sessionPreferences.age
          }
        },
        teacherDetails: {
          teacherId: bookingData.teacherDetails.teacherId,
          packageId: bookingData.teacherDetails.packageId
        }
      };

      const response = await axios.post('store-trial-booking', formattedData);
      return response.data;
    } catch (error) {
      if (error.response?.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('Failed to create trial booking');
    }
  }
);

// New action creator in trialPreferencesActions.js
export const Get_Trial_Bookings_By_Package = createAsyncThunk(
    "trialBooking/getByPackage",
    async (bookingId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`trial-bookings/package/${bookingId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || 'Failed to fetch trial bookings for package'
        );
      }
    }
  );

// Get Trial Booking Details
export const Get_Trial_Booking = createAsyncThunk(
  "trialBooking/get",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/trial-booking/${bookingId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch trial booking');
    }
  }
);

// Cancel Trial Booking
export const Cancel_Trial_Booking = createAsyncThunk(
  "trialBooking/cancel",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/trial-booking/${bookingId}/cancel`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to cancel trial booking');
    }
  }
);

// Get Student Trial Bookings
export const Get_Student_Trial_Bookings = createAsyncThunk(
  "trialBooking/getStudentBookings",
  async (studentEmail, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/trial-bookings/student/${studentEmail}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch student trial bookings');
    }
  }
);

// Update Trial Booking Status
export const Update_Trial_Booking_Status = createAsyncThunk(
  "trialBooking/updateStatus",
  async ({ bookingId, status }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/trial-booking/${bookingId}/status`, { status });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update trial booking status');
    }
  }
);