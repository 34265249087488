// src/store/slices/evaluationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  createEvaluation,
  getAllEvaluations,
  getStudentEvaluations,
  getTeacherEvaluations,
  getEvaluation,
  updateEvaluation,
  deleteEvaluation,
  getEvaluationStats
} from "../actions/evaluationActions";
import { toast } from "react-toastify";

const initialState = {
  evaluations: [],
  singleEvaluation: null,
  stats: {
    averageScore: 0,
    totalEvaluations: 0,
    minScore: 0,
    maxScore: 0
  },
  loading: false,
  error: null
};

const evaluationSlice = createSlice({
  name: "evaluations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Evaluation
      .addCase(createEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEvaluation.fulfilled, (state, action) => {
        state.evaluations.unshift(action.payload);
        state.loading = false;
        toast.success("Evaluation created successfully");
      })
      .addCase(createEvaluation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to create evaluation");
      })

      // Get All Evaluations
      .addCase(getAllEvaluations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllEvaluations.fulfilled, (state, action) => {
        state.evaluations = action.payload?.evaluations || [];
        state.loading = false;
      })
      .addCase(getAllEvaluations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to fetch evaluations");
      })

      // Get Student Evaluations
      .addCase(getStudentEvaluations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStudentEvaluations.fulfilled, (state, action) => {
        state.evaluations = action.payload?.evaluations || [];
        state.loading = false;
      })
      .addCase(getStudentEvaluations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to fetch student evaluations");
      })

      // Get Teacher Evaluations
      .addCase(getTeacherEvaluations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeacherEvaluations.fulfilled, (state, action) => {
        state.evaluations = action.payload?.evaluations || [];
        state.loading = false;
      })
      .addCase(getTeacherEvaluations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to fetch teacher evaluations");
      })

      // Get Single Evaluation
      .addCase(getEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEvaluation.fulfilled, (state, action) => {
        state.singleEvaluation = action.payload?.evaluation || null;
        state.loading = false;
      })
      .addCase(getEvaluation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to fetch evaluation");
      })

      // Update Evaluation
      .addCase(updateEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEvaluation.fulfilled, (state, action) => {
        const index = state.evaluations.findIndex(
          (evaluation) => evaluation._id === action.payload._id
        );
        if (index !== -1) {
          state.evaluations[index] = action.payload;
        }
        state.singleEvaluation = action.payload;
        state.loading = false;
        toast.success("Evaluation updated successfully");
      })
      .addCase(updateEvaluation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to update evaluation");
      })

      // Delete Evaluation
      .addCase(deleteEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteEvaluation.fulfilled, (state, action) => {
        state.evaluations = state.evaluations.filter(
          (evaluation) => evaluation._id !== action.payload.id
        );
        state.loading = false;
        toast.success(action.payload.message || "Evaluation deleted successfully");
      })
      .addCase(deleteEvaluation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to delete evaluation");
      })

      // Get Evaluation Stats
      .addCase(getEvaluationStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEvaluationStats.fulfilled, (state, action) => {
        state.stats = action.payload?.stats || {
          averageScore: 0,
          totalEvaluations: 0,
          minScore: 0,
          maxScore: 0
        };
        state.loading = false;
      })
      .addCase(getEvaluationStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to fetch evaluation stats");
      });
  },
});

export default evaluationSlice.reducer;