import React, { useEffect, useRef } from 'react';

const ChatComponent = () => {
  const websocket = useRef(null);

  useEffect(() => {
    websocket.current = new WebSocket('ws://localhost:3000');

    websocket.current.onopen = () => {
      console.log('Connected to WebSocket');
    };

    websocket.current.onmessage = (event) => {
      console.log('Message from server:', event.data);
    };

    websocket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    websocket.current.onclose = () => {
      console.log('Disconnected from WebSocket');
    };

    return () => {
      if (websocket.current) {
        websocket.current.close();
      }
    };
  }, []);

  // return <div>Chat Component</div>;
};

export default ChatComponent;
