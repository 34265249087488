// PaymentStatus.jsx
import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle, RefreshCcw, AlertTriangle, FileText, X } from 'lucide-react';
import axios from '../../helpers/axiosconfig';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const PaymentStatus = () => {
  const { t } = useTranslation("global");
  const [orderId, setOrderId] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [status, setStatus] = useState('PENDING');
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  
  const maxRetries = 20; // 1 minute total checking time (3s * 20)
  const retryInterval = 3000; // 3 seconds

  // Initialize payment data and order ID
  useEffect(() => {
    const initializePaymentData = () => {
      // First try to get orderId from URL parameters
      const params = new URLSearchParams(location.search);
      const urlOrderId = params.get('orderId');

      // Then check localStorage
      const storedPaymentData = localStorage.getItem('pendingPaymentData');
      
      if (urlOrderId) {
        setOrderId(urlOrderId);
        console.log('Using URL Order ID:', urlOrderId);
        
        if (storedPaymentData) {
          try {
            const parsed = JSON.parse(storedPaymentData);
            setPaymentData(parsed);
          } catch (error) {
            console.error('Error parsing stored payment data:', error);
          }
        }
      } else if (storedPaymentData) {
        try {
          const parsed = JSON.parse(storedPaymentData);
          setPaymentData(parsed);
          
          // Try to get PayU orderId first, fall back to extOrderId
          const payuOrderId = parsed.OrderID || parsed.orderId;
          if (payuOrderId) {
            setOrderId(payuOrderId);
            console.log('Using stored Order ID:', payuOrderId);
          } else {
            setError('No valid order ID found');
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error parsing payment data:', error);
          setError('Invalid payment data');
          setIsLoading(false);
        }
      } else {
        setError('No payment data found');
        setIsLoading(false);
      }
    };

    initializePaymentData();
  }, [location]);

  // Check payment status
  const checkPaymentStatus = async (checkOrderId) => {
    if (!checkOrderId) return false;

    try {
      const response = await axios.get(`authorizePayment/${checkOrderId}`);
      const responseStatus = response.data?.status?.toUpperCase() || 'PENDING';
      
      console.log('Payment status response:', response.data);
      setStatus(responseStatus);
      setIsLoading(false);

      // Consider these statuses as final
      if (['COMPLETED', 'FAILED', 'CANCELED'].includes(responseStatus)) {
        if (responseStatus === 'COMPLETED') {
          // Clean up localStorage for completed payments
          localStorage.removeItem('pendingPaymentData');
        }
        return true;
      }
      return false;
    } catch (error) {
      console.error('Payment status check error:', error);
      setError(error.response?.data?.message || 'Failed to check payment status');
      setIsLoading(false);
      return false;
    }
  };

  // Poll for status updates
  useEffect(() => {
    let intervalId;

    const pollStatus = async () => {
      if (orderId && retryCount < maxRetries && status === 'PENDING') {
        const isStatusFinal = await checkPaymentStatus(orderId);
        
        if (isStatusFinal) {
          if (intervalId) {
            clearInterval(intervalId);
          }
          if (status === 'COMPLETED') {
            // Notify parent window of successful payment
            if (window.opener) {
              window.opener.postMessage({ paymentStatus: 'success' }, '*');
              // Give the message time to be received before closing
              setTimeout(() => window.close(), 500);
            }
          }
        } else {
          setRetryCount(prev => prev + 1);
        }
      } else if (retryCount >= maxRetries) {
        clearInterval(intervalId);
        setError('Payment status check timed out. Please contact support.');
      }
    };

    if (orderId && status === 'PENDING') {
      // Initial check
      pollStatus();
      // Set up interval for subsequent checks
      intervalId = setInterval(pollStatus, retryInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [orderId, retryCount, status, maxRetries]);

  const handleCloseWindow = () => {
    // Notify parent window before closing if payment was successful
    if (status === 'COMPLETED' && window.opener) {
      window.opener.postMessage({ paymentStatus: 'success' }, '*');
      // Give the message time to be received before closing
      setTimeout(() => window.close(), 500);
    } else {
      window.close();
    }
  };

  const handleViewInvoice = () => {
    // Notify parent window to handle invoice viewing
    if (window.opener) {
      window.opener.postMessage({ 
        action: 'viewInvoice',
        orderId: orderId 
      }, '*');
      // Give the message time to be received before closing
      setTimeout(() => window.close(), 500);
    }
  };

  // Status Alert Component
  const StatusAlert = ({ status, error }) => {
    const alertStyles = {
      COMPLETED: {
        wrapper: "bg-green-50 border-green-200 border rounded-lg p-4",
        icon: "text-green-600",
        title: "text-green-800",
        description: "text-green-700"
      },
      CANCELED: {
        wrapper: "bg-yellow-50 border-yellow-200 border rounded-lg p-4",
        icon: "text-yellow-600",
        title: "text-yellow-800",
        description: "text-yellow-700"
      },
      FAILED: {
        wrapper: "bg-red-50 border-red-200 border rounded-lg p-4",
        icon: "text-red-600",
        title: "text-red-800",
        description: "text-red-700"
      },
      PENDING: {
        wrapper: "bg-blue-50 border-blue-200 border rounded-lg p-4",
        icon: "text-blue-600",
        title: "text-blue-800",
        description: "text-blue-700"
      },
      ERROR: {
        wrapper: "bg-red-50 border-red-200 border rounded-lg p-4",
        icon: "text-red-600",
        title: "text-red-800",
        description: "text-red-700"
      }
    };

    const style = alertStyles[status] || alertStyles.ERROR;

    const renderContent = () => {
      switch (status) {
        case 'COMPLETED':
          return {
            Icon: CheckCircle,
            title: t("PaymentStatus.PaymentSuccessful"),
            description: t("PaymentStatus.PaymentSuccessDescription")
          };
        case 'CANCELED':
          return {
            Icon: XCircle,
            title: t("PaymentStatus.PaymentCanceled"),
            description: t("PaymentStatus.PaymentCanceledDescription")
          };
        case 'FAILED':
          return {
            Icon: XCircle,
            title: t("PaymentStatus.PaymentFailed"),
            description: error || t("PaymentStatus.PaymentFailedDescription")
          };
        case 'PENDING':
          return {
            Icon: RefreshCcw,
            title: t("PaymentStatus.ProcessingPayment"),
            description: t("PaymentStatus.ProcessingPaymentDescription")
          };
        default:
          return {
            Icon: AlertTriangle,
            title: t("PaymentStatus.StatusUnknown"),
            description: t("PaymentStatus.StatusUnknownDescription")
          };
      }
    };

    const { Icon, title, description } = renderContent();

    return (
      <div className={style.wrapper}>
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Icon 
              className={`h-5 w-5 ${style.icon} ${
                status === 'PENDING' ? 'animate-spin' : ''
              }`} 
            />
          </div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium ${style.title}`}>{title}</h3>
            <div className={`mt-2 text-sm ${style.description}`}>
              {description}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Payment Details Component
  const PaymentDetails = ({ paymentData }) => {
    if (!paymentData) return null;

    const details = [
      { label: t("PaymentStatus.OrderID"), value: paymentData.OrderID || paymentData.orderId },
      { label: t("PaymentStatus.Package"), value: paymentData.packageName },
      { label: t("PaymentStatus.Amount"), value: paymentData.totalAmount ? `${paymentData.totalAmount} PLN` : null },
      { label: t("PaymentStatus.Student"), value: paymentData.studentName },
      { label: t("PaymentStatus.Email"), value: paymentData.studentEmail }
    ].filter(detail => detail.value);

    return (
      <div className="mt-6 bg-white rounded-lg border border-gray-200 p-4">
        <h3 className="font-semibold text-gray-900 mb-4">{t("PaymentStatus.PaymentDetails")}</h3>
        <div className="space-y-3">
          {details.map(({ label, value }) => (
            <div key={label} className="flex justify-between items-center">
              <span className="text-gray-600">{label}:</span>
              <span className="font-medium">{value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-md mx-auto w-full px-4 sm:px-0">
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 relative">
          <button
            onClick={handleCloseWindow}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
            aria-label={t("PaymentStatus.CloseWindow")}
          >
            <X className="h-5 w-5" />
          </button>

          <div className="text-center mb-8">
            <h2 className="text-xl font-bold text-gray-900">{t("PaymentStatus.Title")}</h2>
            <p className="text-gray-600 mt-1">
              {t("PaymentStatus.OrderIDLabel")}: {orderId || t("PaymentStatus.NotAvailable")}
            </p>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center p-4">
              <RefreshCcw className="h-6 w-6 text-blue-600 animate-spin" />
              <span className="ml-2 text-gray-600">{t("PaymentStatus.LoadingStatus")}</span>
            </div>
          ) : error ? (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <div className="flex items-start">
                <AlertTriangle className="h-5 w-5 text-red-600 flex-shrink-0" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{t("PaymentStatus.Error")}</h3>
                  <div className="mt-2 text-sm text-red-700">{error}</div>
                </div>
              </div>
            </div>
          ) : (
            <StatusAlert status={status} error={error} />
          )}

          <PaymentDetails paymentData={paymentData} />

          {status !== 'PENDING' && (
            <div className="mt-6 space-y-3">
              {status === 'COMPLETED' && (
                <button
                  onClick={handleViewInvoice}
                  className="w-full flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                >
                  <FileText className="mr-2 h-4 w-4" />
                  {t("PaymentStatus.ViewInvoice")}
                </button>
              )}
              <button
                onClick={handleCloseWindow}
                className="w-full flex items-center justify-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
              >
                <X className="mr-2 h-4 w-4" />
                {t("PaymentStatus.CloseWindow")}
              </button>
            </div>
          )}

          {status === 'PENDING' && retryCount > 0 && (
            <div className="mt-6 text-xs text-gray-500 text-center">
              <p>
                {t("PaymentStatus.CheckingStatus", { 
                  current: retryCount, 
                  max: maxRetries 
                })}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;