import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";
import { websocketConnected, websocketMessageReceived, websocketDisconnected } from '../slices/teacherSlice';


export const fetch5Teachers = createAsyncThunk(
  "teacher/fetch5Teachers",
  async () => {
    const response = await axios.get(`Fetch5Teachers`);
    console.log(response)
    return response.data.teacherslist;
  }
);

export const fetchTeacherDetails = createAsyncThunk(
  "teacher/fetchTeacherDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`fetchTeacherDetails/${id}`);
      return response.data.teachersDetails;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTeacherDetailsWithoutSlot = createAsyncThunk(
  "teacher/fetchTeacherDetailsWithoutSlot",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`fetchTeacherDetailswithoutSlot/${id}`);
      return response.data.teachersDetails;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetch1teacher = createAsyncThunk(
  "teacher/fetch1teacher",
  async () => {
    const response = await axios.get(`Fetch1teacher`);
    console.log(response)
    return response.data.TeacherDetails;
  }
);

export const GetTeachers = createAsyncThunk("teacher/GetTeachers", async () => {
  try {
    const response = await axios.get(`getteachers`);
    console.log(response)
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const imageUpload = createAsyncThunk(
  "property/imageUpload",
  async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    const response = await axios.post(`update-image`, formData);
    // console.log(response);
    // console.log(response.data.data)
    return response.data.filename;
  }
);

export const videoUpload = createAsyncThunk(
  "teacher/videoUpload",
  async (video) => {
    const formData = new FormData();
    formData.append("video", video);
    try {
      const response = await axios.post(`upload-video`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Video upload response:", response);
      return response.data.filename;
    } catch (error) {
      console.error("Error uploading video:", error);
      throw error;
    }
  }
);


export const getVideo = createAsyncThunk(
  "teacher/getVideo",
  async (filename, { rejectWithValue }) => {
    try {
      const response = await axios.get(`video/${filename}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Download Progress: ${percentCompleted}%`);
        }
      });
      
      if (response.data.size === 0) {
        throw new Error('Received empty blob');
      }
      
      const url = URL.createObjectURL(response.data);
      console.log('Video URL created:', url);
      
      return url;
    } catch (error) {
      console.error('Error in getVideo:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return rejectWithValue(`Server error: ${error.response.status}`);
      } else if (error.request) {
        // The request was made but no response was received
        return rejectWithValue('No response received from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        return rejectWithValue(`Error: ${error.message}`);
      }
    }
  }
);

export const AddNewTeacher = createAsyncThunk(
  "property/AddNewTeacher",
  async (formData) => {
    try {
      const response = await axios.post(`Signup_Teacher`, formData);
      // console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const changeTeacherPassword = createAsyncThunk(
  "teacher/changePassword",
  async ({ teacherId, currentPassword, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`change-password`, {
        teacherId,
        currentPassword,
        newPassword
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteTeacher = createAsyncThunk(
  "teacher/deleteTeacher",
  async (teacher_ID) => {
    try {
      const response = await axios.get(`Delete_teacher/${teacher_ID}`);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateTeacher = createAsyncThunk(
  "teacher/updateTeacher",
  async ({ teacherId, updatedData }) => {
    console.log(updatedData, "updatedData");
    try {
      const response = await axios.post(
        `Update_Teacher/${teacherId}`,
        updatedData
      );
      console.log(response)
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const Update_Teacher_By_Availability = createAsyncThunk(
  "teacher/Update_Teacher_By_Availability",
  async ({ teacherId, updatedData }) => {
    try {
      const response = await axios.post(
        `Update_Teacher_By_Availability/${teacherId}`,
        updatedData
      );
      // console.log(response)
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const Signup_Teacher_By_Admin = createAsyncThunk(
  "property/Signup_Teacher_By_Admin",
  async (formData) => {
    // console.log(formData);
    try {
      const response = await axios.post(`Signup_Teacher_By_Admin`, formData);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);




export const GetExistingTeacher_Availability = createAsyncThunk(
  'teacher/GetExistingTeacher_Availability',
  async (teacherId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`GetExistingTeacher_Availability/${teacherId}`);
      return response.data; // Return the entire response data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const Create_CutsomPackage = createAsyncThunk(
  "teacher/Create_CutsomPackage",
  async (formData) => {
    // console.log(formData);
    try {
      const response = await axios.post(`Create_CutsomPackage`,formData);
      // console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);



export const SearchTeacherbyUsername = createAsyncThunk(
  "teacher/SearchTeacherbyUsername",
  async (input) => {
    // console.log(input);
    try {
      const response = await axios.post(`SearchTeacherbyUsername/${input}`);
      // console.log(response);
      return response;
    } catch (err) {
      console.log(err);
      return err.response.data
    }
  }
);


export const fetchTeacherBookings = createAsyncThunk(
  "teacher/fetchTeacherBookings",
  async (id) => {
    console.log(id);
    try {
      const response = await axios.post(`fetchTeacherBookings/${id}`);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
      return err.response.data;
    }
  }
);

export const uploadFileToDrive = createAsyncThunk(
  'drive/uploadFile',
  async ({ file, folderId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folderId', folderId);

      const response = await axios.post('/upload', formData);
      return response.data;
    } catch (error) {
      console.error('Upload error:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const listDriveFiles = createAsyncThunk(
  'drive/listFiles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('list');
      console.log('Drive files fetched:', response.data);
      return response.data; // Ensure this matches the structure expected by the reducer
    } catch (error) {
      console.error('Error fetching drive files:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteDriveFile = createAsyncThunk(
  "teacher/deleteDriveFile",
  async (fileId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`delete-drive-file/${fileId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadToLocal = createAsyncThunk(
  'teacher/uploadToLocal',
  async ({ formData, bookingId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { bookingId }
      });
      return response.data.file;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const listLocalFiles = createAsyncThunk(
  'teacher/listLocalFiles',
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/list?bookingId=${bookingId}`);
      return response.data.files; // Return the array of files
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteLocalFile = createAsyncThunk(
  'teacher/deleteLocalFile',
  async ({ bookingId, fileName }, { rejectWithValue }) => {
    try {
      await axios.delete(`/delete/${bookingId}/${fileName}`);
      return fileName;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const downloadLocalFile = createAsyncThunk(
  'teacher/downloadLocalFile',
  async ({ bookingId, fileName }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/download/${bookingId}/${fileName}`, {
        responseType: 'blob', // Important for file downloads
      });
      
      // Create a blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      
      // Append to html link element page
      document.body.appendChild(link);
      
      // Start download
      link.click();
      
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      
      return fileName; // Return fileName for potential use in the reducer
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const downloadMultipleFiles = createAsyncThunk(
  'teacher/downloadMultipleFiles',
  async ({ bookingId, fileNames }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/download-multiple/${bookingId}`, { fileNames }, { 
        responseType: 'blob',
        timeout: 30000 // 30 seconds timeout
      });
      
      const contentType = response.headers['content-type'];
      if (contentType === 'application/json') {
        // If the response is JSON, it's likely an error message
        const reader = new FileReader();
        reader.onload = () => {
          const errorMessage = JSON.parse(reader.result);
          throw new Error(errorMessage.message || 'Unknown error occurred');
        };
        reader.readAsText(response.data);
      } else {
        // It's a file, proceed with download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${bookingId}_files.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      
      return fileNames;
    } catch (error) {
      console.error('Error downloading files:', error);
      return rejectWithValue(error.message || 'Failed to download files');
    }
  }
);


export const connectWebSocket = createAsyncThunk(
  'teacher/connectWebSocket',
  async (_, { dispatch }) => {
    const socket = new WebSocket('ws://localhost:3000'); // Replace with your WebSocket server URL

    socket.onopen = () => {
      console.log('WebSocket connected');
      dispatch(websocketConnected());
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      dispatch(websocketMessageReceived(data));
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      dispatch(websocketDisconnected());
    };

    return socket;
  }
);

// WebSocket send message action
export const sendWebSocketMessage = createAsyncThunk(
  'teacher/sendWebSocketMessage',
  async (message, { getState }) => {
    const { socket } = getState().teacher;
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      throw new Error('WebSocket is not connected');
    }
  }
);

export const joinRoom = createAsyncThunk(
  "teacher/joinRoom",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`teacher-joined/${bookingId}`);
      return { bookingId, response: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const leaveRoom = createAsyncThunk(
  "teacher/leaveRoom",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`teacher-left/${bookingId}`);
      return { bookingId, response: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTeacherDetails = createAsyncThunk(
  "teacher/updateTeacherDetails",
  async ({ teacherId, updatedData }) => {
    try {
      const response = await axios.post(
        `update-teacher-details/${teacherId}`,
        updatedData
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error updating teacher details:", error);
      throw error;
    }
  }
);
