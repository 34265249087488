// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import studentsSlice from './slices/studentsSlice'
import teacherSlice from './slices/teacherSlice'
import coursesSlice from "./slices/coursesSlice";
import enquirySlice from "./slices/enquirySlice";
import packagesSlice from "./slices/packagesSlice";
import paymentSlice from "./slices/paymentSlice";
import bookingSlice from "./slices/bookingSlice";
import notificationSlice from "./slices/notificationSlice";
import walletSlice from "./slices/walletSlice"; 
import meetingReducer from './slices/meetingSlice';
import evaluationSlice from './slices/evaluationSlice';
import trialBookingReducer from './slices/trialPreferencesSlice';


export const store = configureStore({
  reducer: {
    students: studentsSlice,
    teachers: teacherSlice,
    courses: coursesSlice,
    enquirys: enquirySlice,
    packages: packagesSlice,
    payments: paymentSlice,
    bookings: bookingSlice,
    trialBooking: trialBookingReducer,
    notifications: notificationSlice,
    wallet: walletSlice, 
    meeting: meetingReducer,
    evaluations: evaluationSlice, // Added evaluations reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'bookings/getbookings',
          'bookings/getbookings/fulfilled',
          'bookings/Add_booking/fulfilled',
          'bookings/Updatebooking/fulfilled',
          'bookings/GetBookingsByStudentID/fulfilled',
          'bookings/GetBookingsByTeacherID/fulfilled',
          // Add evaluation-related actions if needed
          'evaluations/getStudentEvaluations/fulfilled',
          'evaluations/getEvaluationStats/fulfilled'
        ],
        ignoredPaths: [
          'bookings.Allbookinglist',
          'bookings.Teacher_Bookings',
          'bookings.StudentID_Booking',
          'bookings.existingBookings',
          // Add evaluation-related paths if needed
          'evaluations.evaluations',
          'evaluations.stats'
        ],
        warnAfter: 800
      },
      immutableCheck: { warnAfter: 800 }
    })
});

export default store;