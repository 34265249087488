import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Add_booking_Trail } from '../store/actions/bookingActions';
import { toast } from 'react-toastify';
import Select from 'react-select';
import SmallCalendar from './SmallCalendar';
import { 
  X,
  Check,
  Calendar,
  Clock,
  Phone,
  Mail,
  User,
  ArrowRight,
  Globe,
} from 'lucide-react';
import { 
    Add_booking_Trial_Form
  } from '../store/actions/trialPreferencesActions';


  const LearningPreferencesStep = ({ 
    onNext, 
    t,
    learningObjectives,
    setLearningObjectives,
    sessionPreferences,
    setSessionPreferences,
    email // Add email prop
  }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [focusAreas, setFocusAreas] = useState(sessionPreferences?.focusAreas || []);
    const [selectedTopics, setSelectedTopics] = useState(sessionPreferences?.preferredTopics || []);
    const [customTopics, setCustomTopics] = useState(sessionPreferences?.customTopics || []);
    const [selectedSkills, setSelectedSkills] = useState(learningObjectives || []);
    const [selectedAge, setSelectedAge] = useState(sessionPreferences?.age || null);
  
  
  
    const ageRanges = [
      { value: 5, label: '5-12 years' },
      { value: 13, label: '13-17 years' },
      { value: 18, label: '18-25 years' },
      { value: 26, label: '26-40 years' },
      { value: 41, label: '41+ years' }
    ];
  
    const skillOptions = [
      { type: 'speaking', label: t('learningPreferences.speaking') },
      { type: 'listening', label: t('learningPreferences.listening') },
      { type: 'reading', label: t('learningPreferences.reading') },
      { type: 'writing', label: t('learningPreferences.writing') },
      { type: 'grammar', label: t('learningPreferences.grammar') },
      { type: 'vocabulary', label: t('learningPreferences.vocabulary') },
      { type: 'pronunciation', label: t('learningPreferences.pronunciation') }
    ];
    
    const focusAreaOptions = [
      { value: 'casual_conversation', label: t('learningPreferences.casual_conversation') },
      { value: 'business_english', label: t('learningPreferences.business_english') },
      { value: 'academic_english', label: t('learningPreferences.academic_english') },
      { value: 'test_preparation', label: t('learningPreferences.test_preparation') },
      { value: 'presentation_skills', label: t('learningPreferences.presentation_skills') },
      { value: 'interview_preparation', label: t('learningPreferences.interview_preparation') }
    ];
    
    const topicOptions = [
      { value: 'culture', label: t('learningPreferences.culture') },
      { value: 'business', label: t('learningPreferences.business') },
      { value: 'technology', label: t('learningPreferences.technology') },
      { value: 'arts', label: t('learningPreferences.arts') },
      { value: 'current_events', label: t('learningPreferences.current_events') },
      { value: 'travel', label: t('learningPreferences.travel') }
    ];
  
    const handleSkillToggle = (skillType) => {
      setSelectedSkills(prev => {
        const exists = prev.find(s => s.skillType === skillType);
        if (exists) {
          return prev.filter(s => s.skillType !== skillType);
        } else {
          return [...prev, {
            skillType,
            proficiencyLevel: 'beginner',
            priority: 'medium'
          }];
        }
      });
    };
  
    const handleAgeSelect = (age) => {
        setSelectedAge(age);
        setSessionPreferences(prev => ({
          ...prev,
          age // Make sure age is set in sessionPreferences
        }));
      };
  
      const handleSubmit = async () => {
        try {
          setIsLoading(true);
          const preferences = {
            focusAreas,
            preferredTopics: [...selectedTopics],
            customTopics,
            age: selectedAge,
            lessonStyle: 'mixed'
          };
          setSessionPreferences(preferences);
          setLearningObjectives(selectedSkills);
          onNext();
        } catch (error) {
          console.error('Error updating preferences:', error);
          toast.error('Failed to update preferences');
        } finally {
          setIsLoading(false);
        }
    };
  
    
  
    return (
      <div className="p-6 space-y-4 max-h-[calc(100vh-250px)] overflow-y-auto custom-scrollbar">
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-3">What would you like to improve?</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {skillOptions.map(skill => (
              <button
                key={skill.type}
                onClick={() => handleSkillToggle(skill.type)}
                disabled={isLoading  }
                className={`p-2 rounded-lg border-2 transition-all text-sm ${
                  selectedSkills.some(s => s.skillType === skill.type)
                    ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                    : 'border-gray-200 hover:border-indigo-300'
                } ${(isLoading  ) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {skill.label}
              </button>
            ))}
          </div>
        </div>
  
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-3">Your Age Range</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {ageRanges.map(range => (
              <button
                key={range.value}
                onClick={() => handleAgeSelect(range.value)}
                disabled={isLoading  }
                className={`p-2 rounded-lg border-2 transition-all text-sm ${
                  selectedAge === range.value
                    ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                    : 'border-gray-200 hover:border-indigo-300'
                } ${(isLoading  ) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
  
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-3">Lesson Focus Areas</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {focusAreaOptions.map(area => (
              <button
                key={area.value}
                onClick={() => {
                  setFocusAreas(prev => 
                    prev.includes(area.value) 
                      ? prev.filter(a => a !== area.value)
                      : [...prev, area.value]
                  );
                }}
                disabled={isLoading  }
                className={`p-3 rounded-lg border-2 transition-all ${
                  focusAreas.includes(area.value)
                    ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                    : 'border-gray-200 hover:border-indigo-300'
                } ${(isLoading  ) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {area.label}
              </button>
            ))}
          </div>
        </div>
  
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-3">Topics You'd Like to Discuss</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {topicOptions.map(topic => (
              <button
                key={topic.value}
                onClick={() => {
                  setSelectedTopics(prev => 
                    prev.includes(topic.value)
                      ? prev.filter(t => t !== topic.value)
                      : [...prev, topic.value]
                  );
                }}
                disabled={isLoading  }
                className={`p-3 rounded-lg border-2 transition-all ${
                  selectedTopics.includes(topic.value)
                    ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                    : 'border-gray-200 hover:border-indigo-300'
                } ${(isLoading  ) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {topic.label}
              </button>
            ))}
          </div>
        </div>
  
        <div>
          <h3 className="text-xl font-semibold mb-4">Any Specific Topics or Goals?</h3>
          <textarea
            value={customTopics.join('\n')}
            onChange={(e) => setCustomTopics(e.target.value.split('\n').filter(t => t.trim()))}
            placeholder="Enter your specific topics or goals (one per line)"
            disabled={isLoading  }
            className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 h-32"
          />
        </div>
  
        <button
          onClick={handleSubmit}
          disabled={isLoading  }
          className="w-full flex items-center justify-center gap-2 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 transition-colors disabled:opacity-50"
        >
          {(isLoading  ) ? 'Saving...' : 'Continue'}
          <ArrowRight className="w-5 h-5" />
        </button>
      </div>
    );
  };

  const validateEmail = (email) => {
    // RFC 5322 compliant email regex
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  };
  
  

  const BookingModal = ({ 
    isOpen, 
  onClose, 
  teacher, 
  transformedAvailability,
  hourFormat: initialHourFormat, // Rename to avoid confusion
  t,
  packageId
  }) => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [bookingId, setBookingId] = useState(null);
    const [emailError, setEmailError] = useState("");
    // Form states
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [hourFormat, setHourFormat] = useState(initialHourFormat);
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [savedOtp, setSavedOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [learningObjectives, setLearningObjectives] = useState([]);
    const [errors, setErrors] = useState({});
    const [sessionPreferences, setSessionPreferences] = useState({
        focusAreas: [],
        preferredTopics: [],
        customTopics: [],
        lessonStyle: 'mixed',
        age: null // Initialize age
      });
  
    // With these safer versions:
const bookingLoading = useSelector((state) => state.bookings?.loading ?? false);
const bookingError = useSelector((state) => state.bookings?.error ?? null);
const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  
  // Create timezone options
  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setSelectedTimezone(timezone);
    localStorage.setItem('selectedTimezone', timezone);
  };

  useEffect(() => {
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      setSelectedTimezone(storedTimezone);
    }
  }, []);
  const handleHourFormatChange = (event) => {
    const format = event.target.value;
    setHourFormat(format);
    localStorage.setItem('hourFormat', format);
  };


const handleEmailChange = (e) => {
  const newEmail = e.target.value;
  setEmail(newEmail);
  
  if (newEmail && !validateEmail(newEmail)) {
    setEmailError("Please enter a valid email address");
  } else {
    setEmailError("");
  }
};
  
    useEffect(() => {
      if (selectedDate) {
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
        const slots = transformedAvailability[formattedDate]?.slots || [];
        setTimeSlots(slots);
      }
    }, [selectedDate, transformedAvailability]);
  
    useEffect(() => {
      if (bookingError) {
        toast.error(bookingError);
      }
      
    }, [bookingError]);
  
    const handleDateSelect = (date) => {
      setSelectedDate(date);
      setSelectedSlot(null);
      setCurrentStep(2);
    };
  
    const handleSlotSelect = (slot) => {
      setSelectedSlot(slot);
      setCurrentStep(3);
    };
  
    const handleSendOtp = async () => {
      if (!validateEmail(email)) {
        setEmailError(t("About.invalidEmail"));
        return;
      }
    
      try {
        setIsLoading(true);
        const response = await fetch("https://speakable.online/api/sendOtpEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        });
        const data = await response.json();
        
        if (response.ok) {
          setIsOtpSent(true);
          setSavedOtp(data.otp.toString());
          toast.success(t("About.otpSentSuccess"));
        } else {
          toast.error(t("About.otpSendFailed")); 
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(t("About.otpSendError"));
      } finally {
        setIsLoading(false);
      }
    };
    
    const handleVerifyOtp = () => {
      if (otp === savedOtp) {
        setVerifiedOtp(true);
        toast.success(t("About.otpVerified"));
      } else {
        toast.error(t("About.otpVerificationFailed")); 
        setErrors(prevErrors => ({...prevErrors, otp: t("About.invalidOtp")}));
      }
    };
  
    
  
    const handleConfirm = async () => {
      if (!firstName || !lastName || !email || !mobile || !verifiedOtp) {
        toast.error("Please fill in all required fields and verify your email");
        return;
      }
  
      if (!validateEmail(email)) {
        setEmailError("Please enter a valid email address");
        return;
      }
      
        try {
          setIsLoading(true);
      
          // First API call data
          const formData = {
            Teacher_ID: teacher._id,
            Package_ID: null,
            Status: "Trail",
            Scheduled_Dates: {
              [moment(selectedDate).format('YYYY-MM-DD')]: [{
                start: selectedSlot.start,
                end: selectedSlot.end
              }]
            },
            StudentDetails: {
              firstName,
              lastName,
              email,
              phoneNumber: mobile
            },
            LearningPreferences: {
              learningObjectives: learningObjectives.map(obj => ({
                skillType: obj.skillType,
                proficiencyLevel: obj.proficiencyLevel || 'beginner',
                priority: obj.priority || 'medium'
              })),
              sessionPreferences: {
                focusAreas: sessionPreferences?.focusAreas || [],
                preferredTopics: sessionPreferences?.preferredTopics || [],
                customTopics: sessionPreferences?.customTopics || [],
                lessonStyle: sessionPreferences?.lessonStyle || 'mixed',
                age: sessionPreferences?.age
              }
            }
          };
      
          // Get response from first API call
          const oldResult = await dispatch(Add_booking_Trail(formData)).unwrap();
          
          console.log('First API Response:', oldResult); // Debug log
    
          // Extract booking ID with more robust error handling
          let newBookingId;
          
          if (oldResult.bookings?.[0]?._id) {
            // If response has bookings array
            newBookingId = oldResult.bookings[0]._id.toString();
          } else if (oldResult._id) {
            // If response has direct _id
            newBookingId = oldResult._id.toString();
          } else {
            console.error('Unexpected API response structure:', oldResult);
            throw new Error("Could not find booking ID in response");
          }
    
          console.log('Extracted Booking ID:', newBookingId); // Debug log
      
          // Use the received booking ID for the second API call
          const completeBookingData = {
            bookingId: newBookingId,
            studentDetails: {
              firstName,
              lastName,
              email,
              phoneNumber: mobile
            },
            scheduledDate: moment(selectedDate).format('YYYY-MM-DD'),
            timeSlot: {
              start: selectedSlot.start,
              end: selectedSlot.end
            },
            learningPreferences: {
              learningObjectives: learningObjectives.map(obj => ({
                skillType: obj.skillType,
                proficiencyLevel: obj.proficiencyLevel || 'beginner',
                priority: obj.priority || 'medium'
              })),
              sessionPreferences: {
                focusAreas: sessionPreferences?.focusAreas || [],
                preferredTopics: sessionPreferences?.preferredTopics || [],
                customTopics: sessionPreferences?.customTopics || [],
                lessonStyle: sessionPreferences?.lessonStyle || 'mixed',
                age: sessionPreferences?.age
              }
            },
            teacherDetails: {
              teacherId: teacher._id,
              packageId: newBookingId
            }
          };
    
          console.log('Second API Request Data:', completeBookingData); // Debug log
      
          // Call second API
          await dispatch(Add_booking_Trial_Form(completeBookingData)).unwrap();
      
          // Set booking ID and update UI
          setBookingId(newBookingId);
          setCurrentStep(6);
          toast.success('Booking confirmed successfully!');
      
        } catch (error) {
          console.error("Error booking trial class:", error);
          if (error.response?.data) {
            console.error("API Error Response:", error.response.data); // Debug log
          }
          toast.error(error?.response?.data?.message || error.message || "Failed to book trial class");
        } finally {
          setIsLoading(false);
        }
    };
  
    if (!isOpen) return null;
  
    const renderStep = () => {
      switch (currentStep) {
        case 1:
          return (
            <div className="flex flex-col h-full">
              <div className="p-6 space-y-4 flex-1 overflow-y-auto">
                <h3 className="text-xl font-semibold mb-4">{t('booking.selectDate')}</h3>
                
                {/* Timezone Selection */}
                <div className="mb-6 space-y-4">
                  <div className="bg-white p-4 rounded-xl shadow-sm border border-gray-200">
                    <div className="flex items-center gap-3">
                      <Globe className="w-6 h-6 text-indigo-500" />
                      <Select
                        value={{ value: selectedTimezone, label: selectedTimezone }}
                        onChange={handleTimezoneChange}
                        options={timezoneOptions}
                        className="flex-1"
                        classNamePrefix="timezone-select"
                      />
                    </div>
                  </div>
                  
                  <div className="bg-white p-4 rounded-xl shadow-sm border border-gray-200">
                    <div className="flex items-center gap-3">
                      <Clock className="w-6 h-6 text-indigo-500" />
                      <select
                        value={hourFormat}
                        onChange={handleHourFormatChange}
                        className="flex-1 border-2 border-gray-200 rounded-lg p-2"
                      >
                        <option value="12">12-hour</option>
                        <option value="24">24-hour</option>
                      </select>
                    </div>
                  </div>
                </div>
    
                {/* Calendar */}
                <div className="w-full">
                  <SmallCalendar
                    onChange={handleDateSelect}
                    availableDates={transformedAvailability}
                    selectedDate={selectedDate}
                    selectedTimezone={selectedTimezone}
                    hourFormat={hourFormat}
                    teacherTimezone="UTC"
                  />
                </div>
              </div>
            </div>
          );
    
        case 2:
          return (
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">{t('booking.selectTime')}</h3>
              {timeSlots.length > 0 ? (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                  {timeSlots.map((slot, index) => (
                    <button
                      key={index}
                      onClick={() => handleSlotSelect(slot)}
                      className={`p-3 rounded-lg border-2 transition-all ${
                        selectedSlot === slot 
                          ? 'border-indigo-500 bg-indigo-50 text-indigo-700' 
                          : 'border-gray-200 hover:border-indigo-300'
                      }`}
                    >
                      {moment(slot.start, 'HH:mm').format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')} - 
                      {moment(slot.end, 'HH:mm').format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')}
                    </button>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-center py-8">
                  {t('booking.noAvailableSlots')}
                </p>
              )}
            </div>
          );
    
        case 3:
          return (
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">{t('booking.confirm')}</h3>
              <div className="space-y-4">
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-3 mb-4">
                    <img
                      src={teacher?.Profile_Image?.[0] 
                        ? `https://ik.imagekit.io/8s3jwexmv/${encodeURIComponent(teacher.Profile_Image[0])}` 
                        : "https://ik.imagekit.io/8s3jwexmv/default-profile-image.png"}
                      alt={teacher?.Username}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div>
                      <h4 className="font-medium">{teacher?.Username}</h4>
                      <p className="text-sm text-gray-600">{teacher?.Short_Title}</p>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <p className="flex items-center gap-2">
                      <Calendar className="w-4 h-4 text-gray-500" />
                      <span>{moment(selectedDate).format('LL')}</span>
                    </p>
                    <p className="flex items-center gap-2">
                      <Clock className="w-4 h-4 text-gray-500" />
                      <span>
                        {moment(selectedSlot.start, 'HH:mm').format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')} - 
                        {moment(selectedSlot.end, 'HH:mm').format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')}
                      </span>
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setCurrentStep(4)}
                  className="w-full py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 transition-colors"
                >
                  {t('booking.proceedToDetails')}
                </button>
              </div>
            </div>
          );
    
        case 4:
          return (
            <LearningPreferencesStep 
              onNext={() => setCurrentStep(5)}
              t={t}
              learningObjectives={learningObjectives}
              setLearningObjectives={setLearningObjectives}
              sessionPreferences={sessionPreferences}
              setSessionPreferences={setSessionPreferences}
              email={email}
            />
          );
    
        case 5:
          return (
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">{t('booking.userDetails')}</h3>
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative">
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                      placeholder={t('booking.firstName')}
                      required
                      disabled={isLoading || bookingLoading}
                    />
                    <User className="absolute right-3 top-3.5 text-gray-400 w-5 h-5" />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                      placeholder={t('booking.lastName')}
                      required
                      disabled={isLoading || bookingLoading}
                    />
                    <User className="absolute right-3 top-3.5 text-gray-400 w-5 h-5" />
                  </div>
                </div>
    
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    className={`w-full px-4 py-3 border-2 ${
                      emailError ? 'border-red-500' : 'border-gray-200'
                    } rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500`}
                    placeholder={t('booking.emailAddress')}
                    required
                    disabled={isLoading || bookingLoading}
                  />
                  <Mail className={`absolute right-3 top-3.5 ${
                    emailError ? 'text-red-400' : 'text-gray-400'
                  } w-5 h-5`} />
                  {emailError && (
                    <p className="mt-1 text-sm text-red-500">{emailError}</p>
                  )}
                </div>
    
                {!verifiedOtp && (
                  <button
                    onClick={handleSendOtp}
                    disabled={isLoading || bookingLoading || !email}
                    className="w-full py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
                  >
                    {isLoading ? "Sending..." : isOtpSent ? "Resend OTP" : "Send OTP"}
                  </button>
                )}
                {isOtpSent && !verifiedOtp && (
                  <div className="space-y-2">
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                      placeholder={t('booking.enterOTP')}
                      required
                      disabled={isLoading || bookingLoading}
                    />
                    <button
                      onClick={handleVerifyOtp}
                      className="w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                      disabled={isLoading || bookingLoading}
                    >
                      {t('booking.verifyOTP')}
                    </button>
                  </div>
                )}
    
                <div className="relative">
                  <input
                    type="tel"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                    placeholder={t('booking.mobileNumber')}
                    required
                    disabled={isLoading || bookingLoading}
                  />
                  <Phone className="absolute right-3 top-3.5 text-gray-400 w-5 h-5" />
                </div>
    
                <button
                  onClick={handleConfirm}
                  disabled={!verifiedOtp || !firstName || !lastName || !mobile || isLoading || bookingLoading}
                  className="w-full py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 transition-colors disabled:opacity-50"
            >
              {(isLoading || bookingLoading) ? "Booking..." : "Book Free Trial Class"}
            </button>
          </div>
        </div>
      );

    case 6:
      return (
        <div className="p-6 text-center">
          <div className="mb-6">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <Check className="w-8 h-8 text-green-600" />
            </div>
            <h3 className="text-xl font-semibold mb-2">{t('booking.bookingConfirmed')}</h3>
            <p className="text-gray-600 mb-4">{t('booking.bookingSuccess')}</p>
          </div>
          
          <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <div className="text-left space-y-3">
              <p className="flex items-center gap-2">
                <Calendar className="w-4 h-4 text-gray-500" />
                <span>{moment(selectedDate).format('LL')}</span>
              </p>
              <p className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-500" />
                <span>
                  {moment(selectedSlot.start, 'HH:mm').format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')} - 
                  {moment(selectedSlot.end, 'HH:mm').format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')}
                </span>
              </p>
              <div className="pt-3 border-t border-gray-200">
                <p className="text-sm text-gray-600 mb-2">{t('booking.meetingLink')}:</p>
                <a 
                  href={`https://test.speakable.online/room/meeting/${bookingId}`}
                  className="text-indigo-600 hover:text-indigo-800 font-medium break-all inline-flex items-center gap-2 group"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Join Meeting</span>
                  <svg 
                    className="w-4 h-4 transition-transform group-hover:translate-x-1" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M14 5l7 7m0 0l-7 7m7-7H3" 
                    />
                  </svg>
                </a>
                <p className="mt-2 text-sm text-gray-500">
                  Booking ID: {bookingId}
                </p>
              </div>
            </div>
          </div>

          <button
            onClick={onClose}
            className="w-full py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 transition-colors"
          >
            {t('common.close')}
          </button>
        </div>
      );

    default:
      return null;
  }
};

  const stepLabels = [
    t('booking.selectDate'),
    t('booking.selectTime'),
    t('booking.confirm'),
    t('booking.learningPreferences'),
    t('booking.userDetails'),
    t('booking.success')
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-100">
          <h2 className="text-2xl font-bold text-gray-800">
            {currentStep === 6 ? t('booking.bookingConfirmed') : t('booking.bookLesson')}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-6 h-6 text-gray-500" />
          </button>
        </div>
  
        {/* Step Progress */}
        {currentStep < 6 && (
          <div className="px-6 py-4 bg-gray-50">
            <div className="flex items-center justify-between">
              {[1, 2, 3, 4, 5].map((step) => (
                <div
                  key={step}
                  className={`flex items-center ${step < 5 ? 'flex-1' : ''}`}
                >
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      step <= currentStep
                        ? 'bg-indigo-600 text-white'
                        : 'bg-gray-200 text-gray-600'
                    }`}
                  >
                    {step < currentStep ? (
                      <Check className="w-5 h-5" />
                    ) : (
                      <span>{step}</span>
                    )}
                  </div>
                  {step < 5 && (
                    <div
                      className={`flex-1 h-1 mx-2 ${
                        step < currentStep ? 'bg-indigo-600' : 'bg-gray-200'
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-2 text-sm text-gray-600">
              {stepLabels.slice(0, 5).map((label, index) => (
                <span key={index} className="text-center flex-1">
                  {label}
                </span>
              ))}
            </div>
          </div>
        )}
  
        {/* Modal Content - Scrollable Section */}
        <div className="flex-1 overflow-y-auto min-h-0">
          {renderStep()}
        </div>
  
        {/* Footer */}
        {currentStep < 6 && (
          <div className="border-t border-gray-100 p-4 flex justify-between bg-white">
            {currentStep > 1 && (
              <button
                onClick={() => setCurrentStep(currentStep - 1)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
                disabled={isLoading || bookingLoading}
              >
                {t('common.previous')}
              </button>
            )}
            {currentStep < 5 && selectedDate && (
              <button
                onClick={() => currentStep < 5 && setCurrentStep(currentStep + 1)}
                className="px-4 py-2 text-indigo-600 hover:text-indigo-800 ml-auto"
                disabled={(currentStep === 2 && !selectedSlot) || isLoading || bookingLoading}
              >
                {t('common.next')}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingModal;