import React, { useState, useEffect } from 'react';
import { CreditCard, Wifi, Lock } from 'lucide-react';

const Premium3DCard = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [glowPosition, setGlowPosition] = useState({ x: 50, y: 50 });
  const [isHovered, setIsHovered] = useState(false);
  const [showSparkle, setShowSparkle] = useState(false);

  const [cardDetails, setCardDetails] = useState({
    number: '',
    holder: '',
    expiry: '',
    cvv: ''
  });

  // Add sparkle effect periodically
  useEffect(() => {
    if (isHovered) {
      const interval = setInterval(() => {
        setShowSparkle(prev => !prev);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isHovered]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'number') {
      formattedValue = value.replace(/\s/g, '').match(/.{1,4}/g)?.join(' ') || '';
      formattedValue = formattedValue.substring(0, 19);
    }

    if (name === 'expiry') {
      formattedValue = value
        .replace(/\D/g, '')
        .replace(/^(\d{2})/, '$1/')
        .substring(0, 5);
    }

    if (name === 'cvv') {
      formattedValue = value.substring(0, 3);
      if (formattedValue.length === 1) setIsFlipped(true);
    }

    setCardDetails(prev => ({
      ...prev,
      [name]: formattedValue
    }));
  };

  const handleMouseMove = (e) => {
    const card = e.currentTarget.getBoundingClientRect();
    const centerX = card.left + card.width / 2;
    const centerY = card.top + card.height / 2;
    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    const rotateX = (mouseY / (card.height / 2)) * 15; // Increased rotation angle
    const rotateY = -(mouseX / (card.width / 2)) * 15;

    const glowX = ((e.clientX - card.left) / card.width) * 100;
    const glowY = ((e.clientY - card.top) / card.height) * 100;

    setRotation({ x: rotateX, y: rotateY });
    setPosition({ x: mouseX * 0.15, y: mouseY * 0.15 }); // Increased movement
    setGlowPosition({ x: glowX, y: glowY });
  };

  const handleMouseLeave = () => {
    setRotation({ x: 0, y: 0 });
    setPosition({ x: 0, y: 0 });
    setGlowPosition({ x: 50, y: 50 });
    setIsHovered(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <div className="relative perspective-[2000px]">
        <div
          className="w-[420px] h-[260px] cursor-pointer"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          <div
            style={{
              transform: isFlipped
                ? `rotateY(180deg)`
                : `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg) translateZ(0) translate(${position.x}px, ${position.y}px)`,
              transformStyle: 'preserve-3d',
            }}
            className="relative w-full h-full duration-700 ease-out"
          >
            {/* Front Face */}
            <div
              style={{ backfaceVisibility: 'hidden' }}
              className={`absolute w-full h-full rounded-2xl p-8 bg-gradient-to-br from-slate-800/80 via-slate-900/80 to-black/80
                shadow-2xl border border-white/20 overflow-hidden backdrop-blur-sm
                transition-all duration-500 ${isHovered ? 'shadow-lg shadow-blue-500/30' : ''}`}
            >
              {/* Enhanced holographic effect */}
              <div
                style={{
                  background: `
                    radial-gradient(circle at ${glowPosition.x}% ${glowPosition.y}%, 
                      rgba(255,255,255,0.2) 0%, 
                      rgba(255,255,255,0.1) 15%, 
                      rgba(255,255,255,0.05) 25%, 
                      transparent 60%
                    )
                  `,
                }}
                className="absolute inset-0 z-10 transition-opacity duration-300"
              />

              {/* Sparkle effects */}
              {showSparkle && (
                <div className="absolute inset-0 z-20">
                  <div className="absolute w-2 h-2 bg-white rounded-full animate-ping"
                    style={{ 
                      left: `${Math.random() * 100}%`, 
                      top: `${Math.random() * 100}%`,
                      animationDuration: '1s'
                    }} 
                  />
                </div>
              )}

              <div className="relative z-20 flex flex-col h-full justify-between">
                <div className="flex justify-between items-start">
                  <div className="flex items-center space-x-4">
                    <div className={`w-12 h-10 rounded-md bg-gradient-to-br from-yellow-400 via-yellow-300 to-yellow-400 
                      shadow-inner flex items-center justify-center p-1 transform rotate-6
                      transition-all duration-300 ${isHovered ? 'scale-110' : ''}`}>
                      <div className="w-full h-full border-2 border-black/30 rounded-sm" />
                    </div>
                    <Wifi className={`w-6 h-6 text-white/70 transition-transform duration-300 
                      ${isHovered ? 'scale-110' : ''}`} />
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-white/70 font-light">platinum</p>
                    <p className={`font-semibold tracking-wider text-white transition-all duration-300
                      ${isHovered ? 'text-blue-400' : ''}`}>
                      VISA
                    </p>
                  </div>
                </div>

                <div className="space-y-6">
                  <div className="pt-4">
                    <p className="text-sm text-white/50 mb-1">Card Number</p>
                    <p className={`font-mono text-2xl tracking-widest text-white 
                      [text-shadow:2px_2px_4px_rgba(0,0,0,0.4)] transform translate-z-10
                      transition-all duration-300 ${isHovered ? 'text-blue-200' : ''}`}>
                      {cardDetails.number || '•••• •••• •••• ••••'}
                    </p>
                  </div>

                  <div className="flex justify-between items-end">
                    <div>
                      <p className="text-sm text-white/50 mb-1">Card Holder</p>
                      <p className={`font-semibold tracking-wider text-white 
                        [text-shadow:1px_1px_2px_rgba(0,0,0,0.4)]
                        transition-all duration-300 ${isHovered ? 'text-blue-200' : ''}`}>
                        {cardDetails.holder || 'YOUR NAME'}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-white/50 mb-1">Expires</p>
                      <p className="font-semibold text-white">{cardDetails.expiry || 'MM/YY'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Back Face with enhanced animations */}
            <div
              style={{ backfaceVisibility: 'hidden', transform: 'rotateY(180deg)' }}
              className={`absolute w-full h-full rounded-2xl bg-gradient-to-br from-slate-800/80 via-slate-900/80 to-black/80
                shadow-2xl border border-white/20 overflow-hidden backdrop-blur-sm
                transition-all duration-500 ${isHovered ? 'shadow-lg shadow-blue-500/30' : ''}`}
            >
              <div className="w-full h-16 bg-black mt-8" />
              
              <div className="p-8">
                <div className="space-y-2">
                  <p className="text-white/50 text-sm">CVV</p>
                  <div className={`bg-white h-12 flex items-center justify-end px-4 rounded
                    transition-all duration-300 ${isHovered ? 'shadow-lg shadow-white/20' : ''}`}>
                    <p className="font-mono tracking-wider text-gray-800 text-lg">
                      {cardDetails.cvv || '•••'}
                    </p>
                  </div>
                </div>

                <div className="mt-8">
                  <div className={`flex items-center text-white/70 space-x-2
                    transition-all duration-300 ${isHovered ? 'text-blue-300' : ''}`}>
                    <Lock className={`w-4 h-4 transition-transform duration-300 
                      ${isHovered ? 'scale-110' : ''}`} />
                    <p className="text-sm">This card is protected by advanced security</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Fields */}
      <div className="w-full max-w-md space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-200">Card Number</label>
          <input
            type="text"
            name="number"
            value={cardDetails.number}
            onChange={handleInputChange}
            placeholder="1234 5678 9012 3456"
            className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg 
              text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent
              backdrop-blur-sm transition-all duration-300 hover:bg-white/15"
            maxLength="19"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-200">Card Holder</label>
          <input
            type="text"
            name="holder"
            value={cardDetails.holder}
            onChange={handleInputChange}
            placeholder="JOHN DOE"
            className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg 
              text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent
              backdrop-blur-sm uppercase transition-all duration-300 hover:bg-white/15"
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">Expiry Date</label>
            <input
              type="text"
              name="expiry"
              value={cardDetails.expiry}
              onChange={handleInputChange}
              placeholder="MM/YY"
              className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg 
                text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent
                backdrop-blur-sm transition-all duration-300 hover:bg-white/15"
              maxLength="5"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">CVV</label>
            <input
              type="text"
              name="cvv"
              value={cardDetails.cvv}
              onChange={handleInputChange}
              onFocus={() => setIsFlipped(true)}
              onBlur={() => setIsFlipped(false)}
              placeholder="123"
              className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg 
                text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent
                backdrop-blur-sm transition-all duration-300 hover:bg-white/15"
              maxLength="3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premium3DCard;