import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTeachers } from "../../store/actions/teachersActions";
import { Getcourses } from "../../store/actions/coursesActions";
import { CreatePackage } from "../../store/actions/packagesActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AdminNav from "./AdminNav";
import { ToastContainer, toast } from "react-toastify";

const LectureConfigRow = ({ config, index, onUpdate, onRemove }) => {
  return (
    <div className="border rounded p-3 mb-3 bg-light">
      <div className="row">
        <div className="col-md-5">
          <label className="form-label">Number of Lectures</label>
          <input
            type="number"
            className="form-control"
            value={config.count}
            onChange={(e) => onUpdate(index, "count", parseInt(e.target.value))}
            min="1"
            required
          />
        </div>
        <div className="col-md-5">
          <label className="form-label">Discount (%)</label>
          <input
            type="number"
            className="form-control"
            value={config.discount}
            onChange={(e) => onUpdate(index, "discount", parseFloat(e.target.value))}
            min="0"
            max="100"
            step="0.1"
            required
          />
        </div>
        <div className="col-md-2 d-flex align-items-end">
          <button 
            type="button" 
            className="btn btn-danger"
            onClick={() => onRemove(index)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

const AdminAddPackage = () => {
  const teachers = useSelector((state) => state.teachers.Teacherslist);
  const courses = useSelector((state) => state.courses.courseslist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const [formData, setFormData] = useState({
    Package_Name: "",
    Teachers_ID: [],
    Course_ID: "",
    lectures: [
      {
        count: 1,
        discount: 0
      }
    ],
    Free_Package: false
  });

  useEffect(() => {
    dispatch(GetTeachers());
    dispatch(Getcourses());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Teachers_ID") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleLectureUpdate = (index, field, value) => {
    setFormData(prevData => {
      const newLectures = [...prevData.lectures];
      newLectures[index] = {
        ...newLectures[index],
        [field]: value
      };
      return {
        ...prevData,
        lectures: newLectures
      };
    });
  };

  const addLectureConfig = () => {
    setFormData(prevData => ({
      ...prevData,
      lectures: [...prevData.lectures, { count: 1, discount: 0 }]
    }));
  };

  const removeLectureConfig = (index) => {
    if (formData.lectures.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        lectures: prevData.lectures.filter((_, i) => i !== index)
      }));
    }
  };

  const handleCheckboxChange = (id, type) => {
    setFormData((prevData) => {
      if (type === "Course_ID") {
        return { ...prevData, [type]: id };
      } else {
        const selectedItems = prevData[type].includes(id)
          ? prevData[type].filter((item) => item !== id)
          : [...prevData[type], id];
        return { ...prevData, [type]: selectedItems };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.Package_Name ||
      !formData.Teachers_ID.length ||
      !formData.Course_ID ||
      !formData.lectures.length
    ) {
      const emptyFieldName = !formData.Package_Name
        ? "Name of the Package"
        : formData.Teachers_ID.length === 0
        ? "Teacher"
        : !formData.Course_ID
        ? "Course"
        : "Lecture configuration";

      toast.error(`Please fill in the ${emptyFieldName} field correctly`);
      return;
    }

    // Validate lecture configurations
    const invalidLecture = formData.lectures.find(
      lecture => lecture.count < 1 || lecture.discount < 0 || lecture.discount > 100
    );

    if (invalidLecture) {
      toast.error("Please check lecture configurations. Counts must be positive and discounts between 0-100%");
      return;
    }

    console.log(formData);
    await dispatch(CreatePackage(formData));
    navigate("/Admin-Dashboard/Packages");
  };

  return (
    <>
      <AdminNav />
      <div className="AddCoursestyle">
        <form onSubmit={handleSubmit}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          
          <div className="form-group">
            <label htmlFor="Package_Name">{t("AdminAddPackage.header")}</label>
            <input
              type="text"
              className="form-control"
              id="Package_Name"
              name="Package_Name"
              value={formData.Package_Name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t("AdminAddPackage.SelectTeacher")}</label>
            {teachers?.map((values) => (
              <div key={values._id} className="form-check">
                <input
                  type="checkbox"
                  id={values._id}
                  value={values._id}
                  checked={formData.Teachers_ID.includes(values._id)}
                  onChange={() => handleCheckboxChange(values._id, "Teachers_ID")}
                  className="form-check-input"
                />
                <label htmlFor={values._id} className="form-check-label">
                  {values.Username}
                </label>
              </div>
            ))}
          </div>

          <div className="form-group">
            <label>{t("AdminAddPackage.SelectCourse")}</label>
            {courses?.map((values) => (
              <div key={values._id} className="form-check">
                <input
                  type="radio"
                  id={values._id}
                  value={values._id}
                  checked={formData.Course_ID === values._id}
                  onChange={() => handleCheckboxChange(values._id, "Course_ID")}
                  className="form-check-input"
                />
                <label htmlFor={values._id} className="form-check-label">
                  {values.Course_Name}
                </label>
              </div>
            ))}
          </div>

          <div className="form-group mt-4">
            <label className="d-flex justify-content-between align-items-center">
              <span>{t("AdminAddPackage.LectureConfigurations")}</span>
              <button 
                type="button" 
                className="btn btn-primary"
                onClick={addLectureConfig}
              >
                Add Configuration
              </button>
            </label>
            
            {formData.lectures.map((config, index) => (
              <LectureConfigRow
                key={index}
                config={config}
                index={index}
                onUpdate={handleLectureUpdate}
                onRemove={removeLectureConfig}
              />
            ))}
          </div>

          <div className="form-check form-switch mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={formData.Free_Package}
              onChange={(e) => setFormData(prev => ({ ...prev, Free_Package: e.target.checked }))}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
              {t("AdminAddPackage.MakeThis")}
            </label>
          </div>

          <button type="submit" className="btn btn-outline-success mt-3 btn-course">
            {t("AdminAddPackage.Submit")}
          </button>
        </form>
      </div>
    </>
  );
};

export default AdminAddPackage;