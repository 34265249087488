import { createSlice } from "@reduxjs/toolkit";
import {
  AddNewTeacher,
  DeleteTeacher,
  fetch1teacher,
  fetch5Teachers,
  fetchTeacherBookings,
  fetchTeacherDetails,
  fetchTeacherDetailsWithoutSlot,
  GetExistingTeacher_Availability,
  GetTeachers,
  imageUpload,
  videoUpload,
  getVideo,
  SearchTeacherbyUsername,
  Signup_Teacher_By_Admin,
  Update_Teacher_By_Availability,
  updateTeacher,
  changeTeacherPassword,
  listLocalFiles,
  uploadToLocal,
  deleteLocalFile,
  downloadLocalFile,
  downloadMultipleFiles,
  connectWebSocket,
  sendWebSocketMessage,
  joinRoom,
  leaveRoom,
  updateTeacherDetails,
} from "../actions/teachersActions";
import { toast } from "react-toastify";

let initialState = {
  Teacherslist: [],
  TeacherDetails: [],
  AllTeacherlist: [],
  Teacher: [],
  Teacher_Bookings: [],
  Teacher_Availabile_Slots: [],
  Teachers_Bookings_Status: [],
  currentTeacher: null,
  loading: false,
  error: null,
  driveFiles: [],
  driveLoading: false,
  driveError: null,
  uploadedVideoFilename: null,
  videoUrl: null,
  loadingVideo: false,
  videoError: null,
  localFiles: [],
  fileLoading: false,
  fileError: null,
  downloadLoading: false,
  downloadError: null,
  multipleDownloadLoading: false,
  multipleDownloadError: null,
  socket: null,
  isConnected: false,
  lastMessage: null,
  currentBookingId: null,
  joiningRoom: false,
  joinRoomError: null,
};

const teacherSlice = createSlice({
  name: "teacher",
  initialState: initialState,
  reducers: {
    websocketConnected: (state) => {
      state.isConnected = true;
    },
    websocketDisconnected: (state) => {
      state.isConnected = false;
      state.socket = null;
    },
    websocketMessageReceived: (state, action) => {
      state.lastMessage = action.payload;
      // Handle different message types here
      switch (action.payload.type) {
        case 'teacherJoined':
          // Update state based on teacher joined message
          break;
        // Add more cases as needed
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch5Teachers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetch5Teachers.fulfilled, (state, action) => {
        state.Teacherslist = action.payload;
        state.loading = false;
      })
      .addCase(fetch5Teachers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchTeacherDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeacherDetails.fulfilled, (state, action) => {
        state.currentTeacher = action.payload;
        state.TeacherDetails = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchTeacherDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addCase(fetchTeacherDetailsWithoutSlot.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeacherDetailsWithoutSlot.fulfilled, (state, action) => {
        state.currentTeacher = action.payload;
        state.TeacherDetails = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchTeacherDetailsWithoutSlot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetch1teacher.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetch1teacher.fulfilled, (state, action) => {
        state.Teacher = action.payload;
        state.loading = false;
      })
      .addCase(fetch1teacher.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetTeachers.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTeachers.fulfilled, (state, action) => {
        state.Teacherslist = action.payload;
        state.AllTeacherlist = action.payload;
        state.loading = false;
      })
      .addCase(GetTeachers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddNewTeacher.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNewTeacher.fulfilled, (state, action) => {
        state.Teacherslist = action.payload;
        state.AllTeacherlist.push(action.payload);
        state.loading = false;
      })
      .addCase(AddNewTeacher.rejected, (state) => {
        state.loading = false;
      })
      .addCase(imageUpload.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(imageUpload.rejected, (state) => {
        state.loading = false;
      })
      .addCase(DeleteTeacher.fulfilled, (state, action) => {
        state.loading = false;
        state.AllTeacherlist = state.AllTeacherlist.filter(
          (teacher) => teacher.id !== action.payload
        );
      })
      .addCase(DeleteTeacher.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateTeacher.fulfilled, (state, action) => {
        const { teacherId, updatedTeacher } = action.payload;
        const updatedTeacherIndex = state.AllTeacherlist.findIndex(
          (teacher) => teacher._id === teacherId
        );

        if (updatedTeacherIndex !== -1) {
          const updateTeacher = {
            ...state.AllTeacherlist[updatedTeacherIndex],
            ...updatedTeacher,
          };
          state.AllTeacherlist = [
            ...state.AllTeacherlist.slice(0, updatedTeacherIndex),
            updateTeacher,
            ...state.AllTeacherlist.slice(updatedTeacherIndex + 1),
          ];
        }
        if (state.currentTeacher && state.currentTeacher._id === teacherId) {
          state.currentTeacher = { ...state.currentTeacher, ...updatedTeacher };
        }
      })
      .addCase(updateTeacher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(Update_Teacher_By_Availability.fulfilled, (state, action) => {
        const { teacherId, updatedData } = action.payload;
        const updatedTeacherIndex = state.AllTeacherlist.findIndex(
          (teacher) => teacher._id === teacherId
        );

        if (updatedTeacherIndex !== -1) {
          const updatedTeacher = {
            ...state.AllTeacherlist[updatedTeacherIndex],
            ...updatedData,
          };
          state.AllTeacherlist = [
            ...state.AllTeacherlist.slice(0, updatedTeacherIndex),
            updatedTeacher,
            ...state.AllTeacherlist.slice(updatedTeacherIndex + 1),
          ];
        }
        if (state.currentTeacher && state.currentTeacher._id === teacherId) {
          state.currentTeacher = { ...state.currentTeacher, ...updatedData };
        }
      })
      .addCase(Update_Teacher_By_Availability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(Signup_Teacher_By_Admin.pending, (state) => {
        state.loading = true;
      })
      .addCase(Signup_Teacher_By_Admin.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.Teacherslist = action.payload.data.newTeacher;
          state.AllTeacherlist.push(action.payload.data.newTeacher);
          state.loading = false;
        } else {
          toast.warning(action.payload, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .addCase(Signup_Teacher_By_Admin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetExistingTeacher_Availability.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetExistingTeacher_Availability.fulfilled, (state, action) => {
        if (action.payload && action.payload.AvailableTimeSlots) {
          state.Teacher_Availabile_Slots = action.payload.AvailableTimeSlots;
          state.loading = false;
        } else {
          state.Teacher_Availabile_Slots = [];
          toast.error("No Bookings are Available For Now", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .addCase(GetExistingTeacher_Availability.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload?.message || "Failed to fetch teacher availability", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(SearchTeacherbyUsername.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchTeacherbyUsername.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.Teacherslist = action.payload.data.founded_teacher;
          state.loading = false;
        } else {
          state.Teacherslist = action.payload;
          toast.warning("No Teacher were Found with this Username", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .addCase(SearchTeacherbyUsername.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchTeacherBookings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeacherBookings.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.Teachers_Bookings_Status = action.payload.data.TeacherBookings;
          state.loading = false;
        } else {
          state.Teacherslist = action.payload;
          toast.warning("This Teacher has no bookings yet", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .addCase(fetchTeacherBookings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeTeacherPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeTeacherPassword.fulfilled, (state, action) => {
        state.loading = false;
        toast.success("Password changed successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(changeTeacherPassword.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload?.message || "Failed to change password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(videoUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(videoUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadedVideoFilename = action.payload;
        toast.success("Video uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(videoUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to upload video", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(getVideo.pending, (state) => {
        state.loadingVideo = true;
        state.videoError = null;
      })
      .addCase(getVideo.fulfilled, (state, action) => {
        state.loadingVideo = false;
        state.videoUrl = action.payload;
      })
      .addCase(getVideo.rejected, (state, action) => {
        state.loadingVideo = false;
        state.videoError = action.payload;
        toast.error(action.payload || "Failed to fetch video", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(uploadToLocal.pending, (state) => {
        state.fileLoading = true;
        state.fileError = null;
      })
      .addCase(uploadToLocal.fulfilled, (state, action) => {
        state.fileLoading = false;
        state.localFiles.push(action.payload);
      })
      .addCase(uploadToLocal.rejected, (state, action) => {
        state.fileLoading = false;
        state.fileError = action.payload;
      })
      .addCase(listLocalFiles.pending, (state) => {
        state.fileLoading = true;
        state.fileError = null;
      })
      .addCase(listLocalFiles.fulfilled, (state, action) => {
        state.fileLoading = false;
        state.localFiles = action.payload;
      })
      .addCase(listLocalFiles.rejected, (state, action) => {
        state.fileLoading = false;
        state.fileError = action.payload;
      })
      .addCase(deleteLocalFile.pending, (state) => {
        state.fileLoading = true;
        state.fileError = null;
      })
      .addCase(deleteLocalFile.fulfilled, (state, action) => {
        state.fileLoading = false;
        state.localFiles = state.localFiles.filter(file => file.name !== action.payload);
      })
      .addCase(deleteLocalFile.rejected, (state, action) => {
        state.fileLoading = false;
        state.fileError = action.payload;
      })
      .addCase(downloadLocalFile.pending, (state) => {
        state.downloadLoading = true;
        state.downloadError = null;
      })
      .addCase(downloadLocalFile.fulfilled, (state, action) => {
        state.downloadLoading = false;
        toast.success(`File ${action.payload} downloaded successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });
      })
      .addCase(downloadLocalFile.rejected, (state, action) => {
        state.downloadLoading = false;
        state.downloadError = action.payload;
        toast.error(action.payload || "Failed to download file", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(downloadMultipleFiles.pending, (state) => {
        state.multipleDownloadLoading = true;
        state.multipleDownloadError = null;
      })
      .addCase(downloadMultipleFiles.fulfilled, (state) => {
        state.multipleDownloadLoading = false;
      })
      .addCase(downloadMultipleFiles.rejected, (state, action) => {
        state.multipleDownloadLoading = false;
        state.multipleDownloadError = action.payload;
      })
      .addCase(connectWebSocket.fulfilled, (state, action) => {
        state.socket = action.payload;
      })
      .addCase(sendWebSocketMessage.rejected, (state, action) => {
        console.error('Failed to send WebSocket message:', action.error.message);
      })
      .addCase(joinRoom.pending, (state) => {
        state.joiningRoom = true;
        state.joinRoomError = null;
      })
      .addCase(joinRoom.fulfilled, (state, action) => {
        state.joiningRoom = false;
        state.currentBookingId = action.payload.bookingId;
      })
      .addCase(joinRoom.rejected, (state, action) => {
        state.joiningRoom = false;
        state.joinRoomError = action.payload;
      })
      .addCase(leaveRoom.fulfilled, (state) => {
        state.currentBookingId = null;
      })
      .addCase(updateTeacherDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTeacherDetails.fulfilled, (state, action) => {
        state.loading = false;
        const { teacherId, updatedTeacher } = action.payload;
        const updatedTeacherIndex = state.AllTeacherlist.findIndex(
          (teacher) => teacher._id === teacherId
        );

        if (updatedTeacherIndex !== -1) {
          const updatedTeacherData = {
            ...state.AllTeacherlist[updatedTeacherIndex],
            ...updatedTeacher,
          };
          state.AllTeacherlist = [
            ...state.AllTeacherlist.slice(0, updatedTeacherIndex),
            updatedTeacherData,
            ...state.AllTeacherlist.slice(updatedTeacherIndex + 1),
          ];
        }
        if (state.currentTeacher && state.currentTeacher._id === teacherId) {
          state.currentTeacher = { ...state.currentTeacher, ...updatedTeacher };
        }
        toast.success("Teacher details updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .addCase(updateTeacherDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message || "Failed to update teacher details", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      });
  },
});

export const { websocketConnected, websocketDisconnected, websocketMessageReceived } = teacherSlice.actions;

export default teacherSlice.reducer;