import { createSlice } from "@reduxjs/toolkit";
import { 
  teacherJoinSession, 
  getTeacherSessionStatus,
  studentJoinSession,
  getStudentSessionStatus 
} from "./../actions/teacherSessionActions";

const initialTeacherState = {
  loading: false,
  sessionActive: false,
  sessionDetails: null,
  error: null,
};

const initialStudentState = {
  loading: false,
  sessionActive: false,
  sessionDetails: null,
  error: null,
};

const teacherSessionSlice = createSlice({
  name: "teacherSession",
  initialState: initialTeacherState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(teacherJoinSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(teacherJoinSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionActive = true;
        state.sessionDetails = action.payload;
      })
      .addCase(teacherJoinSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getTeacherSessionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeacherSessionStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionActive = action.payload.isActive;
        state.sessionDetails = action.payload.sessionDetails;
      })
      .addCase(getTeacherSessionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const studentSessionSlice = createSlice({
  name: "studentSession",
  initialState: initialStudentState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(studentJoinSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(studentJoinSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionActive = true;
        state.sessionDetails = action.payload;
      })
      .addCase(studentJoinSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStudentSessionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStudentSessionStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionActive = action.payload.isActive;
        state.sessionDetails = action.payload.sessionDetails;
      })
      .addCase(getStudentSessionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const teacherSessionReducer = teacherSessionSlice.reducer;
export const studentSessionReducer = studentSessionSlice.reducer;